<template>
    <div style="width: 100%;margin-top: 15px" class="rttm-card" >
      <div style="display: flex">
        <div class="rttm-card-title" style="font-size: 15px;flex: 1;margin-top: 5px">我的项目</div>
        <el-button type="primary" @click="editDialog = true" size="mini" style="background-color: #0052d9;border: 0px;height: 32px">创建项目</el-button>
      </div>
      <ez-state-view :state="state" :noDataReload="false">
        <div v-if="state.isSuc" style="margin-top: 10px;display: flex;
          flex-wrap: wrap;justify-content: left;width: 100%;">
          <div v-for="(item,key) in projectList" :key="key" style="margin-top: 15px;border: 1px #edeff3 solid;
          padding: 13px;box-sizing: border-box;width:31.33%;margin-right: 2%;">
            <div style="display: flex;">
              <div style="flex: 1;font-size: 14px;color: #333333;font-weight: 900">
                {{item.name}}
              </div>
              <div style="margin-top: 5px;font-size: 13px;font-weight: 500;color:#42b983" v-if="item.isEnable">启用中</div>
              <div style="margin-top: 5px;font-size: 13px;font-weight: 500;color:#f52052" v-else>已被禁用</div>
            </div>
            <div style="margin-top: 10px">
              <div style="width: 80px;font-size: 12px;color: #8C9DB6">
                AccessId
              </div>
              <div style="flex: 1;margin-top: 3px;font-size: 13px">
                {{item.accessId}}
              </div>
            </div>
            <div style="margin-top: 8px">
              <div style="width: 80px;font-size: 12px;color: #8C9DB6">
                创建时间
              </div>
              <div style="flex: 1;margin-top: 3px;font-size: 12px">
<!--                {{item.ctime}}-->
                {{$ez.util.time.milliToYMDHMS(item.ctime)}}
              </div>
            </div>
            <div style="margin-top: 10px">
              <el-button type="primary"
                         size="mini"
                         @click="$router.push({path: '/openapi/info', query: {id: item.id}})"
                         style="background-color: transparent;border: 0px;
                         border: 1px rgb(101,160,255) solid;color: #0052d9">详情</el-button>
            </div>
          </div>
        </div>
        <el-pagination style="margin-top: 15px;margin-left: -10px"
          background
          layout="prev, pager, next"
          :page-size="100"
          :total="100">
        </el-pagination>
      </ez-state-view>
      <el-dialog
        :destroy-on-close="true"
        :visible.sync="editDialog"
        top="60px"
        width="600px">
        <div slot="title" class="rttm-card-title" style="margin-top: 3px;margin-left: 8px">
          创建接口项目
        </div>
        <openapi-project-edit :is-add="true" @saveOver="getMyProjectList"></openapi-project-edit>
<!--        <openapi-project-edit-->
      </el-dialog>
    </div>
</template>

<script>
import OpenapiApi from '../../../../common/api/OpenApi'
import OpenapiProjectEdit from '../edit/OpenapiProjectEdit'

export default {
  name: 'OpenapiProjectList',
  components: { OpenapiProjectEdit },
  data () {
    return {
      projectList: [],
      editDialog: false,
      state: this.$ez.model.viewState(0)
    }
  },
  methods: {
    async getMyProjectList () {
      this.editDialog = false
      this.state.toLoading()
      const _r = await OpenapiApi.getMyProjectList()
      if (_r.r) {
        if (_r.data.length > 0) {
          this.projectList = _r.data
          this.state.toSuc(500)
        } else {
          this.state.toNoData(500)
        }
      } else {
        this.state.toFail(500)
      }
    }
  },
  created () {
    this.getMyProjectList()
  }
}
</script>

<style scoped>

</style>
