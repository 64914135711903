import EzStrUtil from './EzStrUtil'
import { showEzLoading, closeEzLoading } from './EzLoading'
import EzTimeUtil from './EzTimeUtil'
import encrypt from './EncryptUtil'
export default {
  str: EzStrUtil,
  time: EzTimeUtil,
  load: {
    show: showEzLoading,
    close: (key, t) => closeEzLoading(key, t)
  },
  form: {
    checkData ({
      that,
      ref = 'form',
      formData,
      callBack,
      errToast = '信息填写不完整'
    }) {
      let value = false
      that.$refs[ref].validate((valid) => {
        if (valid) {
          value = formData ?? this.formData
        } else {
          value = false
          that.$message.error(errToast)
          return false
        }
      })
      if (callBack != null) {
        callBack(value)
      }
      return value
    }
  },
  number: {
    getSizeMb (size) {
      if (size > 1000 * 1000) {
        return `${(size / 1000000).toFixed(2)} MB`
      }
      return `${(size / 1000).toFixed(2)} KB`
    }
  },
  url: {
    openInternal (path) {
      const x = window.location.href.split('/#/')
      window.open(x[0] + '/#' + path)
    }
  },
  encrypt: encrypt,
  input: {
    handlerlength: {
      length
    }
  }
}
