<template>
  <div style="line-height: normal">
    <div class="my-top" style="margin-right: 70px;display: flex;line-height: 60px">
      <el-popover
        placement="top-start"
        width="180"
        trigger="hover"
        content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
        <div style="margin-top: -17px;margin-right: -20px;margin-left: -20px;margin-bottom: -20px;border-radius: 5px">
          <div style="height: 42px;background-color: #314054;border-top-left-radius: 5px;
        margin-left: 1px;margin-right: 1px;border-top-right-radius: 5px;color: #ececec;
        text-align: center;font-weight: 500;line-height: 42px">
            个人中心
          </div>
          <div style="padding-top: 10px;cursor: pointer">
            <div @click="$router.push({path: '/account/editUser'})" class="my-menu" style="">
              <div class="my-menu-icon" style="">
                <img src="../../../../assets/icon/my/my-info.png" style="height: 100%;width: 100%"/>
              </div>
              <div class="my-menu-text">编辑账号信息</div>
            </div>
            <div @click="$router.push({path: '/account/editCompany'})" class="my-menu" style="">
              <div class="my-menu-icon" style="">
                <img src="../../../../assets/icon/my/my-company.png" style="height: 100%;width: 100%"/>
              </div>
              <div class="my-menu-text">{{myInfo.developerT == 1 ? '升级企业账号':'编辑企业信息'}}</div>
            </div>
            <div class="my-menu" style="" @click="showPhoneUpdate= true">
              <div class="my-menu-icon" style="">
                <img src="../../../../assets/icon/my/my-setting.png" style="height: 100%;width: 100%"/>
              </div>
              <div class="my-menu-text">更换绑定手机号</div>
            </div>
            <div class="my-menu" style="" @click="showUpdatePassword= true">
              <div class="my-menu-icon" style="">
                <img src="../../../../assets/icon/my/my-password.png" style="height: 100%;width: 100%"/>
              </div>
              <div class="my-menu-text">设置登录密码</div>
            </div>
            <div style="height: 1px;background-color: #eaecee;margin-top: 10px;margin-left: 25px;margin-right: 25px">
            </div>
            <div style="display: flex">
              <div style="width: 25px"></div>
              <div @click="loginOut" style="background: #F6F9FF;height: 36px;flex: 1;margin-top: 15px;
              color: #0052D9;margin-bottom: 20px;text-align: center;line-height: 36px;border: 1px #c4d5ff solid">
                退出登录
              </div>
              <div style="width: 25px"></div>
            </div>
          </div>
        </div>
        <div slot="reference" style="display: flex">
          <div style="margin-left: 30px;margin-right: 30px;height: 30px;margin-top: 15px;width: 1px;background-color: #ececec"></div>
          <div class="user-name">{{myInfo.nickName}}</div>
          <img :src="myInfo.avatar" style="height: 32px;width: 32px;margin-top: 11px;
      border: 3px #e9eaee solid;border-radius: 50px;margin-left: 15px;">
        </div>
      </el-popover>
    </div>
    <el-dialog
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :visible.sync="showPhoneUpdate"
      top="60px"
      width="440px">
      <div slot="title" class="rttm-card-title" style="margin-top: 3px;margin-left: 8px">
        更换绑定手机号
      </div>
      <UpdatePhone v-if="showPhoneUpdate"></UpdatePhone>
    </el-dialog>
    <el-dialog
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :visible.sync="showUpdatePassword"
      top="60px"
      width="440px">
      <div slot="title" class="rttm-card-title" style="margin-top: 3px;margin-left: 8px">
        设置登录密码
      </div>
      <UpdatePassword v-if="showUpdatePassword"></UpdatePassword>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import UpdatePhone from '../../../module/account/update/phone/UpdatePhone'
import UpdatePassword from '../../../module/account/update/password/UpdatePassword'

export default {
  name: 'myTop',
  components: { UpdatePassword, UpdatePhone },
  computed: {
    ...mapState('my', {
      nickName: state => state.nickName,
      myInfo: state => state.info
    })
  },
  data () {
    return {
      showPhoneUpdate: false,
      showUpdatePassword: false
    }
  },
  methods: {
    ...mapMutations('login', {
      LOGIN_OUT: 'LOGIN_OUT'
    }),
    loginOut () {
      this.LOGIN_OUT()
      this.$router.push('/')
    }
  }
}
</script>
<style scoped>
  .ez-dialog >>> .el-dialog__header{
    padding: 0px;
    padding-left: 15px;
  }
  .ez-dialog >>> .el-dialog__body{
    padding: 0px;
  }
  .ez-dialog >>> .el-dialog__close{
    font-size: 22px;color: #8c939d;margin-top: 3px;
  }
</style>
<style scoped lang="less">
  .my-menu{
    display: flex;height: 36px;padding-left: 25px;padding-top: 8px;
    .my-menu-icon{
      height: 28px;width: 28px;
    }
    .my-menu-text{
      flex: 1;line-height: 28px;margin-left: 10px;font-size: 13px;
    }
  }
  .my-menu:hover{
    background-color: #ecf1fc;
    color: #0052D9;
  }

.user-name{
  width: 100px;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>
