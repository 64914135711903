<template>
    <div style="display: flex;height: 100%;width: 100%">
      <div style="flex: 1"></div>
      <div style="padding: 15px;padding-top: 5px;display: flex;height: 100%;width: 100%;  box-sizing: border-box;width: 1200px">
        <div style="width: 260px;" >
          <SdkDirectoryList></SdkDirectoryList>
        </div>
        <div style="flex: 1;margin-left: 5px" class="rttm-card">
          <SdkMainView v-if="routeQuery.type == 1"></SdkMainView>
          <SdkDirectoryMainView v-else-if="routeQuery.type == 0"></SdkDirectoryMainView>
        </div>
      </div>
      <div style="flex: 1"></div>
    </div>
</template>

<script>
import SdkDirectoryList from './directory/SdkDirectoryList'
import SdkMainView from './main/SdkMainView'
import SdkDirectoryMainView from './directory/SdkDirectoryMainView'

export default {
  name: 'SdkList',
  components: { SdkDirectoryMainView, SdkMainView, SdkDirectoryList },
  data () {
    return {
      routeQuery: {}
    }
  },
  watch: {
    $route () {
      this.routeQuery = this.$route.query
    }
  },
  created () {
    this.routeQuery = this.$route.query
  }
}
</script>

<style scoped>

</style>
