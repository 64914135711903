<template>
  <div class="software-detail-container">
    <div class="software-detail-header">
      <div class="header-info">
        <img :src="softwareInfo.logo" alt="">
        <span class="software-title">{{softwareInfo.name}}</span>
      </div>
      <div class="software-header-nav" @click="changeTabs">
        <div :class="getClass('Detail')" data-name="Detail">详情</div>
        <div :class="getClass('History')" data-name="History">版本记录</div>
        <div :class="getClass('Comment')" data-name="Comment">评价&评论</div>
      </div>
    </div>
    <div class="software-detail-content">
      <el-scroll-bar style="height: 100%; width: 100%">
        <keep-alive>
          <components :is="selectedName" :softwareId="softwareInfo.id" :softwareType="softwareType" />
        </keep-alive>
      </el-scroll-bar>
    </div>
  </div>
</template>

<script>
import Detail from './detail/SoftwareDetail.vue'
import History from '../version/table/SoftwareVersionTableView.vue'
import Comment from '../comment/SoftwareCommentList.vue'
export default {
  name: 'SoftwareDetail',
  props: {
    softwareInfo: {
      type: Object,
      default: () => {}
    },
    softwareType: {
      type: Number,
      default: 1
    }
  },
  components: {
    Detail,
    History,
    Comment
  },
  data () {
    return {
      softwareDetail: {},
      selectedName: 'Detail'
    }
  },
  watch: {
    softwareInfo () {
      console.log('cccccccccccc')
    }
  },
  computed: {
    getClass () {
      return (index) => {
        return (this.selectedName === index ? 'header-nav-item header-nav-active' : 'header-nav-item')
      }
    }
  },
  methods: {
    changeTabs (event) {
      if (!event.target.dataset.name) {
        return
      }
      this.selectedName = event.target.dataset.name
    }
  },
  created () {
    // this.getSoftwareDetail()
  }
}
</script>

<style lang="less" scoped>
.software-detail-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .software-detail-header{
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    .header-info{
      display: flex;
    }
    img{
      width: 60px;
      height: 60px;
      border-radius: 5px;
      border: 1px #eff0f3 solid;
    }
    .software-title{
      margin-left: 20px;
      align-self: center;
      font-size: 20px;
      font-weight: 700;
    }
    .software-header-nav {
      display: flex;
      margin-top: 30px;
      .header-nav-item{
        padding: 0 20px;
        height: 40px;
        margin-right: 8px;
        cursor: pointer;
        border-bottom: 3px solid transparent;
        color: #8c939d;
        font-size: 15px;
        &:hover,
        &.header-nav-active{
          border-bottom-color: #0052D9;
          color: #0052D9;
        }
      }
    }
  }
  .software-detail-content{
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}
/deep/.el-scrollbar__wrap{
  overflow-x: hidden;
}
</style>
