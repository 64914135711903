import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import EzView from './components/ez_view'
import EzStateView from './components/ez_state_view'
import EzFileSel from './components/EzFileSel'
import {
  Button, Dialog, Input, Message, Popover, Steps,
  Step, Form, FormItem, Radio, Pagination,
  DatePicker, Select, Option, Table, TableColumn,
  Tag, Image, Rate, Carousel, CarouselItem, RadioGroup, InputNumber, InfiniteScroll,
  MessageBox, Tabs, TabPane
} from 'element-ui'

import ScrollBar from 'element-ui/lib/scrollbar'
import AppConfig from './config/AppConfig'
import 'element-ui/lib/theme-chalk/index.css'
import EzUtil from './common/util/EzUtil'
import './assets/css/rttm.css'
import EzState from './ez/common/model/EzState'
import { eventBus } from '@/common/util/EventBus'

Vue.use(Button).use(Input).use(Dialog)
  .use(Popover).use(Steps).use(Step)
  .use(Form).use(FormItem).use(Radio)
  .use(DatePicker).use(Select).use(Option)
  .use(Tag).use(Table).use(TableColumn)
  .use(Pagination).use(Image).use(Rate)
  .use(Carousel).use(CarouselItem).use(RadioGroup)
  .use(InputNumber).use(InfiniteScroll)
  .use(TabPane).use(Tabs)

Vue.component('EzView', EzView)
Vue.component('EzStateView', EzStateView)
Vue.component('EzFileSel', EzFileSel)
Vue.component('el-scroll-bar', ScrollBar)
Vue.prototype.$message = Message
Vue.prototype.$messageBox = MessageBox

Vue.prototype.$ez = {
  util: EzUtil,
  dict: {},
  model: {
    viewState (defState) {
      return new EzState(defState)
    }
  }
}

Vue.prototype.$bus = eventBus

Vue.prototype.$appConfig = AppConfig
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
