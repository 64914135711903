<template>
    <div class="rttm-card" style="padding: 20px">
      <div style="display: flex">
        <div class="rttm-card-title" style="font-size: 15px;flex: 1;margin-top: 2px">
          最近操作的{{type==1 ? "穿戴应用": '表盘应用'}}
        </div>
        <template>
          <el-button @click="goPage(null)" type="text" size="small" style="font-size: 13px;color: #0052D9">查看全部</el-button>
        </template>
      </div>
      <EzStateView :noDataReload="false" :state="state" padding-t="20px" style="margin-top: 10px">
        <div v-if="state.isSuc" >
          <ControlTable :softwareList="softwareList" @toDetail="goPage" />
        </div>
      </EzStateView>
    </div>
</template>

<script>
import SoftwareApi from '@/common/api/SoftwareApi'
import ControlTable from '../../components/ControlTable.vue'

export default {
  name: 'ControlSoftwareTable',
  props: ['type'],
  components: {
    ControlTable
  },
  data () {
    return {
      softwareList: [],
      state: this.$ez.model.viewState(0)
    }
  },
  methods: {
    goPage (id) {
      this.$router.push({
        path: this.type === 1 ? '/wearapp' : '/dialapp',
        query: id ? { id: id } : {}
      })
    },
    async getSoftwareList () {
      SoftwareApi.getMySoftwareList({ type: this.type, pageNo: 1, pageSize: 3 }).then(res => {
        this.softwareList = res.data.records
        this.softwareList.length ? this.state.toSuc() : this.state.toNoData()
      }).catch(err => {
        console.error(err)
        this.state.toFail()
      })
    }
  },
  created () {
    this.getSoftwareList()
  }
}
</script>

<style scoped>

</style>
