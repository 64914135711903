<template>
  <div style="height: 100%;width: 100%;display: flex">
    <div style="flex: 1;margin-top: -1px;margin-left: 15px;margin-bottom: 0px;display: flex;flex-direction: column;padding-top: 0px" class="rttm-card">
      <div style="flex: 1;height: 0px">
        <ez-state-view :state="state"  style="height: 100%">
          <div v-if="commentList.length <= 0" style="display: flex;flex-direction: column;text-align: center;height: 100%;">
            <div style="flex: 1"></div>
            <div style="display: flex">
              <div style="flex: 1"></div>
              <img src="../../../../assets/no_comment.png" height="130" width="130"/>
              <div style="flex: 1"></div>
            </div>
            <div style="color: #8c939d">
              暂无评论
            </div>
            <div style="flex: 2"></div>
          </div>
          <div v-else style="height: 100%;overflow-y: auto">
            <!-- <div v-for="(item,key) in commentList"
                 :key="key"
                 style="display: flex;margin-top: 20px">
              <div style="height: 40px;width: 40px">
                <img :src="item.userAvatar" style="height: 40px;width: 40px;border-radius: 200px">
              </div>
              <div  style="margin-left: 10px;margin-top: 1px;flex: 1">
                <div style="display: flex;width: 100%">
                  <div style="flex: 1">
                    <div style="font-size: 14px;color: #6d7580">
                      {{item.userNickName}}
                    </div>
                    <div class="comment-rate" style="margin-top: 2px">
                      <el-rate
                        v-model="item.score"
                        disabled
                        show-score
                        text-color="#ff9900"
                        score-template="{value}">
                      </el-rate>
                    </div>
                  </div>
                  <div style="font-size: 13px;color: #aab0b8;line-height: 30px">
                    {{$ez.util.time.milliToYMDHMS(item.publishTime).substring(0,16)}}
                  </div>
                </div>
                <div style="margin-top: 3px;color: #545763;font-weight: 500">
                  {{item.content}}
                </div>
                <div v-if="!item.hasReply"  @click="toReplyComment = item,replyDailog=true" style="display: flex;margin-top: 6px;cursor:pointer;">
                  <div style="color: #0052D9;font-size: 12px">
                    回复
                  </div>
                  <div style="font-size: 18px;margin-top: -2px;margin-left: 5px;color: #2572f1">
                    <i class="el-icon-s-comment"></i>
                  </div>
                </div>
                <div v-else style="margin-bottom: 20px">
                  <div style="border-style: solid;
                      margin-left: 10px; margin-top: -5px;
                      border-width: 9px 7px 9px 7px;
                      border-color: transparent transparent #E1E9FB transparent;
                      width: 0px;
                      height: 0px;">
                  </div>
                  <div style="padding: 11px;padding-left: 15px;padding-right:15px;background-color: #F3F6FB;border: 1px #E1E9FB solid;border-radius: 3px;font-size: 14px;color: #767676">
                    <div style="font-weight: 500;font-size: 13px">{{item.replyInfo.content}}</div>
                    <div style="font-size: 12px;margin-top: 6px;color: #9ea4ad">{{$ez.util.time.milliToYMDHMS(item.replyInfo.ctime).substring(0,16)}}</div>
                  </div>
                </div>
                <div  style="height: 1px;background-color: #f3f4f6;margin-top: 8px;"></div>
              </div>
            </div> -->
            <CommentItem v-for="(item, index) in commentList" :key="index" :commentInfo="item"></CommentItem>
          </div>
        </ez-state-view>
      </div>
      <div style="background-color: white;z-index: 9;margin-top: 12px">
        <div style="margin-bottom: -10px;margin-top: 00px">
          <el-pagination
            background
            @size-change="(s)=> {pageParam.pageSize = s;pageParam.pageNo = 1;toSearch()}"
            @current-change="(c)=> {pageParam.pageNo = c;toSearch()}"
            :page-size="pageParam.pageSize"
            :page-sizes="[5, 10, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <div style="width: 300px;margin-top: 15px;margin-right: 15px;margin-left: 15px;">
      <div class="rttm-card">
        <el-form label-position="top" label-width="100px" ref="form" :model="searchForm">
          <div style="">
            <div style="margin-top: -12px">
              <el-form-item label="是否回复" prop="name">
                <el-select  size="small"  style="width: 100%" v-model="searchForm.hasReply" placeholder="请选择">
                  <el-option label="全部" :value="null"></el-option>
                  <el-option label="已回复" :value="true"></el-option>
                  <el-option label="未回复" :value="false"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="margin-top: -12px">
              <el-form-item label="发布时间-开始" prop="name">
                <el-date-picker type="date" value-format="timestamp" placeholder="选择日期" v-model="searchForm.publishTimeBegin" size="small" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </div>
            <div style="margin-top: -12px">
              <el-form-item label="发布时间-截止" prop="name">
                <el-date-picker type="date" value-format="timestamp" placeholder="选择日期" v-model="searchForm.publishTimeEnd" size="small" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </div>
          </div>
          <div style="display: flex;">
            <div style="flex: 1">
              <el-button @click="paramReSet" size="small" icon="el-icon-refresh" style="width: 100%"> 重置 </el-button>
            </div>
            <div style="width: 30px"></div>
            <div style="flex: 1">
              <el-button type="primary" @click="toSearch" style="background-color: #0052d9;border: 1px #0052d9 solid;width: 100%" icon="el-icon-search" size="small"> 搜索 </el-button>
            </div>
          </div>
        </el-form>
      </div>
    </div> -->
    <!-- <el-dialog
      title="评论回复"
      :visible.sync="replyDailog"
      width="600px">
      <div v-if="replyDailog" class="rttm-card">
        <div style="margin-bottom: 12px;margin-top: -12px;color: #717780;display: flex">
          <div>回复用户: @</div>
          <div> <img :src="toReplyComment.userAvatar" style="height: 20px;width: 20px;margin-top: 0px;border-radius: 30px;margin-left: 5px;margin-right: 5px"></div>
          <div>{{toReplyComment.userNickName}}</div>
        </div>
        <el-input
          type="textarea"
          :rows="5"
          :maxlength="20"
          placeholder="请输入回复内容"
          v-model="replyForm.content">
        </el-input>
        <div style="text-align: center;padding-top: 20px;display: flex">
          <div style="flex: 1"></div>
          <el-button @click="replyDailog=false" size="small" style="width: 100px;" icon="el-icon-back" > 取消 </el-button>
          <div style="width: 30px"></div>
          <el-button type="primary" @click="pulishReply" style="background-color: #0052d9;width: 100px;border: 1px #0052d9 solid;" icon="el-icon-s-comment" size="small"> 回复 </el-button>
          <div style="flex: 1"></div>
        </div>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import SoftwareApi from '@/common/api/SoftwareApi'
import CommentItem from './CommentItem.vue'

export default {
  props: ['softwareId'],
  name: 'SoftwareCommentList',
  components: {
    CommentItem
  },
  data () {
    return {
      replyDailog: false,
      commentList: [],
      toReplyComment: {},
      state: this.$ez.model.viewState(0),
      replyForm: {
        content: null
      },
      searchForm: {
        publishTimeBegin: null,
        publishTimeEnd: null,
        hasReply: null
      },
      pageParam: {
        total: 0,
        pageSize: 10,
        pageNo: 1
      }
    }
  },
  methods: {
    paramReSet () {
      this.searchForm = {
        publishTimeBegin: null,
        publishTimeEnd: null,
        hasReply: null
      }
    },
    async pulishReply () {
      if (this.$ez.util.str.isEmpty(this.replyForm.content)) {
        this.$message.error('回复内容不能为空！请填写完整回复内容')
        return false
      }
      this.replyForm.commentId = this.toReplyComment.id
      const _r = await SoftwareApi.publishReply(this.replyForm)
      if (_r.r) {
        this.replyDailog = false
        for (const v of this.commentList) {
          if (v.id === this.toReplyComment.id) {
            v.hasReply = true
            v.replyInfo = _r.data
            return true
          }
        }
      }
    },
    async toSearch () {
      this.state.toLoading()
      this.searchForm.softwareId = this.softwareId
      SoftwareApi.getCommentList({
        id: this.softwareId,
        pageNo: this.pageParam.pageNo,
        pageSize: this.pageParam.pageSize
      }).then(res => {
        console.log(res)
        this.commentList = res.data.records
        this.pageParam.total = res.data.total
        this.state.toSuc()
      })
    }
  },
  watch: {
    softwareId (oldVal, newVal) {
      if (oldVal !== newVal) {
        this.toSearch()
      }
    }
  },
  created () {
    this.toSearch()
  }
}
</script>

<style scoped>
  .comment-rate{
    width: 115px;
    margin-left: -10px;
    transform: scale(0.85,0.85);
  }
  .comment-rate >>> .el-rate__icon{
    margin-right: 1px;margin-top: -2px;
  }
</style>
