<template>
    <div style="padding-left: 40px;padding-right: 40px;padding-top: 0px;padding-bottom: 25px">
      <div>
        <div style="font-size: 24px;color: #222222;font-weight: 700;margin-top: 10px">
          <template v-if="isRegister">注册成为开发者</template>
          <template v-else>登录开发者账号</template>
        </div>
        <div style="margin-top: 40px;color: #222222;font-size: 15px">
          <div v-if="isRegister">手机号注册</div>
          <div v-else  style="display: flex;margin-top: -10px;margin-bottom: 20px">
            <div style="height: 20px;font-size: 15px;color: #0052D9;">
              <div @click="isPhoneLogin = !isPhoneLogin">{{isPhoneLogin ? '密码登录':'验证码登录'}}</div>
            </div>
            <div style="flex: 1"></div>
          </div>
        </div>
      </div>
      <div v-if="(isPhoneLogin && !isRegister) || isRegister">
        <div style="display: flex;margin-top: 10px">
          <div style="height: 40px;width: 60px;line-height: 40px;background-color: #F6F6F6;
            font-size: 16px;border-radius: 5px;text-align: center;font-weight: 500">+86</div>
          <div class="phone-input" style="margin-left: 5px;flex: 1">
            <el-input v-model="phone" :maxlength="20"  @keydown.native="keydown($event)" @input="canSend = (phone != sendPhone ? true : (countdown <= 0 ? true: false))" style="background-color: #F6F6F6" :placeholder="isRegister ? '请输入你要注册的手机号':'请输入你要登录的手机号'"></el-input>
          </div>
        </div>
        <div style="display: flex;margin-top: 20px;border-radius: 5px;overflow: hidden">
          <div class="verifyCode-input" style="flex: 1">
            <el-input v-model="verifyCode" @keydown.native="keydown($event)" :maxlength="20"  style="background-color: #F6F6F6" placeholder="请输入验证"></el-input>
          </div>
          <div v-if="canSend"  @click="verifyCodeSend"  style="width: 120px;text-align: center;line-height: 40px;background-color: #4a4e50;color: white;font-size: 13px">
            发送验证码
          </div>
          <div v-else style="width: 120px;text-align: center;line-height: 40px;background-color: #a9adb1;color: white;font-size: 13px">
            {{countdown}}秒后重新发送
          </div>
        </div>
        <div style="display: flex">
          <div v-if="isRegister" @click="toRegister" style="height: 44px;flex: 1;text-align: center;
            border-radius: 50px;margin-top: 40px;font-size: 16px;
            color: white;font-weight: 500;
            line-height: 44px;background-color: #0052D9">
            立即注册
          </div>
          <div v-else @click="toLogin" style="height: 44px;flex: 1;text-align: center;
            border-radius: 50px;margin-top: 40px;font-size: 16px;
            color: white;font-weight: 500;
            line-height: 44px;background-color: #0052D9">
            立即登录
          </div>
        </div>
      </div>
      <div v-else>
        <div style="display: flex;margin-top: 10px">
          <div style="height: 40px;width: 70px;line-height: 40px;background-color: #eaecef;
            font-size: 14px;border-radius: 5px;text-align: center;font-weight: 500;
            border-top-right-radius: 0px;border-bottom-right-radius: 0px;">
            手机号
          </div>
          <div class="phone-x-input" style="flex: 1">
            <el-input v-model="phone" style="background-color: #F6F6F6" placeholder="请输入你要登录的手机号" :maxlength="20"  @keydown.native="keydown($event)"></el-input>
          </div>
        </div>
        <div style="display: flex;margin-top: 20px">
          <div style="height: 40px;width: 70px;line-height: 40px;background-color: #eaecef;
            font-size: 14px;border-radius: 5px;text-align: center;font-weight: 500;
            border-top-right-radius: 0px;border-bottom-right-radius: 0px;">
            密&nbsp;&nbsp;&nbsp;码
          </div>
          <div class="phone-x-input" style="flex: 1">
            <el-input v-model="password" @keydown.native="keydown($event)" :maxlength="20" type="password" style="background-color: #F6F6F6" placeholder="请输入你的登录密码" show-password></el-input>
          </div>
        </div>
        <div @click="toLogin" style="height: 44px;flex: 1;text-align: center;
            border-radius: 50px;margin-top: 40px;font-size: 16px;
            color: white;font-weight: 500;
            line-height: 44px;background-color: #0052D9">
          立即登录
        </div>
      </div>
      <div>
        <div v-if="isRegister">
          <div style="font-size: 15px;color: #8c939d;font-weight: 500;text-align: center;margin-top: 30px">
            注册即表示同意RTT智能穿戴
          </div>
          <div style="margin-top: 10px;color: #0052D9;text-align: center">《用户服务协议》</div>
        </div>
        <div v-else style="height: 60px">
        </div>
        <div style="display: flex;margin-top: 20px">
          <div style="flex: 1;height: 1px;background-color: #d8d8e0;margin-top: 10px"></div>
          <div style="margin-left: 20px;margin-right: 20px">
            {{isRegister ? "已有账号,点击下方按钮前往登录" : "没有账号,点击下方按钮前往注册"}}
          </div>
          <div style="flex: 1;height: 1px;background-color: #d8d8e0;margin-top: 10px"></div>
        </div>
        <div style="display: flex">
          <div style="flex: 1"></div>
          <div v-if="isRegister" @click="showLoginDialog" style="height: 40px;background-color: #01af48;color: white;text-align: center;
            margin-top: 20px;margin-bottom: 10px;
            line-height: 40px;width: 120px;font-size: 15px;font-weight: 500;border-radius: 50px">
            登录
          </div>
          <div v-else @click="showRegisterDialog" style="height: 40px;background-color: #01af48;color: white;text-align: center;
            margin-top: 20px;margin-bottom: 10px;
            line-height: 40px;width: 120px;font-size: 15px;font-weight: 500;border-radius: 50px">
            免费注册
          </div>
          <div style="flex: 1"></div>
        </div>
      </div>
      <EzView>
        <div class="ez-dialog">
          <el-dialog
            :close-on-click-modal="false"
            :show-close="false"
            :visible.sync="goDialogVisible"
            width="460px"
            append-to-body>
            <div slot="title" style="font-size: 16px;color: #8c939d;text-align: center;">
              手机已被注册
            </div>
            <div style="font-size: 14px;margin-top: -10px;text-align: center;display: flex">
              <div style="flex: 1"></div>
              您输入的手机 <div style="font-size: 20px;color: #333333;margin-top: -5px;font-weight: 500;margin-left: 5px;margin-right: 5px">{{sendPhone}}</div> 已被注册，请前往登录
              <div style="flex: 1"></div>
            </div>
            <div style="display: flex;margin-top: 20px">
              <div style="flex: 1"></div>
              <el-button round size="small" @click="sendPhone = '';phone = '';verifyCode ='';sendCode='';goDialogVisible=false">重新注册</el-button>
              <el-button type="primary" round  size="small" @click="goDialogVisible=false;showLoginDialog()">前往登录</el-button>
              <div style="flex: 1"></div>
            </div>
          </el-dialog>
        </div>
      </EzView>
    </div>
</template>

<script>
import EzcmSysApi from '../../../common/api/EzcmSysApi'
import AuthApi from '../../../common/api/AuthApi'
import { mapMutations } from 'vuex'
import AccountApi from '../../../common/api/AccountApi'

export default {
  name: 'LoginView',
  props: ['isRegister'],
  data () {
    return {
      goDialogVisible: false,
      phone: '',
      password: null,
      sendPhone: '',
      verifyCode: '',
      sendCode: '',
      canSend: true,
      countdown: 0,
      isPhoneLogin: true
    }
  },
  methods: {
    ...mapMutations('login', {
      LOGIN_IN: 'LOGIN_IN'
    }),
    // 禁止输入空格
    keydown (e) {
      if (e.keyCode === 32) {
        e.returnValue = false
      }
    },
    // todo 前往注册
    async toRegister () {
      if (this.sendPhone && this.phone !== this.sendPhone) {
        this.$message.error('请发送验证码')
        return false
      }
      if (!this.checkPhone(this.phone)) {
        return false
      }
      if (!this.submitCheck()) return false
      const _r = await AuthApi.registerVerifyCode(
        { phone: this.phone, verifyCode: this.verifyCode, sendCode: this.sendCode })
      if (_r.r) {
        this.$message.success('注册成功! 欢迎来到RTT开发者平台')
        this.LOGIN_IN(_r.data.token)
        this.$router.push('/control')
        window.location.reload()
      } else if (_r.code === 102001) {
        this.goDialogVisible = true
      }
    },
    // todo 前往登录
    async toLogin () {
      if (!this.checkPhone(this.phone)) {
        return false
      }
      // 密码登录
      if (!this.isPhoneLogin) {
        if (!this.password) {
          this.$message.error('请输入有效的登录密码')
          return false
        }
      } else { // 验证码登录
        if (this.sendPhone && this.phone !== this.sendPhone) {
          this.$message.error('请发送验证码')
          return false
        }
        if (!this.submitCheck()) return false
      }
      let _r

      if (this.isPhoneLogin) { // 验证码登录
        _r = await AuthApi.loginVerifyCode(
          { phone: this.phone, verifyCode: this.verifyCode, sendCode: this.sendCode })
      } else { // 密码登录
        _r = await AuthApi.loginPassword(
          { loginCode: this.phone, password: this.$ez.util.encrypt.base64.encode(this.password) })
      }

      if (_r.r) {
        this.$message.success('登录成功！欢迎回来')
        this.LOGIN_IN(_r.data.token)
        this.$router.push('/control')
        window.location.reload()
      }
    },
    // todo 发送验证码
    async verifyCodeSend () {
      if (!this.checkPhone(this.phone)) return false
      this.sendPhone = this.phone
      if (this.isRegister) {
        const _rP = await AccountApi.isHasDeveloperPhone({ phone: this.phone })
        if (_rP.r) {
          if (_rP.data) {
            this.$message.error('该手机号已被注册，请换一个吧！')
            return false
          }
        } else {
          return false
        }
      }
      const _r = await EzcmSysApi.verifyCodeSend({ phone: this.sendPhone })
      if (_r.r) {
        this.sendCode = _r.data
        this.canSend = false
        this.countdown = 60
        const self = this
        const countdownInterval = setInterval(function () {
          self.countdown = self.countdown - 1
          if (self.countdown <= 0) {
            self.canSend = true
            window.clearInterval(countdownInterval)
          }
        }, 1000)
      }
    },
    // todo 提交检查
    submitCheck () {
      if (this.$ez.util.str.isEmpty(this.sendCode)) {
        if (!this.checkPhone(this.phone)) return false
      } else {
        if (!this.checkPhone(this.sendPhone)) return false
      }
      if (this.$ez.util.str.isEmpty(this.sendCode)) {
        this.$message.error('请发送验证码')
        return false
      }
      if (this.$ez.util.str.isEmpty(this.verifyCode)) {
        this.$message.error('请填写验证码')
        return false
      }
      return true
    },
    // todo 手机号检查
    checkPhone (phone) {
      if (!this.phone) {
        this.$message.error('请输入手机号!')
        return false
      }
      if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))) {
        this.$message.error('手机号格式不正确!')
        return false
      }
      return true
    },
    showLoginDialog () {
      this.$emit('showLoginDialog')
    },
    showRegisterDialog () {
      this.$emit('showRegisterDialog')
    }
  }
}
</script>

<style scoped>
  .phone-input{
    border-radius: 5px;
    overflow: hidden;
  }
  .phone-x-input >>> .el-input__inner{
    background-color: #F6F6F6;
    border: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    font-size: 15px;
  }
  .phone-input >>> .el-input__inner{
    background-color: #F6F6F6;
    border: 0px;
    font-size: 15px;
  }
  .verifyCode-input >>> .el-input__inner{
    border: 0px;
    font-size: 15px;
    background-color: #F6F6F6;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
</style>
