<template>
    <div style="height: 100%">
      <slot v-if="viewState.isSuc"></slot>
      <div v-else style="display: flex;flex-direction: column;height: 100%">
        <div style="flex: 1"></div>
        <div :style="{'padding-top': paddingT || '0px','padding-bottom': paddingB || '0px'}" v-if="viewState.isLoading">
          <img v-if="state" src="">
          <div style="text-align: center">
            <img src="../assets/ez/state_load.gif" height="28" />
          </div>
          <div :style="{'padding-top': paddingT || '15px'}" style="text-align: center;font-size: 12px;margin-top: 15px;color: #8c939d;">
            正在加载中...
          </div>
        </div>
        <div :style="{'padding-top': paddingT || '0px','padding-bottom': paddingB || '0px'}" v-else-if="viewState.isFail"  style="text-align: center">
          <img src="../assets/ez/state_fail.png" height="140px"/>
          <div style="text-align: center;font-size: 13px;margin-top: -10px;color: #8c939d">
            加载失败
          </div>
          <div style="margin-top: 15px">
            <el-button @click="reLoad" type="primary" style="background-color: #0052D9;border: 0px;" size="small" round>重新加载</el-button>
          </div>
        </div>
        <div :style="{'padding-top': paddingT || '0px','padding-bottom': paddingB || '0px'}" v-else-if="viewState.isNoData" style="text-align: center">
          <img src="https://modao.cc/mb-workspace/images/workspace/design/no_combos_light.svg" width="120px">
          <div style="text-align: center;font-size: 13px;margin-top: 10px;color: #8c939d">
            暂无数据
          </div>
          <div style="margin-top: 10px" v-if="noDataReload != false">
            <el-button @click="reLoad" type="primary" style="background-color: #0052D9;border: 0px;" size="small" round>重新加载</el-button>
          </div>
        </div>
        <div style="flex: 1"></div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['state', 'loadText', 'loadImg', 'paddingT', 'paddingB', 'noDataReload'],
  name: 'ez_load_view',
  data () {
    return {
      viewState: this.state
    }
  },
  watch: {
    state: {
      handler: function (val, oldval) {
        this.viewState = val
      },
      deep: true// 对象内部的属性监听，也叫深度监听
    }
  },
  methods: {
    reLoad () {
      this.$emit('reLoadFun')
    }
  }
}
</script>

<style scoped>

</style>
