<template>
  <div style="height: 100%;overflow-y: auto;width: 100%">
    <div class="sdk-table">
      <div style="font-size: 18px;display: flex;padding-bottom: 10px;margin-top: 20px">
        <div style="height: 8px;width: 8px;background-color: #1B8AFA;border-radius: 30px;margin-top: 9px;margin-right: 8px"></div>
        最新版本
      </div>
      <div style="">
        <div v-if="sdkInfo.hasVersion">
          <el-table
            :data="[sdkInfo.currentVersion]"
            style="width: 100%">
            <el-table-column
              label="文件名称"
              prop="fileName">
              <template slot-scope="scope">
                <div style="display: flex;color: #333333;font-weight: 500">
                  {{scope.row.fileName}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="版本"
              prop="version">
              <template slot-scope="scope">
                <div style="display: flex;color: #333333;font-weight: 500">
                  {{scope.row.version}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="发布时间"
              prop="desc"
              width="160">
              <template slot-scope="scope">
                <div style="display: flex;color: #333333;font-weight: 500">
                  {{$ez.util.time.milliToYMDHM(scope.row.ctime)}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label=版本大小
              prop="desc"
              width="100">
              <template slot-scope="scope">
                <div style="display: flex;color: #333333;font-weight: 500">
                  {{$ez.util.number.getSizeMb(scope.row.fileSize)}}MB
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="desc"
              width="80">
              <template slot-scope="scope">
                <div style="display: flex;color: #333333;font-weight: 500">
                  <a :href="scope.row.fileUrl" download="" style="color: #0052d9;font-size: 12px;margin-left: 10px">下载SDK</a>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              type="expand"
              width="50">
              <template slot-scope="props">
<!--                {{props.row.updateDescription}}-->
                <div v-html="props.row.updateDescription"></div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-else="">
        </div>
      </div>
      <div style="font-size: 18px;display: flex;padding-bottom: 10px;margin-top: 20px">
        <div style="height: 8px;width: 8px;background-color: #1B8AFA;border-radius: 30px;margin-top: 9px;margin-right: 8px"></div>
        SDK简介
      </div>
      <div v-html="sdkInfo.introduce"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SdkIntroduceView',
  props: ['sdkInfo'],
  data () {
    return {
      editDialog: true,
      tableData: [
        {
          fileName: 'rtt_odaSDK'
        }
      ]
    }
  }
}
</script>

<style scoped>
.sdk-table{
  margin-top: 10px;

}
  .sdk-table >>> .el-table{
    border-radius: 3px;
    border: 1px #e6edf5 solid;
    border-bottom: 0px;
  }
  .sdk-table >>> th{
    color: #666666;
    background-color: #f7f9fa;
  }
  .sdk-table >>> td{
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .sdk-table >>> .el-table__expand-icon{
    font-size: 22px;
    margin-top: -3px;
    margin-bottom: 10px;
    font-weight: 900;
    margin-left: -14px;
  }
</style>
