import axios from 'axios'
import qs from 'qs'
import { showEzLoading, closeEzLoading } from '../util/EzLoading'
import { Message } from 'element-ui'

export const ParamType = {
  TYPE_OBJ: 'OBJ',
  TYPE_JSON: 'JSON',
  TYPE_FROM: 'FROM',
  TYPE_OTHER: 'OTHER'
}

// // 参数类型
// var paramType = {
//   TYPE_OBJ: 'OBJ',
//   TYPE_JSON: 'JSON',
//   TYPE_FROM: 'FROM',
//   TYPE_OTHER: 'OTHER'
// }

axios.interceptors.response.use(res => {
  // 在请求成功后的数据处理
  return res
}, err => {
  // 在响应错误的时候的逻辑处理
  this.$message.error('操作失败！')
  console.log(err)
})
export function EzRequest ({
  url,
  baseUrl = '',
  param = {}, // 请求参数
  method = 'POST', // 请求类型
  config = {}, // 请求配置
  headers = {}, // 请求头部配置
  paramType = ParamType.TYPE_OBJ, // 请求参数类型
  needToken = true, // 请求是否需要携带token
  tokenFun = (headers) => {} // 请自行获取放在这
}) {
  const _headers = headers || {}
  const _showLoad = config.showLoad || false
  const _loadCloseTime = config.loadCloseTime || 100
  const _loadText = config.loadText || '正在请求'
  let _loadingIdKey = ''
  let _loadIsShow = false
  let _contentType = 'application/x-www-form-urlencoded;charset=UTF-8'

  // 设置headers ================
  // 设置token
  // 设置content-type
  if (_headers['content-type']) {
    if (_headers['content-type'].startWith('application/x-www-form-urlencoded')) {
      paramType = ParamType.TYPE_OBJ
    } else if (_headers['content-type'].startWith('application/json')) {
      paramType = ParamType.TYPE_JSON
    }
  } else {
    if (paramType === ParamType.TYPE_OBJ) {
      _contentType = 'application/x-www-form-urlencoded'
    } else if (paramType === ParamType.TYPE_JSON) {
      _contentType = 'application/json'
    }
    _headers['content-type'] = _contentType
  }
  if (tokenFun) {
    // console.log('设置headers ================')
    tokenFun(_headers)
  }
  // 展示加载中...
  if (_showLoad) {
    _loadingIdKey = showEzLoading(_loadText)
    _loadIsShow = true
  }

  const _pData = paramType === ParamType.TYPE_OBJ ? getQsObj(param) : getJsonStr(param)

  return new Promise((resolve) => {
    const config = method.toLocaleLowerCase() === 'post' ? {
      url: baseUrl + url,
      method: method,
      data: _pData,
      headers: _headers,
      timeout: 30000
    } : {
      url: baseUrl + url,
      method: method,
      params: param,
      headers: _headers,
      timeout: 30000
    }
    axios(config).then(response => {
      // 关闭加载中
      if (_loadIsShow && _loadingIdKey) {
        setTimeout(function () {
          closeEzLoading(_loadingIdKey)
        }, _loadCloseTime)
      }
      resolve(response.data)
    }, err => {
      // 关闭加载中
      if (_loadIsShow && _loadingIdKey) {
        setTimeout(function () {
          closeEzLoading(_loadingIdKey)
        }, _loadCloseTime)
      }
      resolve(err)
    }).catch(err => {
      // 关闭加载中
      if (_loadIsShow && _loadingIdKey) {
        setTimeout(function () {
          closeEzLoading(_loadingIdKey)
        }, _loadCloseTime)
      }
      resolve(err.data)
    })
  })
}

export function EzApiResult ({
  res,
  filterCodes = [],
  sucCode = 0,
  showErr = false,
  errText,
  showSuc = false,
  sucText,
  toastTime = 2000
}) {
  const reuslt = (res.code === sucCode)
  const data = res

  // todo 失败提示
  const _errText = errText || res.msg || '请求失败!'
  if (filterCodes != null && filterCodes.length > 0 && filterCodes.indexOf(res.code) > -1) {
    console.log('业务CODE过滤:' + res.code)
  } else {
    if (!reuslt && showErr) {
      Message({
        showClose: true,
        message: _errText,
        type: 'error',
        duration: toastTime
      })
    }
  }
  // todo 成功提示
  const _suSucText = sucText || res.msg || '操作成功!'
  if (reuslt && showSuc) {
    Message({
      showClose: true,
      message: _suSucText,
      type: 'success',
      duration: toastTime
    })
  }

  data.r = reuslt
  return data
}

/**
 * 处理返回jsonStr参数
 * @param data
 * @returns JSON.stringify(data)
 */
function getJsonStr (data) {
  return JSON.stringify(data)
}

/**
 * 处理返回qsObj参数
 * @param data
 * @returns qs.stringify(data)
 */
function getQsObj (data) {
  return qs.stringify(data, { indices: false })
}
