<template>
  <div style="width: 100%;height: 100%;overflow-y: auto;background: #F8F9FB">
    <div style="height: 300px;width: 100%;top: 0px;left: 0px;">
      <img src="../../../assets/top_bg.png" style="height: 100%;width: 100%"/>
    </div>
    <div style="margin-top: -275px">
      <div style="font-size: 34px;font-weight: 700;color: white;text-align: center">欢迎来到RTT开发者平台</div>
      <div style="font-size: 16px;text-align: center;color: rgba(255,255,255,0.89);margin-top: 10px">请先完善您的账号信息</div>
      <div style="display: flex">
        <div style="flex: 1"></div>
        <ez-view style="margin-top: 20px">
          <div class="ez-e-steps">
            <el-steps  :active="active" finish-status="success"  align-center style="width: 460px">
              <el-step title="选择账号类型"></el-step>
              <el-step title="完善账号信息"></el-step>
              <el-step title="完成"></el-step>
            </el-steps>
          </div>
        </ez-view>
        <div style="flex: 1"></div>
      </div>
    </div>
    <div style="display: flex">
      <div style="flex: 1"></div>
      <div class="rttm-card" v-if="active == 0" style="background-color: white;width: 800px;margin-top: 25px">
        <div style="text-align: center;font-size: 18px;font-weight: bold;margin-top: 20px">选择账号类型</div>
        <div style="display: flex;margin-top: 40px;height: 240px;">
          <div style="flex: 1"></div>
          <div @click="developerT = 1" :class="[developerT == 1 ? 'my-app-card-act':'','my-app-card']">
            <div class="my-app-card-icon">
              <img src="../../../assets/img/my/dev_type_self.png" style="width: 130px"/>
            </div>
            <div class="my-app-card-name">个人账号</div>
            <div class="my-app-card-value">
              <i class="el-icon-success"></i>
            </div>
          </div>
          <div @click="developerT = 2" :class="[developerT == 2 ? 'my-app-card-act':'','my-app-card']"  style="margin-left: 20px">
            <div class="my-app-card-icon">
              <img src="../../../assets/img/my/dev_type_company.png" style="width: 130px"/>
            </div>
            <div class="my-app-card-name">企业账号</div>
            <div class="my-app-card-value">
              <i class="el-icon-success"></i>
            </div>
          </div>
          <div style="flex: 1"></div>
        </div>
        <div style="text-align: center;margin-top: 70px;margin-bottom: 30px">
          <el-button type="primary" @click="toNext" round style="background-color: #0052D9;border: 0px;padding-left: 40px;padding-right: 40px">下一步</el-button>
        </div>
      </div>
      <div class="rttm-card" v-if="active == 1" style="background-color: white;width: 800px;margin-top: 25px;margin-bottom: 30px">
        <div style="padding: 30px;padding-top: 10px;padding-bottom: 0px">
          <div style="font-size: 18px;font-weight: 700;padding-left: 13px;border-left: 4px #0052d9 solid;height: 22px;margin-bottom: 20px;line-height: 25px">账号基础信息</div>
          <DeveloperInfoEdit :account="accountInfo" ref="developerInfoEdit"></DeveloperInfoEdit>
          <div v-if="developerT == 2" style="font-size: 18px;font-weight: 700;padding-left: 13px;border-left: 4px #0052d9 solid;height: 22px;margin-bottom: 20px;line-height: 25px;margin-top: 20px">企业基础信息</div>
          <CompanyInfoEdit v-if="developerT == 2" ref="companyInfoEdit"></CompanyInfoEdit>
        </div>
        <div style="text-align: center">
          <el-button type="primary" @click="toCertification" round style="background-color: #0052D9;padding-left: 50px;padding-right: 50px;border: 0px;color: white;margin-bottom: 10px">提交</el-button>
        </div>
      </div>
      <div class="rttm-card" v-if="active == 3" style="background-color: white;width: 800px;margin-top: 25px;margin-bottom: 30px">
        <div style="padding: 30px;padding-top: 0px;padding-bottom: 0px">
          <div style="text-align: center;font-size: 25px;font-weight: 500;margin-top: 30px">
            信息提交成功
          </div>
          <div style="text-align: center;margin-top: 40px">
            <img src="../../../assets/img/my/certification_over.png" height="180" />
          </div>
          <div style="text-align:center;color: #8c939d;text-align: center;font-size: 13px;margin-top: 50px">感谢您的填写，信息已经完善成功</div>
          <div style="text-align:center;color: #8c939d;text-align: center;font-size: 13px">接下来您可以前往控制台解锁更多功能</div>
        </div>
        <div style="text-align: center;margin-top: 30px;margin-bottom: 40px">
          <el-button type="primary" @click="toControl" round style="background-color: #0052D9;padding-left: 50px;padding-right: 50px;border: 0px;color: white;margin-bottom: 10px">{{countdown}} 前往控制台</el-button>
        </div>
      </div>
      <div style="flex: 1"></div>
    </div>
  </div>
</template>

<script>
import DeveloperInfoEdit from './info/DeveloperInfoEdit'
import AccountApi from '../../../common/api/AccountApi'
import CompanyInfoEdit from './info/CompanyInfoEdit'
export default {
  name: 'MyInfoComplete',
  components: { DeveloperInfoEdit, CompanyInfoEdit },
  data () {
    return {
      active: 0,
      nodeList: {},
      developerT: 0,
      countdown: 5,
      accountInfo: {}
    }
  },
  methods: {
    toControl () {
      this.$router.replace('/control')
      window.location.reload()
    },
    toNext () {
      if (this.developerT === 0) {
        this.$message.error('请先选择认证类型')
        return false
      }
      this.active = 1
    },
    async toCertification () {
      let developerInfo = {}
      this.$refs.developerInfoEdit.submitData((v) => { developerInfo = v })
      if (!developerInfo) {
        return false
      }
      let _r
      if (this.developerT === 1) {
        _r = await AccountApi.certificationPersonal(developerInfo)
      } else {
        let companyInfo = {}
        this.$refs.companyInfoEdit.submitData((v) => { companyInfo = v })
        companyInfo.uid = developerInfo.uid
        developerInfo.companyInfo = companyInfo
        _r = await AccountApi.certificationCompany(developerInfo)
      }

      if (_r.r) {
        this.active = 3
        const self = this
        const countdownInterval = setInterval(function () {
          self.countdown = self.countdown - 1
          if (self.countdown <= 0) {
            self.toControl()
            window.clearInterval(countdownInterval)
          }
        }, 1000)
      }
    }
  },
  created () {
    this.accountInfo = JSON.parse(JSON.stringify(this.$store.state.my.info))
  }
}
</script>

<style scoped lang="less">
  .my-app-card:hover{
    transform: scale(1,1);
    box-shadow: 5px 0px 10px rgba(35, 34, 34, 0.07);
    border: 3px white solid;
  }
  .my-app-card {
    flex: 1;
    height: 240px;
    background-image: linear-gradient(#f4f6fc, #fff);
    border: 1px #e8ebef solid;
    border-radius: 3px;
    text-align: center;
    transform: scale(0.8,0.8);
  .my-app-card-icon{
    margin-top: 12px;

    /*background: #8c939d;*/
    display: inline-block;
  }
  .my-app-card-name{
    margin-top: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #0067db;
  }
  .my-app-card-value{
    margin-top: 10px;
    color: #4faa27;
    display: none;
    font-size: 30px;
    font-weight: bold;
  }
  .my-app-card-unit{
    margin-top: 5px;
    color: #8c939d;
    font-size: 12px;
  }
  }
  .my-app-card-act{
    transform: scale(1,1);
    box-shadow: 5px 0px 10px rgba(35, 34, 34, 0.07);
    border: 3px white solid;
    .my-app-card-value{
      display: block;
    }
  }
</style>
