<template>
  <div>
    <el-form label-position="top" label-width="80px" ref="formMenu" :model="formData">
      <el-form-item label="设置头像" prop="avatar">
        <div style="height: 70px;width: 70px;border-radius: 60px;background-color: #e7eaef">
          <img :src="formData.avatar" style="height: 100%;width: 100%;border-radius: 100px">
        </div>
        <EzFileSel @afterUpload="afterUploadAvatar">
          <div class="rttm-opt-text-p" style="margin-left: 8px;margin-top: 0px;font-size: 13px">切换头像</div>
        </EzFileSel>
      </el-form-item>
      <div style="display: flex;margin-top: -20px">
        <div style="flex: 1">
          <div style="width: 100%;text-align: right;color: #F0C086;margin-bottom: -30px;font-size: 13px">
            您用于登录的账号,请牢记
          </div>
          <el-form-item label="账号">
            <el-input size="small" :maxlength="20"  v-model="account.account" disabled></el-input>
          </el-form-item>
        </div>
        <div style="flex: 1;margin-left: 50px">
          <div style="width: 100%;text-align: right;color: #F0C086;margin-bottom: -30px;font-size: 13px">
            您用于登录的手机号
          </div>
          <el-form-item label="登录手机">
            <el-input size="small" :maxlength="20"  v-model="account.phone" disabled></el-input>
          </el-form-item>
        </div>
      </div>
      <div style="display: flex;margin-top: -20px">
        <div style="flex: 1">
          <el-form-item label="账号昵称" prop="nickName" :rules="[{ required: true, message: '账号不能为空'}]">
            <el-input size="small"
                      @input="()=> {formData.nickName = formData.nickName.replaceAll(' ', '')}"
                      v-model="formData.nickName"
                      :maxlength="20"></el-input>
          </el-form-item>
        </div>
        <div style="flex: 1;margin-left: 50px">
          <el-form-item label="邮箱" prop="email"  :rules="[ { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]">
            <el-input size="small"  v-model="formData.email"
                      @input="()=> {formData.email = formData.email.replaceAll(' ', '')}"
                      :maxlength="60"></el-input>
          </el-form-item>
        </div>
      </div>
      <div style="display: flex;margin-top: -5px">
        <div style="flex: 1">
          <el-form-item label="性别" prop="sex" :rules="[{ required: true, message: '性别不能为空'}]">
            <el-radio v-for="(item,key) in sexRadios" :key="key" v-model="formData.sex" :label="item.label">{{item.name}}</el-radio>
          </el-form-item>
        </div>
        <div style="flex: 1;margin-left: 50px">
          <el-form-item label="生日" prop="birthday">
            <el-date-picker
              size="small"
              style="width: 100%"
              v-model="formData.birthday"
              :picker-options="pickerOptionsStart"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </div>
      </div>
      <div style="display: flex;margin-top: -5px">
        <div style="flex: 1">
          <el-form-item label="真实姓名" prop="realName">
            <el-input size="small" @keydown.native="keydown($event)"
                      @input="()=> {formData.realName = formData.realName.replaceAll(/[\d]/g, '')}"
                      :maxlength="20"
                      v-model="formData.realName" ></el-input>
          </el-form-item>
        </div>
        <div style="flex: 1;margin-left: 50px">
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'DeveloperInfoEdit',
  props: ['account', 'isCertification'],
  data () {
    return {
      formData: {
        avatar: this.account.avatar,
        uid: this.account.uid,
        birthday: this.account.birthday,
        nickName: this.account.nickName,
        realName: this.account.realName,
        sex: this.account.sex,
        email: this.account.email,
        age: this.account.age
        // birthday: this.account.birthday,
        // birthday: this.account.birthday,
        // birthday: this.account.birthday,
        // birthday: this.account.birthday
      },
      sexRadios: [
        {
          label: 1,
          name: '男'
        },
        {
          label: 0,
          name: '女'
        }
      ],
      pickerOptionsStart: {
        disabledDate: time => {
          return (
            time.getTime() > new Date().getTime()
          )
        }
      }
    }
  },
  methods: {
    afterUploadAvatar (d) {
      this.formData.avatar = d[0].url
    },
    // 禁止输入空格
    keydown (e) {
      if (e.keyCode === 32) {
        e.returnValue = false
      }
    },
    submitData (callBack) {
      let value = false
      this.$refs.formMenu.validate((valid) => {
        this.formData.nickName = this.formData.nickName.replaceAll(' ', '')
        if (valid) {
          this.formData.birthday = this.formData.birthday.substring(0, 10)
          this.getage()
          value = this.formData
        } else {
          value = false
          this.$message.error('信息填写不完整')
          return false
        }
      })
      callBack(value)
      return value
    },
    // 根据生日计算年龄
    getage () {
      var birthdays = new Date(this.formData.birthday.replace(/-/g, '/'))
      var d = new Date()
      var age =
        d.getFullYear() -
        birthdays.getFullYear() -
        (d.getMonth() < birthdays.getMonth() ||
        (d.getMonth() === birthdays.getMonth() &&
          d.getDate() < birthdays.getDate())
          ? 1
          : 0)
      this.formData.age = age
    }
  }
}
// sh startup.sh -m standalone
</script>

<style scoped>

</style>
