<template>
  <div class="control-card">
    <div class="control-card-title">账号资料</div>
    <div style="display: flex;margin-top: 10px">
      <div style="height: 80px;width: 80px;background: #8c939d;border-radius: 50px">
        <img :src="myInfo.avatar" style="height: 100%;width: 100%;border-radius: 50px">
      </div>
      <div style="margin-top: 2px;margin-left: 15px">
        <div><span style="font-size: 16px;font-weight: 700;">{{myInfo.nickName}}</span>
          <span v-if="$ez.util.str.isNotEmpTy(myInfo.realName)" style="font-size: 13px;font-weight: 700;margin-left: 5px">({{myInfo.realName}})</span>
        </div>
        <div style="display: flex">
          <div style="font-size: 12px;color: #a4a8ad;margin-top: 7px">
            登录账号
          </div>
          <div style="margin-top: 5px;margin-left: 10px;font-size: 13px;font-weight: 500">
            {{myInfo.account}}
          </div>
        </div>
        <div style="display: flex">
          <div style="font-size: 12px;color: #a4a8ad;margin-top: 6px">
            账号类型
          </div>
          <div style="margin-top: 5px;margin-left: 10px;font-size: 13px;font-weight: 500;font-size: 12px;display: flex">
            <img src="../../../../assets/icon/my/my_type_personal.png" style="width: 17px;height: 17px;"/>
            <div style="margin-left: 5px;color: #05476a;font-size: 12px">
             {{myInfo.developerT == 1 ? '个人用户': '企业版用户'}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 1px;background-color: #f3f5f6;margin-top: 15px;margin-bottom: 10px"></div>
    <div style="display:flex;">
      <div style="flex: 1;text-align: center;">
        <div><span style="font-size: 20px;color: #333333;font-weight: 500">{{myCount.projectCount || '-'}}</span><span style="font-size: 12px;margin-bottom: 2px">/个</span></div>
        <div style="font-size: 12px;color: #bcc1c9">手表项目</div>
      </div>
      <div style="flex: 1;text-align: center;">
        <div><span style="font-size: 20px;color: #333333;font-weight: 500">{{myCount.appCount || '-'}}</span><span style="font-size: 12px;margin-bottom: 2px">/个</span></div>
        <div style="font-size: 12px;color: #bcc1c9">穿戴应用</div>
      </div>
      <div style="height: 20px;width: 1px;background-color: #eaecee;margin-top: 8px"></div>
      <div style="flex: 1;text-align: center;">
        <div><span style="font-size: 20px;color: #333333;font-weight: 500">{{myCount.dialCount || '-'}}</span><span style="font-size: 12px;margin-bottom: 2px">/个</span></div>
        <div style="font-size: 12px;color: #bcc1c9">表盘</div>
      </div>
      <div style="height: 20px;width: 1px;background-color: #eaecee;margin-top: 8px"></div>
      <div style="flex: 1;text-align: center;">
        <div><span style="font-size: 20px;color: #333333;font-weight: 500">{{myCount.openapiProjectCount || '-'}}</span><span style="font-size: 12px;margin-bottom: 2px">/个</span></div>
        <div style="font-size: 12px;color: #bcc1c9">接口项目</div>
      </div>
      <div style="height: 20px;width: 1px;background-color: #eaecee;margin-top: 8px"></div>
      <div style="flex: 1;text-align: center;">
        <div><span style="font-size: 20px;color: #333333;font-weight: 500">{{myCount.downNum || '-'}}</span><span style="font-size: 12px;margin-bottom: 2px">/次</span></div>
        <div style="font-size: 12px;color: #bcc1c9">累计相关下载</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ControlMyInfo',
  props: ['myCount'],
  computed: {
    ...mapState('my', {
      nickName: state => state.nickName,
      myInfo: state => state.info
    })
  }
}
</script>

<style scoped>

</style>
