// const _baseUrl = 'http://192.168.88.22:6999'
const _baseUrl = process.env.NODE_ENV === 'development' ? 'http://10.23.8.26:6999' : 'https://wearable-appstore.rt-thread.com/api'
const appConfig = {
  baseUrl: _baseUrl,
  upload: {
    defUrl: _baseUrl + '/rtt/resources/upload/imgs',
    defTypes: ['png', 'jpg', 'jpeg', 'bmp'],
    types: {
      img: ['png', 'jpg', 'jpeg', 'bmp'],
      rttApp: ['prc'],
      rttDial: ['dial']
    },
    urls: {
      files: _baseUrl + '/rtt/resources/upload/files',
      imgs: _baseUrl + '/rtt/resources/upload/imgs',
      packages: _baseUrl + '/rtt/resources/upload/packages'
    }
  },
  testUrl: '',
  isTest: false
}

export default appConfig
