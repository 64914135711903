<template>
  <el-table
    :data="softwareList"
    style="width: 100%">
    <el-table-column
      fixed
      prop="version"
      width="100"
      label="LOGO">
      <template slot-scope="scope">
        <img :src="scope.row.logo" style="height: 50px;width: 50px;object-fit: cover;border-radius: 10px;margin-top: 6px">
      </template>
    </el-table-column>
    <el-table-column
      prop="name"
      label="名称">
      <template slot-scope="scope">
        <div>
          <div style="font-size: 14px;font-weight: 500">{{scope.row.name}}</div>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="zip"
      label="分类">
      <template slot-scope="scope">
        <div style="display: flex">
          <div style="height: 21px;line-height: 22px;background-color: rgba(23,110,255,0.07);
            box-sizing: content-box;
            font-size: 13px;color: #0052D9;padding-left: 10px;padding-right: 10px">
            {{scope.row.categoryName}}
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="address"
      label="收费"
      width="100">
      <template>
        <div style="color: #0052D9">
          免费
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="address"
      label="更新时间">
      <template slot-scope="scope">
        <p style="color: #8c939d"> {{$ez.util.time.milliToYMDHMS(scope.row.utime)}}</p>
      </template>
    </el-table-column>
    <el-table-column
      fixed="right"
      label="操作"
      width="60">
      <template slot-scope="scope">
        <el-button @click="goPage(scope.row.id)" type="text" size="small" style="font-size: 13px;color: #266cde">详情</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'ControlTable',
  props: {
    softwareList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    goPage (id) {
      this.$emit('toDetail', id)
    }
  }
}
</script>
