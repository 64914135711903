import { AppApiResult, AppRequest } from './request/AppRequest'

export default {
  async verifyCodeSend (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/ezcm/sys/verifyCode/sendAli',
        param: p,
        config: { showLoad: true, loadText: '正在注册中' }
      })
    })
  }
}
