<template>
    <div>
      <div class="rttm-card" style="padding: 30px;padding-top: 20px;padding-bottom: 15px;max-height: calc(100vh - 230px);overflow-y: auto;">
        <el-tag size="small"  style="width: 100%;margin-bottom: 10px;height: 29px;border-radius: 0px;line-height: 28px">
          Android包名 和 IOS Bundle ID 至少填写一个
        </el-tag>
        <el-form label-position="left" label-width="115px" ref="form"   :rules="rules"  :model="formData">
          <div style="">
            <el-form-item label="应用名称"  prop="name" :rules="[{ required: true, message: '项目名称不能为空'}]">
              <el-input size="small" v-model="formData.name" @input="()=> {formData.name = formData.name.replaceAll(' ', '')}" :maxlength="20"  placeholder="请输入项目名称"></el-input>
            </el-form-item>
            <el-form-item label="应用类型" prop="type" :rules="[{ required: true, message: '请选择项目类型'}]">
              <el-select :disabled="!isAdd" v-model="formData.type" size="small" style="width: 100%" placeholder="请选择项目类型">
                <el-option
                  label="请选择"
                  :value="null">
                </el-option>
                <el-option
                  v-if="developerT == 2"
                  label="健康运动类应用"
                  :value="1">
                </el-option>
                <el-option
                  label="第三方应用"
                  :value="2">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Android包名" prop="androidPackageName" >
              <el-input size="small" v-model="formData.androidPackageName" :maxlength="100"   placeholder="请输入Android包名"></el-input>
            </el-form-item>
            <el-form-item label="Android SHA1" prop="androidSha1" :rules="[{ required: formData.androidPackageName, message: '当填写了Android包名时,SHA1也必须填写'}]">
              <el-input size="small"  v-model="formData.androidSha1" :maxlength="100"  :rows="4" placeholder="请输入Android SHA1"></el-input>
            </el-form-item>
            <el-form-item label="IOS Bundle ID" prop="iosBundleIdentifier">
              <el-input size="small"  v-model="formData.iosBundleIdentifier" :maxlength="100"  placeholder="请输入IOS Bundle ID"></el-input>
            </el-form-item>
            <el-form-item label="项目描述" prop="description" :rules="[{ required: true, message: '项目描述不能为空'}]">
              <el-input size="small" :maxlength="250"  v-model="formData.description" @input="()=> {formData.description = formData.description.replaceAll(' ', '')}" type="textarea" :rows="4" placeholder="请输入项目描述"></el-input>
            </el-form-item>
          </div>
          <div></div>
        </el-form>
      </div>
<!--      <div style="height: 1px;background-color: #eef0f3;"></div>-->
      <div style="text-align: center;margin-top: 20px">
        <el-button type="primary" style="background-color: #0052D9;border: 0px;height: 38px" @click="saveData()">{{isAdd ? '提交创建': '提交修改'}}</el-button>
      </div>
      <div style="height: 20px"></div>
    </div>
</template>

<script>
import OpenapiApi from '../../../../common/api/OpenApi'

import { mapState } from 'vuex'

export default {
  name: 'OpenapiProjectEdit',
  props: ['isAdd', 'project'],
  computed: {
    ...mapState('my', {
      developerT: state => state.developerT
    })
  },
  data () {
    var packageCheck = (rule, value, callback) => {
      if (!this.formData.androidPackageName && !this.formData.iosBundleIdentifier) {
        return callback(new Error('Android包名 和 IOS Bundle ID 至少填写一个'))
      }
      // console.log(this.formData.androidPackageName.indexOf('-'))
      // if (this.formData.androidPackageName.indexOf('-') === -1) {
      //   return callback(new Error('输入格式为xxx-xxx'))
      // }
      callback()
    }
    return {
      formData: {
        type: this.isAdd ? null : this.project.type,
        id: this.isAdd ? null : this.project.id,
        name: this.isAdd ? null : this.project.name,
        androidSha1: this.isAdd ? null : this.project.androidSha1,
        androidPackageName: this.isAdd ? null : this.project.androidPackageName,
        iosBundleIdentifier: this.isAdd ? null : this.project.iosBundleIdentifier,
        description: this.isAdd ? null : this.project.description
      },
      rules: {
        androidPackageName: [
          { validator: packageCheck, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async saveData () {
      const _value = this.$ez.util.form.checkData({ that: this, formData: this.formData })
      if (_value === false) {
        return false
      }
      let _r
      if (this.isAdd) {
        _r = await OpenapiApi.createProject(_value)
      } else {
        _r = await OpenapiApi.editProject(_value)
      }
      if (_r.r) {
        this.$emit('saveOver')
      }
    }
  }
}
</script>

<style scoped>

</style>
