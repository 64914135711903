<template>
    <div class="rttm-card" style="padding: 40px;min-height: 330px;padding-top: 20px">
      <ez-state-view :state="state" @reLoadFun="getIsHasPassword" style="">
        <div v-if="isHasOPassword">
<!--          <div style="font-size: 15px;margin-top: -20px;font-weight: 500">请输入原始密码</div>-->
<!--          <div>-->
<!--            <div style="display: flex;margin-top: 12px">-->
<!--              <div class="phone-input" style="flex: 1;">-->
<!--                <el-input v-model="oPassword" size="small" style="background-color: #F6F6F6;padding-top: 4px;padding-bottom: 4px" placeholder="请输入原始密码"></el-input>-->
<!--              </div>-->
<!--              <div :style="{'background-color': oPasswordIsOk ? '#35af19': '#0052D9'}" style="height: 40px;width: 60px;line-height: 40px;background-color: #0052D9;color: white;-->
<!--            font-size: 14px;border-radius: 5px;text-align: center;font-weight: 500;border-bottom-left-radius: 0px;border-top-left-radius: 0px;">-->
<!--                {{ oPasswordIsOk ? 'OK': '验证'}}-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div v-else style="margin-top: 0px;padding-bottom: 20px">
          <el-tag type="warning" style="width: 100%">您的账号还未设置登录密码,为了安全起见请尽快设置</el-tag>
        </div>
<!--        <div style="margin-top: -20px;font-size: 15px;font-weight: 500">发送验证码完成安全认证</div>-->
<!--        <div style="font-weight: 700;font-size: 20px;display: flex">-->
<!--          <div style="flex: 1;padding-top: 5px">-->
<!--            15751735395-->
<!--          </div>-->
<!--          <div  @click="verifyCodeSend"  v-if="canSend" style="width: 110px;text-align: center;line-height: 34px;background-color: #4a4e50;color: white;font-size: 13px">-->
<!--            发送验证码-->
<!--          </div>-->
<!--          <div v-else style="width: 110px;text-align: center;line-height: 34px;background-color: #a9adb1;color: white;font-size: 13px">-->
<!--            {{countdown}}秒后重新发送-->
<!--          </div>-->
<!--        </div>-->
<!--        <div style="display: flex;margin-top: 0px;border-radius: 5px;overflow: hidden">-->
<!--          <div class="verifyCode-input" style="flex: 1">-->
<!--            <div>-->
<!--              <div style="display: flex;margin-top: 12px">-->
<!--                <div class="phone-input" style="flex: 1;">-->
<!--                  <el-input v-model="verifyCode" size="small" style="background-color: #F6F6F6;padding-top: 4px;padding-bottom: 4px" placeholder="请输入验证码"></el-input>-->
<!--                </div>-->
<!--                <div :style="{'background-color': oPasswordIsOk ? '#35af19': '#0052D9'}" style="height: 40px;width: 60px;line-height: 40px;background-color: #0052D9;color: white;-->
<!--            font-size: 14px;border-radius: 5px;text-align: center;font-weight: 500;border-bottom-left-radius: 0px;border-top-left-radius: 0px;">-->
<!--                  {{ oPasswordIsOk ? 'OK': '验证'}}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div style="font-size: 15px;font-weight: 500">请输入密码</div>
        <div>
          <div style="display: flex;margin-top: 12px">
            <div class="phone-input" style="flex: 1;">
              <el-input type="password"
                        v-model="password"
                        size="small" style="background-color: #F6F6F6;padding-top: 4px;padding-bottom: 4px"
                        :maxlength="20"
                        @input="()=> {password = password.replaceAll(' ', '')}"
                        placeholder="请输入密码" show-password></el-input>
            </div>
          </div>
        </div>
        <div :style="{'color':passwordErr ? '#fc2747': '#999'}" style="color: #d9005e;font-size: 13px;margin-top: 10px;">密码需要8-20位，至少包含数字+字母,不可包含特殊字符</div>
        <div style="font-size: 15px;margin-top: 20px;font-weight: 500">确认密码</div>
        <div>
          <div style="display: flex;margin-top: 12px">
            <div class="phone-input" style="flex: 1;">
              <el-input type="password"
                        v-model="rPassword"
                        :maxlength="20"
                        @input="()=> {rPassword = rPassword.replaceAll(' ', '')}"
                        size="small"
                        style="background-color: #F6F6F6;padding-top: 4px;padding-bottom: 4px"
                        placeholder="请输入确认密码" show-password></el-input>
            </div>
          </div>
        </div>
        <div style="display: flex">
          <div @click="toUpdatePassword"  style="height: 44px;flex: 1;text-align: center;
            border-radius: 50px;margin-top: 40px;font-size: 16px;
            color: white;font-weight: 500;
            line-height: 44px;background-color: #0052D9">
            立即设置
          </div>
        </div>
        <div style="font-size: 15px;margin-top: 10px;text-align: center;margin-top: 20px" >
          <el-tag style="width: 100%">登录密码更换后需要您重新登录</el-tag>
        </div>
      </ez-state-view>
    </div>
</template>

<script>
import AccountApi from '../../../../../common/api/AccountApi'

export default {
  name: 'UpdatePassword',
  data () {
    return {
      state: this.$ez.model.viewState(0),
      oPassword: null,
      password: null,
      rPassword: null,
      passwordErr: false,
      oPasswordIsOk: false,
      isHasOPassword: false,
      verifyCode: null,
      canSend: true
    }
  },
  methods: {
    async getIsHasPassword () {
      const _r = await AccountApi.isHasPassword()
      if (_r.r) {
        if (_r.data) {
          this.isHasOPassword = true
        }
        this.state.toSuc()
      } else {
        this.state.toFail()
      }
    },
    async toUpdatePassword () {
      if (!this.password || this.password?.length < 8 || this.password?.length > 20) {
        this.passwordErr = true
        this.$message.error(this.password ? '密码长度格式不正确！' : '密码不能为空！')
        return false
      }
      let flag = false

      if (this.password.match(/[\d]/) && this.password.match(/[A-Za-z]/)) { // 数字 + 字母
        flag = true
      }
      if (this.password.match(/[^\da-zA-Z]/)) {
        this.$message.error('密码不可包含特殊字符串！')
        this.passwordErr = true
        return false
      }
      // else if (this.password.match(/[\d]/) && this.password.match(/[^\da-zA-Z]/)) { // 数字 + 字符
      //   flag = true
      // } else if (this.password.match(/[A-Za-z]/) && this.password.match(/[^\da-zA-Z]/)) { // 字母 + 字符
      //   flag = true
      // }
      if (!flag) {
        this.$message.error('密码格式不正确！')
        this.passwordErr = true
        return false
      }
      if (this.password !== this.rPassword) {
        this.$message.error('两次密码不一致,请检查无误后再提交')
        return false
      }

      const _isOldR = await AccountApi.verifyPassword({ password: this.$ez.util.encrypt.base64.encode(this.password) })
      if (!_isOldR.r) {
        return false
      }

      if (_isOldR.data) {
        this.$message.error('新密码和旧密码不能相同,请换一个密码吧！')
        return false
      }

      const _r = await AccountApi.updatePassword({ password: this.$ez.util.encrypt.base64.encode(this.password) })
      if (_r.r) {
        // this.$message.success('密码设置成功！')
        this.$store.commit('login/LOGIN_OUT')
        const self = this
        setTimeout(function () {
          self.$router.push({ path: '/', query: { isLogin: true } })
        }, 1000)
      }
    }
  },
  created () {
    this.getIsHasPassword()
  }
}
</script>

<style scoped>
  .phone-input{
    border-radius: 5px;
    overflow: hidden;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
  .phone-input >>> .el-input__inner{
    background-color: #F6F6F6;
    border: 0px;
    font-size: 15px;
  }
  .verifyCode-input >>> .el-input__inner{
    border: 0px;
    font-size: 15px;
    background-color: #F6F6F6;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
</style>
