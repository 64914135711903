<template>
  <div class="software-comment-item-container">
    <div class="comment-score">
      <el-rate v-model="commentInfo.score" score-template="{value}"  disabled show-score text-color="#ff9900"></el-rate>
      <span>{{ $ez.util.time.milliToYMDHMS(commentInfo.ctime) }}</span>
    </div>
    <div class="comment-content">
      <el-input type="textarea" :readonly="true" resize="none" :value="commentInfo.content"></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentItem',
  props: {
    commentInfo: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getTime (ctime) {
      return new Date(ctime * 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.software-comment-item-container {
  display: flex;
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  flex-direction: column;
  .comment-score{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}
</style>
