<template>
  <div style="background-color: white;border: 1px #f3f4f6 solid;height: 100%" >
    <div style="font-size: 16px;color: #333333;font-weight: bold;padding-bottom: 0px;
        padding-top: 20px;margin-left: 20px">
      SDK列表
    </div>
    <ez-state-view :state="state">
      <div class="sdk-menu-group" style="margin-top: 15px;  color: #666;">
        <div v-for="(item,key) in dirList"
             :key="key">
          <div class="sdk-menu-group-title"
               :class="(activeDir == item.id && !activeSdk) ? 'sdk-menu-group-title-act':''"
               @click="changRoute(item.id, null,0)">
            <div style="flex: 1">
              {{item.name}}
            </div>
            <div style="font-size: 18px;margin-right: 15px;padding-top: 2px">
              <i :class="activeDir == item.id ? 'el-icon-arrow-down':'el-icon-arrow-right'"></i>
            </div>
          </div>
          <div v-show="activeDir == item.id">
            <div v-for="(item2,key2) in item.sdkList"
                 :key="key2"
                 :class="item2.id == activeSdk ? 'sdk-menu-title-act':''"
                 class="sdk-menu-title"
                 @click="changRoute(item.id, item2.id,1)">{{item2.dirName}}</div>
          </div>
        </div>
<!--        <div class="sdk-menu-title" style="background-color: #f0f4fc">Android</div>-->
<!--        <div class="sdk-menu-title">IOS</div>-->
<!--        <div class="sdk-menu-group-title">第三方应用SDK</div>-->
<!--        <div class="sdk-menu-title">Android</div>-->
<!--        <div class="sdk-menu-title">IOS</div>-->
      </div>
    </ez-state-view>
  </div>
</template>

<script>
import OpenApi from '../../../../common/api/OpenApi'

export default {
  name: 'SdkDirectoryList',
  data () {
    return {
      activeDir: null,
      activeSdk: null,
      dirList: [],
      state: this.$ez.model.viewState(0)
    }
  },
  watch: {
    $route () {
      this.activeDir = this.$route.query.directoryId
      this.activeSdk = this.$route.query.sdkId
    }
  },
  methods: {
    async getDirList () {
      this.state.toLoading()
      const _r = await OpenApi.sdkDirectoryList({})
      const _r2 = await OpenApi.sdkList({})
      if (_r) {
        this.state.toSuc()
        for (const v of _r.data) {
          v.sdkList = []
          for (const x of _r2.data) {
            if (v.id === x.directoryId) {
              v.sdkList.push(x)
            }
          }
        }
        this.dirList = _r.data
        if (!this.activeDir) {
          this.changRoute(this.dirList[0].id, null, 0)
        }
      } else {
        this.state.toFail()
      }
    },
    changRoute (directoryId, sdkId, type) {
      this.activeDir = directoryId
      this.$router.replace({ path: '/sdkList', query: { directoryId: directoryId, sdkId: sdkId, type: type } })
    }
  },
  created () {
    this.activeDir = this.$route.query.directoryId
    this.activeSdk = this.$route.query.sdkId
    this.getDirList()
  }
}
</script>

<style scoped>
  .sdk-menu-group-title:hover{
    background-color: #1B8AFA;
    color: white;
  }
  .sdk-menu-group-title-act{
    background-color: #1B8AFA;
    color: white;
  }
.sdk-menu-group-title{
  display: flex;
  height: 46px;
  line-height: 46px;
  left: 40px;
  padding-left: 20px;
  font-size: 15px;
  font-weight: 500;
}
  .sdk-menu-title{
    height: 46px;
    color: #666;
    line-height: 46px;
    font-size: 15px;
    font-weight: 500;
    padding-left: 50px;
  }
  .sdk-menu-title:hover{
    background-color: #f0f4fc;
    color: #1B8AFA;
  }
  .sdk-menu-title-act{
    background-color: #f0f4fc;
    color: #1B8AFA;
  }
</style>
