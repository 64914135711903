import { AppApiResult, AppRequest } from './request/AppRequest'
export default {
  /**
   * 获取个人用用列表
   * @param {*} p
   * @returns
   */
  async getMySoftwareList (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/base/mySoftwareList',
        method: 'get',
        param: p,
        config: { showLoad: false, loadText: '用户信息获取中' }
      })
    })
  },
  /**
   * 获取指定应用详情
   * @param {*} p
   * @returns
   */
  async getSoftwareDetail (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/base/v2/detail',
        method: 'get',
        param: p,
        config: { showLoad: false, loadText: '应用提交中' }
      })
    })
  },
  /**
   * 新建应用
   * @param {*} p
   * @returns
   */
  async createSoftware (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/base/create',
        param: p,
        // paramType: 'JSON',
        config: { showLoad: true, loadText: '应用提交中' }
      }),
      showSuc: true,
      sucText: '保存成功'
    })
  },
  /**
   * 更新应用信息
   * @param {*} p
   * @returns
   */
  async editSoftware (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/base/edit',
        param: p,
        // paramType: 'JSON',
        config: { showLoad: true, loadText: '应用提交中' }
      }),
      showSuc: true,
      sucText: '保存成功'
    })
  },
  /**
   * 获取软件分类
   * @param {*} p
   * @returns
   */
  async getCategoryList (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/category/categoryList',
        method: 'get',
        param: p,
        config: { showLoad: false, loadText: '应用提交中' }
      })
    })
  },
  /**
   * 更新应用版本
   * @param {*} p
   * @returns
   */
  async addVersion (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/version/add',
        param: p,
        // paramType: 'JSON',
        config: { showLoad: true, loadText: '应用版本提交中' }
      })
    })
  },
  /**
   * 获取应用版本历史记录
   * @param {*} p
   * @returns
   */
  async getHistoryPage (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/version/historyPage',
        method: 'get',
        param: p,
        config: { showLoad: false, loadText: '应用提交中' }
      })
    })
  },
  /**
   * 获取应用数量
   * @param {*} p
   * @returns
   */
  async getDeveloperCount (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/base/developerCount',
        param: p,
        config: { showLoad: false, loadText: '应用提交中' }
      })
    })
  },
  async developerUpper (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/base/developerUpper',
        param: p,
        config: { showLoad: true, loadText: '操作提交中' }
      }),
      showSuc: true,
      sucText: '操作成功'
    })
  },
  async commentSearch (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/comment/search',
        method: 'get',
        param: p,
        config: { showLoad: false, loadText: '操作提交中' }
      }),
      showSuc: false,
      showErr: false,
      errText: '',
      sucText: '操作成功'
    })
  },
  async publishReply (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/comment/publishReply',
        param: p,
        config: { showLoad: true, loadText: '操作提交中' }
      }),
      showSuc: true,
      sucText: '操作成功'
    })
  },
  /**
   * 获取下载链接
   * @param {*} p
   * @returns
   */
  async getDownloadUrl (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/download/getDownloadUrl',
        method: 'get',
        param: p,
        config: { showLoad: true, loadText: '正在准备下载...' }
      })
    })
  },
  /**
   * 分页获取评论列表
   * @param {*} p
   */
  async getCommentList (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/comment/v2/getComment',
        method: 'get',
        param: p,
        config: { showLoad: true, loadText: '正在加载...' }
      })
    })
  },

  /** ======== 手表项目相关 ========= */

  /**
   * 创建手表项目
   * @param {*} p
   * @returns
   */
  async createProject (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/project/create',
        param: p,
        config: { showLoad: true, loadText: '加载中...' }
      }),
      showSuc: true,
      sucText: '创建成功'
    })
  },
  /**
   * 获取用户的手表项目列表
   * @param {*} p
   * @returns
   */
  async getProjectsByUid (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/project/getProjectList',
        param: p,
        method: 'get',
        config: { showLoad: true, loadText: '加载中...' }
      }),
      showSuc: false
    })
  },
  /**
   * 获取项目分类列表
   * @returns
   */
  async getProjectCategory () {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/project/category',
        method: 'get',
        config: { showLoad: true, loadText: '加载中...' }
      }),
      showErr: true,
      errText: '产品分类信息加载失败，请稍后重试....'
    })
  },
  /**
   * 更新项目信息
   * @param {*} p
   * @returns
   */
  async updateProject (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/project/update',
        param: p,
        config: { showLoad: true, loadText: '加载中...' }
      }),
      showErr: true,
      errText: '项目信息更新失败....',
      showSuc: true,
      sucText: '更新成功'
    })
  },
  /**
   * 更新软件版本上架状态
   * @param {*} p
   * @returns
   */
  async updateVersionStatus (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/software/version/updateState',
        param: p,
        method: 'get',
        config: { showLoad: true, loadText: '加载中...' }
      })
    })
  }
}
