<template>
    <div class="rttm-card" style="padding: 20px">
      <div style="display: flex">
        <div class="rttm-card-title" style="font-size: 15px;flex: 1;margin-top: 2px">
          最近操作的手表项目
        </div>
        <template>
          <el-button @click="toProject" type="text" size="small" style="font-size: 13px;color: #0052D9">查看全部</el-button>
        </template>
      </div>
      <EzStateView :noDataReload="false" :state="state" padding-t="20px" style="margin-top: 10px">
        <div v-if="state.isSuc" >
          <ControlTable :softwareList="latestProjectList" @toDetail="toProject" />
        </div>
      </EzStateView>
    </div>
</template>
<script>
import SoftwareApi from '@/common/api/SoftwareApi'
import ControlTable from '../../components/ControlTable.vue'
export default {
  name: 'ControlProjectTable',
  components: {
    ControlTable
  },
  data () {
    return {
      latestProjectList: [],
      state: this.$ez.model.viewState(0)
    }
  },
  methods: {
    toProject (id) {
      this.$router.push({
        name: 'project',
        params: {
          id: id
        }
      })
    },
    getLatestProjectList () {
      SoftwareApi.getProjectsByUid({
        pageNo: 1,
        pageSize: 3
      }).then(res => {
        this.latestProjectList = res.data.records
        if (this.latestProjectList.length) {
          this.state.toSuc()
        } else {
          this.state.toNoData()
        }
      }).catch(err => {
        console.error(err)
        this.state.toFail()
      })
    }
  },
  created () {
    this.getLatestProjectList()
  }
}
</script>
