<template>
  <div class="rttm-card" style="padding-top: 15px">

<!--    <div style="font-size: 13px;margin-top: 10px"></div>-->
    <div style="margin-top: 0px">
      <el-tag type="warning" style="width: 100%">更换绑定手机号后,可以使用新的手机号及当前密码进行登录</el-tag>
    </div>
    <div style="font-size: 16px;font-weight: 500;margin-top: 15px">输入新的手机号</div>
    <div>
      <div style="display: flex;margin-top: 20px">
        <div style="height: 40px;width: 60px;line-height: 40px;background-color: #F6F6F6;
            font-size: 16px;border-radius: 5px;text-align: center;font-weight: 500">+86</div>
        <div class="phone-input" style="margin-left: 5px;flex: 1;">
          <el-input v-model="phone" size="small" :maxlength="20"   @input="canSend = (phone != sendPhone ? true : (countdown <= 0 ? true: false))"  style="background-color: #F6F6F6;padding-top: 4px;padding-bottom: 4px" placeholder="请输入你要切换的手机号"></el-input>
        </div>
      </div>
      <div style="display: flex;margin-top: 20px;border-radius: 5px;overflow: hidden">
        <div class="verifyCode-input" style="flex: 1">
        <el-input v-model="verifyCode" :maxlength="20"  style="background-color: #F6F6F6;" placeholder="请输入验证"></el-input>
        </div>
        <div  @click="verifyCodeSend"  v-if="canSend" style="width: 120px;text-align: center;line-height: 40px;background-color: #4a4e50;color: white;font-size: 13px">
          发送验证码
        </div>
        <div v-else style="width: 120px;text-align: center;line-height: 40px;background-color: #a9adb1;color: white;font-size: 13px">
          {{countdown}}秒后重新发送
        </div>
      </div>
      <div style="display: flex">
        <div @click="toLogin"   style="height: 44px;flex: 1;text-align: center;
            border-radius: 50px;margin-top: 40px;font-size: 16px;
            color: white;font-weight: 500;
            line-height: 44px;background-color: #0052D9">
          立即更换
        </div>
      </div>
    </div>
    <div style="font-size: 15px;margin-top: 10px;text-align: center;margin-top: 20px" >
      <el-tag style="width: 100%">手机号更换后需要您重新登录</el-tag>
    </div>
  </div>
</template>

<script>
import EzcmSysApi from '../../../../../common/api/EzcmSysApi'
import AccountApi from '../../../../../common/api/AccountApi'

export default {
  name: 'UpdatePhone',
  data () {
    return {
      phone: null,
      verifyCode: null,
      countdown: 0,
      canSend: true,
      sendPhone: false
    }
  },
  methods: {
    // todo 前往登录
    async toLogin () {
      if (this.sendPhone && this.phone !== this.sendPhone) {
        this.$message.error('请发送验证码')
        return false
      }
      if (!this.checkPhone(this.phone)) {
        return false
      }
      if (!this.submitCheck()) return false
      const _r = await AccountApi.updatePhone(
        { phone: this.phone, verifyCode: this.verifyCode, sendCode: this.sendCode })
      if (_r.r) {
        this.$store.commit('login/LOGIN_OUT')
        const self = this
        setTimeout(function () {
          self.$router.push({ path: '/', query: { isLogin: true } })
        }, 1000)
      }
    },
    // todo 发送验证码
    async verifyCodeSend () {
      if (!this.checkPhone(this.phone)) return false
      this.sendPhone = this.phone
      const _rP = await AccountApi.isHasDeveloperPhone({ phone: this.phone })
      if (_rP.r) {
        if (_rP.data) {
          this.$message.error('该手机号已被注册，请换一个吧！')
          return false
        }
      } else {
        return false
      }
      const _r = await EzcmSysApi.verifyCodeSend({ phone: this.sendPhone })
      if (_r.r) {
        this.sendCode = _r.data
        this.canSend = false
        this.countdown = 60
        const self = this
        const countdownInterval = setInterval(function () {
          self.countdown = self.countdown - 1
          if (self.countdown <= 0) {
            self.canSend = true
            window.clearInterval(countdownInterval)
          }
        }, 1000)
      }
    },
    // todo 提交检查
    submitCheck () {
      if (this.$ez.util.str.isEmpty(this.sendCode)) {
        if (!this.checkPhone(this.phone)) return false
      } else {
        if (!this.checkPhone(this.sendPhone)) return false
      }
      if (this.$ez.util.str.isEmpty(this.sendCode)) {
        this.$message.error('请发送验证码')
        return false
      }
      if (this.$ez.util.str.isEmpty(this.verifyCode)) {
        this.$message.error('请填写验证码')
        return false
      }
      return true
    },
    // todo 手机号检查
    checkPhone (phone) {
      if (!this.phone) {
        this.$message.error('请输入手机号!')
        return false
      }
      if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))) {
        this.$message.error('手机号不正确!')
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>
  .phone-input{
    border-radius: 5px;
    overflow: hidden;
  }
  .phone-input >>> .el-input__inner{
    background-color: #F6F6F6;
    border: 0px;
    font-size: 15px;
  }
  .verifyCode-input >>> .el-input__inner{
    border: 0px;
    font-size: 15px;
    background-color: #F6F6F6;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
</style>
