<template>
  <div>
    <el-form label-position="top" label-width="80px" ref="formMenu" :model="formData" :rules="rules" >
      <el-form-item label="营业执照" prop="businessLicenseImg" ref="businessLicenseImg" style="margin-top: -5px">
        <div style="display: flex;margin-top: 5px">
          <div v-if="formData.businessLicenseImg" style="height: 120px;width: 120px;position: relative;margin-right: 25px">
            <img :src="formData.businessLicenseImg" style="height: 100%;width: 100%;object-fit: cover;border-radius: 2px">
            <div @click="formData.businessLicenseImg = null" style="height: 24px;width: 24px;background-color: rgba(0,0,0,0.6);
                color: white;font-size: 16px;line-height: 26px;text-align: center;
                position: absolute;top: -10px;right: -10px;border-radius: 20px">
              <i class="el-icon-close" style="margin-left: 2px"></i>
            </div>
<!--            <div style="height: 18px;margin-top: -10px;font-size: 13px;font-weight: bold;color: #06d6a7;">-->
<!--              <i class="el-icon-success" style="font-size: 13px;margin-top: 5px"></i>上传成功-->
<!--            </div>-->
          </div>
          <div>
<!--            <EzFileSel v-if="businessLicenseFileList.length > 0" @afterUpload="afterLicenseFileImg" style="">-->
<!--              <img src="../../../../assets/img/sel_file.png" height="120" width="120" />-->
<!--            </EzFileSel>-->
            <EzFileSel v-if="!formData.businessLicenseImg" @afterUpload="afterLicenseFileImg" style="" >
              <img src="../../../../assets/img/sel_file.png" height="120" width="120" />
            </EzFileSel>
            <div style="height: 18px;margin-top: -20px;font-size: 13px;font-weight: bold;color: #06d6a7;">
            </div>
          </div>
          <img src="../../../../assets/img/my/company_shiyitu.png" height="120" width="120" style="margin-left: 30px"/>
        </div>
      </el-form-item>
      <el-form-item label="企业注册名称" prop="name" :rules="[{ required: true, message: '企业注册名称不能为空'}]" style="margin-top: 30px">
        <el-input size="small" v-model="formData.name"
                  @input="()=> {formData.name = formData.name.replaceAll(' ', '')}"
                  :maxlength="20"
                  placeholder="请填写营业执照上的全称，仅支持中国大陆工商局或市场监督管理局登记的企业"></el-input>
      </el-form-item>
      <el-form-item label="营业执照号" prop="businessLicenseNo">
        <el-input size="small" v-model="formData.businessLicenseNo"
                  :maxlength="20"
                  @input="()=> {formData.businessLicenseNo = formData.businessLicenseNo.replaceAll(' ', '')}"
                  placeholder="请填写15位营业执照注册号或18位统一社会信用代码"></el-input>
      </el-form-item>
      <el-form-item label="所属行业"
                    prop="industry"
                    :rules="[{ required: true, message: '所属行业不能为空'}]">
        <el-input size="small"
                  v-model="formData.industry" @keydown.native="keydown($event)"
                  @input="()=> {formData.industry = formData.industry.replaceAll(' ', '')}"
                  :maxlength="20"
                  placeholder="请输入所属行业"></el-input>
      </el-form-item>
      <el-form-item label="企业介绍" prop="introduce" @input="()=> {formData.introduce = formData.introduce.replaceAll(' ', '')}" :rules="[{ required: true, message: '企业介绍不能为空'}]">
        <el-input size="small"
                  :maxlength="250"
                  @input="()=> {formData.introduce = formData.introduce.replaceAll(' ', '')}"
                  v-model="formData.introduce" placeholder="请输入企业介绍"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'CompanyInfoEdit',
  data () {
    var businessLicenseNoCheck = (rule, value, callback) => {
      if (this.formData.businessLicenseNo.length !== 18 && this.formData.businessLicenseNo.length !== 15) {
        return callback(new Error('营业执照号应为15或18位数字,字母请检查'))
      }
      var jgpattern = /^[A-Za-z0-9]+$/
      if (!jgpattern.test(this.formData.businessLicenseNo)) {
        return callback(new Error('营业执照号应为15或18位数字,字母请检查'))
      }
      callback()
    }
    return {
      formData: {
        businessLicenseImg: null,
        businessLicenseNo: null,
        name: null,
        industry: null,
        companySize: null,
        introduce: null
      },
      rules: {
        businessLicenseNo: [
          { required: true, message: '营业执照号不能为空' },
          { validator: businessLicenseNoCheck, trigger: 'blur' }
        ],
        businessLicenseImg: [
          { required: true, message: '请上传营业执照', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    submitData (callBack) {
      let value = false
      this.$refs.formMenu.validate((valid) => {
        if (valid) {
          value = this.formData
        } else {
          value = false
          this.$message.error('信息填写不完整')
          return false
        }
      })
      callBack(value)
      return value
    },
    afterLicenseFileImg (d) {
      this.formData.businessLicenseImg = d[0].url
      if (this.formData.businessLicenseImg != null) {
        this.$refs.businessLicenseImg.clearValidate()
      }
    },
    saveData () {
    }
  },
  // 禁止输入空格
  keydown (e) {
    if (e.keyCode === 32) {
      e.returnValue = false
    }
  },
  created () {
    if (this.$store.state.my.companyInfo) {
      this.formData.businessLicenseImg = this.$store.state.my.companyInfo.businessLicenseImg
      this.formData.businessLicenseNo = this.$store.state.my.companyInfo.businessLicenseNo
      this.formData.name = this.$store.state.my.companyInfo.name
      this.formData.industry = this.$store.state.my.companyInfo.industry
      this.formData.companySize = this.$store.state.my.companyInfo.companySize
      this.formData.introduce = this.$store.state.my.companyInfo.introduce
    }
  }
}
</script>

<style scoped>

</style>
