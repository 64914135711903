import { AppApiResult, AppRequest } from './request/AppRequest'
export default {
  async myDetail () {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/member/developer/myDetail',
        param: {},
        config: { showLoad: true, loadText: '用户信息获取中' }
      })
    })
  },
  async certificationPersonal (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/member/developer/certificationPersonal',
        param: p,
        paramType: 'JSON',
        config: { showLoad: true, loadText: '用户信息获取中' }
      })
    })
  },
  async certificationCompany (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/member/developer/certificationCompany',
        param: p,
        paramType: 'JSON',
        config: { showLoad: true, loadText: '用户信息获取中' }
      })
    })
  },
  async userEdit (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/member/user/edit',
        param: p,
        config: { showLoad: true, loadText: '正在提交信息' }
      }),
      showSuc: true,
      sucText: '您的信息已成功编辑！'
    })
  },
  async updateCompany (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/member/developer/updateCompany',
        param: p,
        paramType: 'JSON',
        config: { showLoad: true, loadText: '正在提交企业信息' }
      }),
      showSuc: true,
      sucText: '企业信息提交成功！'
    })
  },
  async isHasDeveloperPhone (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/member/developer/isHasDeveloperPhone',
        param: p,
        config: { showLoad: true, loadText: '正在验证手机号' }
      }),
      showSuc: false,
      sucText: '企业信息提交成功！'
    })
  },
  async updatePhone (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/member/developer/updatePhone',
        param: p,
        config: { showLoad: true, loadText: '正在更换手机号' }
      }),
      showSuc: true,
      sucText: '更换手机号更换成功！请前往登录'
    })
  },
  async isHasPassword (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/member/developer/isHasPassword',
        param: p,
        config: { showLoad: false, loadText: '正在更换手机号' }
      }),
      showSuc: false,
      sucText: '更换手机号更换成功！请前往登录'
    })
  },
  async verifyPassword (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/member/developer/verifyPassword',
        param: p,
        config: { showLoad: true, loadText: '正在验证密码' }
      }),
      showSuc: false,
      sucText: '密码验证成功'
    })
  },
  async updatePassword (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/member/developer/updatePassword',
        param: p,
        config: { showLoad: true, loadText: '正在设置登录密码' }
      }),
      showSuc: true,
      sucText: '登录密码设置成功！请前往登录'
    })
  }
}
