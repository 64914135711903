<template>
  <div style="width: 100%;margin-top: 15px">
    <ez-state-view :state="state" :no-data-reload="false" style="">
      <div v-if="state.isSuc">
        <div class="rttm-card">
          <div style="display: flex">
            <div>
              <el-button size="mini"
                         @click="$router.go(-1)"
                         style="background-color: #ebedf1;border: 0px;color: #444752;border-radius: 1px">返回</el-button>
            </div>
            <div style="flex: 1;font-size: 15px;margin-top: 3px;margin-left: 20px;text-align: center;" class="rttm-card-title">
              项目详情
            </div>
            <div style="margin-top: -1px">
              <el-button @click="editDialog = true"
                         type="primary" size="mini"
                         style="background-color: transparent;border: 0px;
                border: 1px rgb(101,160,255) solid;color: #0052d9">编辑</el-button>
            </div>
          </div>
          <div style="flex: 1;font-size: 15px;margin-top: 3px;margin-top: 20px;color: #0052D9"
               class="rttm-card-title">
            项目资料
          </div>
          <div style="font-size: 14px;color: #666666;font-weight: 500;margin-top: 20px;">应用名称</div>
          <div style="margin-top: 5px;font-size: 16px;font-weight: 900;">{{projectInfo.name}}</div>
          <div style="font-size: 14px;color: #666666;font-weight: 500;margin-top: 20px;">应用类型</div>
          <div style="margin-top: 5px;font-size: 16px;font-weight: 900;">
           {{projectInfo.type == 1 ?  '运动健康类应用':'第三方应用'}}
          </div>
          <div style="font-size: 14px;color: #666666;font-weight: 500;margin-top: 20px;">项目状态</div>
          <div style="margin-top: 5px;font-size: 13px;font-weight: 500;color:#42b983" v-if="projectInfo.isEnable">启用中</div>
          <div style="margin-top: 5px;font-size: 13px;font-weight: 500;color:#f52052" v-else>已被禁用</div>
          <el-tag  v-if="!projectInfo.isEnable" type="danger" size="small" style="width: 100%;padding-top: 6px;padding-bottom: 10px;height: 34px;margin-top: 10px;padding-left: 20px">
            后台管理人员,已将您的该项目设置为了禁用,如有问题请联系管理人员
          </el-tag>
          <div style="height: 1px;margin-top: 15px;background-color: #eeeeee;margin-bottom: 10px"></div>
          <div style="background-color: #fff;padding: 0px">
            <div style="font-size: 14px;color: #666666;font-weight: 500;margin-top: 0px">Access ID</div>
            <div style="margin-top: 5px;font-size: 14px;font-weight: 500">{{projectInfo.accessId}}</div>
            <div style="font-size: 14px;color: #666666;font-weight: 500;margin-top: 15px">Access Key</div>
            <div style="margin-top: 5px;font-size: 14px;font-weight: 500">{{projectInfo.accessKey}}</div>
            <el-tag size="small" type="info" style="width: 100%;margin-top: 10px;height: 29px;border-radius: 0px;line-height: 28px">
              Access ID 和 Access Key,信息为您在调用接口服务时使用的头部信息，敏感信息，请注意保密，不要泄露
            </el-tag>
          </div>
        </div>
        <div class="rttm-card" style="margin-top: 5px">
          <div style="flex: 1;font-size: 15px;margin-top: -3px;color: #0052D9" class="rttm-card-title">
            SDK License 相关信息
          </div>
          <el-tag size="small" type="warning" style="width: 100%;margin-top: 10px;height: 29px;border-radius: 0px;line-height: 28px">
            SDK License 为您在使用相关平台SDK 时须传入的License,信息为敏感信息，请注意保密，不要泄露
          </el-tag>
          <div style="margin-top: 15px;" >
            <div style="font-size: 14px;color: #666;font-weight: 500;width: 120px">Android 包名</div>
            <div v-if="projectInfo.androidPackageName" style="margin-top: 5px;font-size: 16px;font-weight: 500;">{{projectInfo.androidPackageName}}</div>
            <div v-else style="display: flex;margin-top: 10px;">
              <div style="color: #6d7580;font-size: 13px;background-color: #f5f6f8;padding: 2px;padding-left: 5px;padding-right: 5px">
                暂未完善Android 包名
              </div>
              <span @click="editDialog = true" style="cursor: pointer;color: #0052d9;margin-left: 10px;margin-top: 2px">前往完善</span>
            </div>
            <div style="font-size: 14px;color: #666;font-weight: 500;margin-top: 15px;width: 140px">Android SDK License</div>
            <div v-if="projectInfo.androidPackageName">
              <div v-if="showAndroidLicense" style="margin-top: 5px;font-size: 14px;font-weight: 500;padding: 15px;background-color: #F3F6FB;
            margin-top: 10px;border: 1px #E1E9FB solid;border-radius: 0px;font-size: 13px;">
                {{projectInfo.androidSdkLicense}}
              </div>
            </div>
            <div v-else style="display: flex;">
              <div style="color: #6d7580;margin-top: 10px;font-size: 13px;background-color: #f5f6f8;padding: 2px;padding-left: 5px;padding-right: 5px">
                请先完善Android 的包名信息
              </div>
            </div>
            <div v-if="projectInfo.androidPackageName" @click="showAndroidLicense = !showAndroidLicense" style="color: #0052d9;margin-top: 10px">
              {{showAndroidLicense ? '收起': '展示License'}}
            </div>
            <div style="height: 1px;margin-top: 20px;background-color: #eeeeee"></div>
            <div style="font-size: 13px;color: #666;font-weight: 500;margin-top: 15px">IOS Bundle ID</div>
            <div v-if="projectInfo.iosBundleIdentifier" style="margin-top: 5px;font-size: 16px;font-weight: 500;">{{projectInfo.iosBundleIdentifier}}</div>
            <div v-else style="margin-top: 5px;font-size: 13px;display: flex;margin-top: 10px">
              <div style="color: #6d7580;font-size: 13px;background-color: #f5f6f8;padding: 2px;padding-left: 5px;padding-right: 5px">
                暂未完善Bundle ID
              </div>
              <span @click="editDialog = true" style="cursor: pointer;color: #0052d9;margin-left: 10px;margin-top: 2px">前往完善</span>
            </div>
            <div style="font-size: 13px;color: #666;font-weight: 500;margin-top: 15px">IOS SDK License</div>
            <div v-if="projectInfo.iosBundleIdentifier">
              <div v-if="showIosLicense" style="margin-top: 5px;font-size: 14px;font-weight: 500;padding: 15px;background-color: #F3F6FB;
            margin-top: 10px;border: 1px #E1E9FB solid;border-radius: 0px;font-size: 13px;">
                {{projectInfo.iosSdkLicense}}
              </div>
            </div>
            <div v-else style="display: flex">
              <div style="color: #6d7580;margin-top: 10px;font-size: 13px;background-color: #f5f6f8;padding: 2px;padding-left: 5px;padding-right: 5px">
                请先完善IOS 的Bundle ID信息
              </div>
            </div>
            <div v-if="projectInfo.iosBundleIdentifier" @click="showIosLicense = !showIosLicense" style="cursor: pointer;color: #0052d9;margin-top: 10px">
              {{showIosLicense ? '收起': '展示License'}}
            </div>
          </div>
        </div>
        <div class="rttm-card" style="margin-top: 5px">
          <div style="flex: 1;font-size: 15px;margin-top: 3px;margin-top: 20px;color: #0052D9" class="rttm-card-title">
            项目基本信息
          </div>
          <div style="font-size: 13px;color: #b6bac1;font-weight: 500;margin-top: 15px">应用简介</div>
          <div style="padding: 15px;background-color: #F3F6FB;margin-top: 10px;border: 1px #E1E9FB solid;border-radius: 3px;font-size: 13px;color: #767676">
            {{projectInfo.description}}
          </div>
          <div style="font-size: 13px;color: #b6bac1;font-weight: 500;margin-top: 15px">创建时间</div>
          <div style="margin-top: 5px;font-size: 14px;font-weight: 500">{{$ez.util.time.milliToYMDHMS(projectInfo.ctime)}}</div>
        </div>
      </div>
    </ez-state-view>
    <el-dialog
      :destroy-on-close="true"
      :visible.sync="editDialog"
      top="60px"
      width="600px">
      <div slot="title" class="rttm-card-title" style="margin-top: 3px;margin-left: 8px">
        修改开放项目
      </div>
<!--      <openapi-project-edit-->
      <openapi-project-edit v-if="state.isSuc" :is-add="false" :project="projectInfo" @saveOver="getProjectDetail"></openapi-project-edit>
    </el-dialog>
  </div>
</template>

<script>
import OpenapiApi from '../../../../common/api/OpenApi'
import OpenapiProjectEdit from '../edit/OpenapiProjectEdit'

export default {
  name: 'OpenapiProjectInfo',
  components: { OpenapiProjectEdit },
  data () {
    return {
      showAndroidLicense: false,
      showIosLicense: false,
      showLicense: false,
      editDialog: false,
      projectInfo: null,
      projectId: null,
      state: this.$ez.model.viewState(0)
    }
  },
  methods: {
    async getProjectDetail () {
      this.editDialog = false
      this.state.toLoading()
      const _r = await OpenapiApi.getDetail({ id: this.projectId })
      if (_r.r) {
        this.projectInfo = _r.data
        this.state.toSuc(500)
      } else {
        this.state.toFail(500)
      }
    }
  },
  created () {
    this.projectId = this.$route.query.id
    this.getProjectDetail()
  }
}
</script>

<style scoped>

</style>
