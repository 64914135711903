export default class {
  constructor (state) {
    this.isLoading = false
    this.isSuc = false
    this.isFail = false
    this.isNoData = false

    this.viewState = 0
    if (state && state > 0) {
      this.viewState = state
    }
    this._onViewStateChagne(state || 0)
  }

  // 0 加载，1 加载成功，2 加载失败，3 没有数据
  _onViewStateChagne (type, t) {
    const self = this
    setTimeout(function () {
      self.viewState = type
      self.isLoading = self._isLoading()
      self.isSuc = self._isSuc()
      self.isFail = self._isFail()
      self.isNoData = self._isNoData()
    }, t || 0)
  }

  _isLoading () {
    return this.viewState === 0
  }

  _isSuc () {
    return this.viewState === 1
  }

  _isFail () {
    return this.viewState === 2
  }

  _isNoData () {
    return this.viewState === 3
  }

  toLoading (t) {
    this._onViewStateChagne(0, t || 0)
  }

  toSuc (t) {
    this._onViewStateChagne(1, t || 0)
  }

  toFail (t) {
    this._onViewStateChagne(2, t || 0)
  }

  toNoData (t) {
    this._onViewStateChagne(3, t || 0)
  }
}
