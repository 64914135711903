<template>
  <div style="display: flex;width: 100%;height: 100%">
    <div style="flex: 1"></div>
    <div style="width: 1100px;display: flex">
      <div style="width: 780px;overflow-y: auto">
        <router-view></router-view>
        <div class="rttm-card" style="margin-top: 15px">
          <div class="rttm-card-title" style="font-size: 15px">SDK_Demo和资源</div>
          <img src="../../../assets/img/test_erweima.jpg" height="80"/>
        </div>
      </div>
      <div style="width: 300px;margin-left: 15px;margin-top: 15px">
        <div class="rttm-card">
          <div class="rttm-card-title" style="font-size: 15px">公告</div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 60px;font-size: 13px;text-align: center;color: #8491a8">
              <div style="">8月</div>
              <div style="">10号</div>
            </div>
            <div style="font-size: 13px;margin-left: 15px">
              【上云回馈】接口应用上线啦 ，
              旗舰版套餐包 7.5 折起 ，立即选购
            </div>
          </div>
        </div>
        <div class="rttm-card" style="margin-top: 15px">
          <div class="rttm-card-title" style="font-size: 15px">指引文档</div>
          <div @click="openApi" style="display: flex;margin-top: 10px;cursor: pointer">
            <div style="font-size: 13px;text-align: center;color: #8491a8">
              <img src="../../../assets/icon/app/app_api.png" height="46" width="46"/>
            </div>
            <div style="font-size: 13px;margin-left: 15px;margin-top: 2px">
              <div >Api文档</div>
              应用接口API文档，前往查看>>
            </div>
          </div>
          <div  @click="goSdk" style="display: flex;margin-top: 10px;cursor: pointer">
            <div style="font-size: 13px;text-align: center;color: #8491a8">
              <img src="../../../assets/icon/app/app_daima.png" height="46" width="46"/>
            </div>
            <div style="font-size: 13px;margin-left: 15px;margin-top: 2px">
              <div>SDK文档</div>
                DK使用文档，前往查看 >>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="flex: 1">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApiappProject',
  methods: {
    openApi () {
      window.open('http://rtt.vsites.cn/developer/api.html')
    },
    goSdk () {
      // this.$router.push({ path: '/sdkList' })
      this.$ez.util.url.openInternal('/sdkList')
    }
  }
}
</script>

<style scoped>

</style>
