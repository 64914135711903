<template>
  <div style="">
    <div style="height: 1px;background-color: #eef0f3;"></div>
    <div class="rttm-card" style="padding: 40px;padding-top: 20px;padding-bottom:12px;max-height: calc(100vh - 250px);overflow-y: auto;">
      <el-form label-position="top" label-width="80px" ref="form"  :rules="rules" :model="formData">
        <div style="display: flex">
          <div style="flex: 4">
            <div style="flex: 1">
              <div style="width: 100%;text-align: right;color: #bcbec4;margin-bottom: -25px;font-size: 13px">
                输入格式为xxx.xxx.xxx
              </div>
              <el-form-item label="AppId" prop="appId">
                <el-input size="small" :disabled="isHasVersion" :maxlength="250" v-model="formData.appId" placeholder="请输入appId"></el-input>
              </el-form-item>
            </div>
            <div style="flex: 1">
              <div style="width: 100%;text-align: right;color: #bcbec4;margin-bottom: -25px;font-size: 13px">
                已上线应用不能修改应用名称
              </div>
              <el-form-item label="应用名称" prop="name" :rules="[{ required: true, message: '应用名称不能为空'}]">
                <el-input size="small" :maxlength="250" @keydown.native="keydown($event)" v-model="formData.name" :disabled="isHasVersion" placeholder="请输入应用名称"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="应用分类" prop="category" :rules="[{ required: true, message: '应用分类为必须选'}]">
              <el-select v-model="softwareCategory" placeholder="请选择" style="width: 100%" size="small">
                <el-option
                  v-for="item in categoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <div style="flex: 1">
              <div style="width: 100%;text-align: right;color: #bcbec4;margin-bottom: -25px;font-size: 13px">
                请填写应用提供者名称
              </div>
              <el-form-item label="应用提供方" prop="provider" :rules="[{ required: true, message: '应用提供方不能为空'}]">
                <el-input size="small" :maxlength="250" @input="()=> {formData.provider = formData.provider.replaceAll(' ', '')}"  v-model="formData.provider" placeholder="请输入应用提供方"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="应用描述"  prop="description" :rules="[{ required: true, message: '请选择应用描述'}]">
              <el-input size="small" :maxlength="250" v-model="formData.description" type="textarea"   :rows="4" placeholder="请选择应用描述"></el-input>
            </el-form-item>
            <div style="flex: 1">
              <div style="width: 100%;text-align: right;color: #bcbec4;margin-bottom: -25px;font-size: 13px">
                最多定义5组关键词,逗号分割
              </div>
              <el-form-item label="检索关键词" prop="searchKeywords" >
                <el-input size="small" :maxlength="100"
                          @input="searchWordsChange"
                          v-model="formData.searchKeywords"
                          placeholder="请输入检索关键词"></el-input>
              </el-form-item>
            </div>
          </div>
          <div style="flex: 3;margin-left: 50px">
            <div style="flex: 1">
              <div style="width: 100%;text-align: right;color: #bcbec4;margin-bottom: -25px;font-size: 13px">
                在上传版本时自动进行更新
              </div>
              <el-form-item label="应用logo" prop="logo" :rules="[{ required: false, message: 'logo必须上穿'}]">
                <img :src="formData.logo" style="height: 80px;width: 80px;;border-radius: 10px;border: 1px #eaebee solid">
                <div style="margin-top: -2px;display:flex;">
                  <EzFileSel @afterUpload="afterUploadLogo" style="height: 32px">
                    <el-button type="primary" size="small" style="background-color: #0052D9;border: 0px;height: 32px;width: 80px;text-align: center;padding: 0px" >切换logo</el-button>
                  </EzFileSel>
                </div>
              </el-form-item>
              <div style="height: 0px"></div>
              <div style="width: 100%;text-align: right;color: #bcbec4;margin-bottom: -27px;font-size: 13px;margin-top:40px">
                版权证明的反正面照片
              </div>
              <el-form-item label="版权证明" prop="copyrightFiles" :rules="[{ required: false, message: '请上传应用截图'}]" style="margin-top: -5px">
                <div style="display: flex;margin-top: 5px">
                  <div v-for="(item,key) in copyrightFileList" :key="key" style="height: 80px;width: 80px;position: relative;margin-right: 25px">
                    <el-image fit="cover"
                      style="height: 100%;width: 100%;object-fit: cover;border-radius: 2px"
                      :src="item"
                      :preview-src-list="copyrightFileList">
                    </el-image>
                    <div @click="delCopyrightImg(item)" style="height: 24px;width: 24px;background-color: rgba(0,0,0,0.6);
                color: white;font-size: 16px;line-height: 26px;text-align: center;
                position: absolute;top: -10px;right: -10px;border-radius: 20px">
                      <i class="el-icon-close" style="margin-left: 2px"></i>
                    </div>
                  </div>
                  <EzFileSel v-if="copyrightFileList.length < 2" @afterUpload="afterCopyrightImg" style="">
                    <img src="../../../../../assets/img/sel_file.png" height="80" width="80" />
                  </EzFileSel>
                </div>
              </el-form-item>
            </div>
          </div>
        </div>
        <div></div>
      </el-form>
    </div>
    <div style="height: 1px;background-color: #eef0f3;"></div>
    <div style="text-align: center;margin-top: 20px">
      <el-button type="primary" style="background-color: #0052D9;border: 0px;height: 38px" @click="saveData()">提交应用</el-button>
    </div>
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import SoftwareApi from '../../../../../common/api/SoftwareApi'

export default {
  name: 'SoftwareEdit',
  props: ['isAdd', 'type', 'software'],
  data () {
    var keywordsCheck = (rule, value, callback) => {
      this.formData.searchKeywords = this.formData.searchKeywords.replaceAll('，', ',')

      if (this.formData.searchKeywords.substring(this.formData.searchKeywords.length - 1, this.formData.searchKeywords.length) === ',') {
        this.formData.searchKeywords = this.formData.searchKeywords.substring(0, this.formData.searchKeywords.length - 1)
      }

      const strList = this.formData.searchKeywords.split(',')
      if (strList.length > 5) {
        return callback(new Error('关键词最多定义五组'))
      }
      strList.sort()
      for (var i = 0; i < strList.length; i++) {
        if (this.$ez.util.str.isEmpty(strList[i])) {
          return callback(new Error('检索关键词不可以为空字符串，请注意您的逗号填写'))
        }
        if (strList[i] === strList[i + 1]) {
          return callback(new Error('检索关键词不可以重复'))
        }
      }
      callback()
    }
    var appIdCheck = (rule, value, callback) => {
      const reg = /^[A-Za-z_-]+\.[A-Za-z0-9._-]+\.[A-Za-z0-9_-]+$/
      if (!reg.test(value)) {
        return callback(new Error('包名至少为三级，且只能包含以下字符 A-Z a-z 0-9 . _ -，并以 A-Z a-z 0-9 结尾'))
      }
      callback()
    }
    var descriptionCheck = (rule, value, callback) => {
      const x = this.formData.description.replaceAll(' ', '')
      if (x.length <= 0) {
        return callback(new Error('应用描述不可以为纯空格'))
      }
      callback()
    }
    return {
      formData: {
        id: null,
        logo: 'https://rttoss.oss-cn-shanghai.aliyuncs.com/img/def_application_logo.png',
        appId: null,
        name: null,
        provider: null,
        searchKeywords: null,
        type: this.type,
        typeName: this.type === 1 ? '应用' : '表盘',
        category: null,
        description: null,
        categoryName: null,
        copyrightFiles: null
      },
      rules: {
        appId: [
          { required: true, message: 'AppId不能为空' },
          { validator: appIdCheck, trigger: 'blur' }
        ],
        searchKeywords: [
          { required: true, message: '检索关键词不能为空' },
          { validator: keywordsCheck, trigger: 'blur' }
        ],
        description: [
          { required: true, message: '检索关键词不能为空' },
          { validator: descriptionCheck, trigger: 'blur' }
        ]
      },
      copyrightFileList: [],
      categoryList: [],
      isHasVersion: false
    }
  },
  methods: {
    searchWordsChange () {
      this.formData.searchKeywords = this.formData.searchKeywords.replaceAll('，', ',')
      this.formData.searchKeywords = this.formData.searchKeywords.replaceAll(' ', '')
      const list = this.formData.searchKeywords.split(',')
      let _str = ''
      for (const v of list) {
        _str += ','
        if (v.length > 10) {
          _str += v.substring(0, 10)
        } else {
          _str += v
        }
      }
      this.formData.searchKeywords = _str.substring(1, _str.length)
    },
    // 禁止输入空格
    keydown (e) {
      if (e.keyCode === 32) {
        e.returnValue = false
      }
    },
    packageCheck (val) {
      // console.log(val);
      const codeReg = new RegExp('[A-Za-z0-9._-]+') // 正则 英文+数字；
      const len = val.length
      let str = ''
      for (var i = 0; i < len; i++) {
        if (codeReg.test(val[i])) {
          str += val[i]
        }
      }
      this.formData.appId = str
    },
    delCopyrightImg (d) {
      const i = this.copyrightFileList.indexOf(d)
      this.copyrightFileList.splice(i, 1)
      this.formData.copyrightFiles = JSON.stringify(this.copyrightFileList)
      if (this.copyrightFileList.length <= 0) {
        this.formData.copyrightFiles = null
      }
    },
    afterCopyrightImg (d) {
      this.copyrightFileList.push(d[0].url)
      this.formData.copyrightFiles = JSON.stringify(this.copyrightFileList)
      if (this.copyrightFileList.length <= 0) {
        this.formData.copyrightFiles = null
      }
    },
    submitData (callBack) {
      let value = false
      this.$refs.form.validate((valid) => {
        if (valid) {
          value = this.formData
        } else {
          value = false
          this.$message.error('信息填写不完整')
          return false
        }
      })
      if (callBack != null) {
        callBack(value)
      }
      return value
    },
    async saveData () {
      const _value = this.submitData()
      if (!_value) {
        return false
      }
      let _r = null
      // todo 处理版权证明
      if (this.$ez.util.str.isEmpty(_value.copyrightFiles)) {
        _value.copyrightFiles = '[]'
      }
      try {
        JSON.parse(_value.copyrightFiles)
      } catch (e) {
        _value.copyrightFiles = '[]'
      }

      if (this.isAdd) {
        _r = await SoftwareApi.createSoftware(_value)
      } else {
        _r = await SoftwareApi.editSoftware(_value)
      }
      if (_r.r) {
        this.$emit('saveOver', _r.data.id)
        this.$bus.$emit('hideSoftWareDialog')
      }
    },
    afterUploadLogo (d) {
      this.formData.logo = d[0].url
    },
    async getCategoryList () {
      const _r = await SoftwareApi.getCategoryList({ type: this.type })
      if (_r.r) {
        this.categoryList = _r.data
      }
    }
  },
  computed: {
    softwareCategory: {
      get () {
        return this.formData.categoryName
      },
      set (val) {
        this.formData.category = val
        const index = this.categoryList.findIndex(e => e.id === val)
        this.formData.categoryName = this.categoryList[index].name
      }
    }
  },
  created () {
    if (!this.isAdd) {
      this.formData = {
        id: this.software.id,
        logo: this.software.logo,
        appId: this.software.appId,
        name: this.software.name,
        provider: this.software.provider,
        searchKeywords: this.software.searchKeywords,
        type: this.software.type,
        typeName: this.software.typeName,
        category: this.software.category,
        description: this.software.description,
        categoryName: this.software.categoryName,
        copyrightFiles: this.software.copyrightFiles
      }
      this.copyrightFileList = JSON.parse(this.formData.copyrightFiles)
      this.isHasVersion = this.software.isHasVersion
    }
    this.getCategoryList()
  }
}
</script>

<style scoped>

</style>
