<template>
  <div class="list-container">
    <template v-for="(item,key) in dataList.filter(data => ! keyword || data.name.toLowerCase().includes(keyword.toLowerCase()))">
      <div @click="showProjectDetail(item.id, key)" :class="['left-app-item', currentId == item.id ? 'left-app-item-act left-list-item-container':'left-app-item left-list-item-container']"
            :key="key">
        <div class="left-app-item-logo">
          <img :src="item.logo"
                style="height: 100%;width: 100%;border-radius: 10px;border: 1px #d9dde7 solid"/>
        </div>
        <div class="left-app-item-info">
          <div class="left-app-item-info-text">{{item.name}}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'LeftList',
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    keyword: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      currentId: '',
      currentIndex: 0
    }
  },
  methods: {
    showProjectDetail (id, index) {
      this.currentId = id
      this.currentIndex = index
      this.$emit('showDetail', index)
    },
    getIndexById (id) {
      return this.dataList.findIndex((item) => item.id === id)
    }
  },
  watch: {
    dataList (newVal, oldVal) {
      console.log('ddddddd')
      console.log(newVal.length, oldVal.length)
      if (newVal.length !== oldVal.length && newVal.length) {
        this.currentId = newVal[0].id
        this.currentIndex = 0
      }
      this.$emit('showDetail', this.currentIndex)
    }
  },
  mounted () {
    this.currentId = this.dataList.length ? this.dataList[0].id : ''
    if (this.$route.params?.id) {
      const index = this.getIndexById(this.$route.params?.id)
      this.currentId = this.dataList[index >= 0 ? index : 0].id
    }
  }
}
</script>

<style scoped lang="less" >
.left-app-item:hover{
  background-color: #f7fbff;
  border-left: 4px #0052D9 solid;
}
.left-app-item{
  cursor:pointer;
  width: 100%;
  padding-right: 20px;
  display: flex;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-left: 4px transparent solid;
  box-sizing: border-box;
  .left-app-item-logo{
    height: 44px;
    width: 44px;
  }
  .left-app-item-info{
    margin-left: 15px;
    display: flex;
    align-items: center;
    .left-app-item-info-text{
      font-size: 15px;
      font-weight: bold;
      margin-top: 1px;
    }
    .left-app-item-info-version{
      font-size: 12px;
      font-weight: 400;
      margin-top: 4px;
      color: #8c939d;
    }
  }
}
.list-container{
  width: 100%;
}
.left-list-item-container{
  width: 100%;
}

.left-app-item-act{
  background-color: #f7fbff;
  border-left: 4px #0052D9 solid;
}
</style>
