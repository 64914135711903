<template>
    <div style="height: 60px;display: flex;border-bottom: 1px #ececec solid">
      <div style="flex: 1"></div>
      <div style="width: 1300px;display: flex;line-height: 60px">
        <div style="width: 220px;text-align: left">
          <img src="../../../assets/app_logo.png" height="30" style="margin-top: 15px"/>
        </div>
        <div style="height: 40px;margin-top: 10px;width: 1px;background-color: #ececec"></div>
        <div style="flex: 1;display: flex;color: #8c939d;line-height: 60px">
          <div class="nav-item" @click="$ez.util.url.openInternal('/sdkList')">SDK</div>
          <div class="nav-item" @click="goUrl('http://rtt.vsites.cn/developer/api.html')">文档</div>
          <div class="nav-item" @click="goUrl('https://club.rt-thread.org/index.html')">开发者社区</div>
        </div>
        <div style="font-size: 15px;line-height: 60px">
          <div class="nav-item"  @click="loginDialogVisible = true">控制台</div>
        </div>
        <div></div>
        <div>
          <el-button style="margin-left: 40px" round @click="loginDialogVisible = true">登录</el-button>
          <el-button style="margin-left: 20px" type="primary" round @click="registerDialogVisible = true">立即注册</el-button>
        </div>
      </div>
      <div style="flex: 1"></div>
     <EzView>
        <div class="ez-dialog">
          <el-dialog
            :visible.sync="loginDialogVisible"
            width="400px">
            <div slot="title" style="margin-left: 20px;font-size: 18px;font-weight: 500;color: #8c939d;margin-top: 5px">
              RTT智能穿戴
            </div>
            <LoginView :isRegister="false"  @showRegisterDialog="showRegisterDialog"></LoginView>
          </el-dialog>
        </div>
       <div class="ez-dialog">
         <el-dialog
           :visible.sync="registerDialogVisible"
           width="400px">
           <div slot="title" style="margin-left: 20px;font-size: 18px;font-weight: 500;color: #8c939d;margin-top: 5px">
             RTT智能穿戴
           </div>
           <LoginView :isRegister="true"  @showLoginDialog="showLoginDialog"></LoginView>
         </el-dialog>
       </div>
     </EzView>
    </div>
</template>

<script>
import LoginView from '../login/LoginView'

export default {
  name: 'TopNavView',
  components: { LoginView },
  data () {
    return {
      loginDialogVisible: false,
      registerDialogVisible: false
    }
  },
  methods: {
    jumpPath (path) {
      this.cRouteNodePath = path
      this.$router.push({ path: path })
    },
    showLoginDialog () {
      this.registerDialogVisible = false
      const self = this
      setTimeout(function () {
        self.loginDialogVisible = true
      }, 300)
    },
    goUrl (url) {
      window.open(url)
    },
    showRegisterDialog () {
      this.loginDialogVisible = false
      const self = this
      setTimeout(function () {
        self.registerDialogVisible = true
      }, 300)
    }
  },
  created () {
    const toLogin = this.$route.query.isLogin
    if (toLogin) {
      this.showLoginDialog()
    }
  }
}
</script>

<style scoped lang="less">
  .nav-item{
    font-size: 14px;
    color: #606468;
    font-weight: 500;
    margin-left: 30px;
    margin-right: 20px;
  }
  .nav-item:hover{
    color: #1072e2;
  }
</style>
