import { EzApiResult, EzRequest, ParamType } from '../../request/EzRequest'
import AppConfig from '../../../config/AppConfig'
import store from '../../../store'
import router from '../../../router'
export async function AppRequest ({
  url,
  param = {}, // 请求参数
  method = 'POST', // 请求类型
  config = {}, // 请求配置
  headers = {}, // 请求头部配置
  paramType = ParamType.TYPE_OBJ // 请求参数类型
}) {
  const x = await EzRequest({
    url: url,
    baseUrl: AppConfig.baseUrl,
    param: param, // 请求参数
    method: method, // 请求类型
    config: config, // 请求配置
    headers: headers, // 请求头部配置
    paramType: paramType, // 请求参数类型
    needToken: true, // 请求是否需要携带token
    tokenFun: (headers) => {
      // headers.accessToken = store.state.login.token
      headers['access-token'] = store.state.login.token
    } // 请自行获取放在这
  })
  return x
}
// accessToken
export async function AppApiResult ({
  res,
  sucCode = 0,
  filterCodes = [],
  showErr = true,
  errText,
  showSuc = false,
  sucText,
  toastTime = 2000
}) {
  const _r = EzApiResult({
    res: res,
    // eslint-disable-next-line no-undef
    filterCodes: filterCodes,
    sucCode: sucCode,
    showErr: showErr,
    errText: errText,
    showSuc: showSuc,
    sucText: sucText,
    toastTime: toastTime
  })
  if (_r.code === 100001 || _r.code === 100021) {
    store.commit('login/LOGIN_OUT')
    router.push({ path: '/', query: { isLogin: true } })
  }
  return _r
}
