import Vue from 'vue'
import Vuex from 'vuex'
import My from './my/My'
import Login from './login/Login'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    my: My,
    login: Login
  },
  getters: {
    isLogin: state => {
      return state.login.isLogin
    },
    token: state => {
      return state.login.token
    }
  }
})
