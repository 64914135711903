<template>
    <div>
      <el-table
        :data="versionList"
        style="width: 100%">
        <el-table-column fixed prop="projectType" label="支持的手表型号">
          <template #default="scope">
            {{ scope.row.projectName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="version"
          label="版本号">
          <template slot-scope="scope">
            v{{scope.row.version}}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="大小">
          <template slot-scope="scope">
            {{$ez.util.number.getSizeMb(scope.row.packageSize)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="city"
          label="提交时间"
          width="180">
          <template slot-scope="scope">
            {{$ez.util.time.milliToYMDHM(scope.row.ctime)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="zip"
          width="100"
          label="审核状态">
          <template slot-scope="scope">
            <div>
              <el-tag v-if="scope.row.verifyStatus == 1" type="success" size="small">审核已通过</el-tag>
              <el-tag v-else-if="scope.row.verifyStatus == 2" type="warning"  size="small">审核未通过</el-tag>
              <el-tag v-else type="info"  size="small">等待审核</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="审核时间"
          width="180">
          <template slot-scope="scope">
            {{$ez.util.time.milliToYMDHM(scope.row.utime)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="驳回原因"
          width="180">
          <template slot-scope="scope">
            {{ scope.row.verifyReason ? scope.row.verifyReason : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="是否上架"
          width="180">
          <template slot-scope="scope">
            {{ scope.row.isDisable ? '已禁用' : scope.row.isPublish ? '已上架' : "未上架" }}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="190">
          <template slot-scope="scope">
            <el-button :disabled="!!scope.row.isDisable" @click="updateStatus(scope.row.id, scope.row.isPublish)" :type="scope.row.isPublish ? 'danger' : 'primary'" size="mini">{{ scope.row.isPublish ? '下架' : '上架' }}</el-button>
            <el-button @click="showInfo(scope.row)" type="text" size="small">查看</el-button>
            <a href="javascript:void(0)" @click="getDownloadUrl(scope.row.id)" download="" style="color: #0052d9;font-size: 12px;margin-left: 10px">下载安装包</a>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-dialog
          :destroy-on-close="true"
          :visible.sync="infoDialog"
          top="60px"
          width="800px">
          <div slot="title" class="rttm-card-title" style="margin-top: 3px;margin-left: 8px">
            {{type == 1 ? '穿戴':'表盘'}}版本信息
          </div>
          <SoftwareVersionInfo  v-if="infoDialog" :softwareInfo="versionInfo"></SoftwareVersionInfo>
        </el-dialog>
      </div>
    </div>
</template>

<script>
import SoftwareApi from '@/common/api/SoftwareApi'
import SoftwareVersionInfo from '../info/SoftwareVersionInfo'
export default {
  name: 'SoftwareVersionTable',
  components: { SoftwareVersionInfo },
  props: ['versionList', 'type'],
  data () {
    return {
      infoDialog: false,
      versionInfo: null
    }
  },
  methods: {
    showInfo (data) {
      this.versionInfo = data
      const self = this
      this.$nextTick(function () {
        self.infoDialog = true
      })
    },
    getDownloadUrl (id) {
      SoftwareApi.getDownloadUrl({ id }).then(res => {
        if (res.data) {
          window.open(res.data, '_blank')
        } else {
          this.$message.error('未发现对应版本软件包')
        }
      })
    },
    updateStatus (versionId, isPublish) {
      if (isPublish) {
        this.$messageBox.confirm('应用下架后，用户将无法下载使用', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          this.updateStatusAjax(versionId, isPublish)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消下架'
          })
        })
      } else {
        this.updateStatusAjax(versionId, isPublish)
      }
    },
    updateStatusAjax (versionId, isPublish) {
      SoftwareApi.updateVersionStatus({
        versionId: versionId,
        isPublish: !isPublish
      }).then(res => {
        this.$message({
          type: 'success',
          message: `${isPublish ? '下架' : '上架'}成功`
        })
        this.$emit('updateVersionHistory')
      }).catch(err => {
        console.error(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
