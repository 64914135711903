<template>
  <div style="width: 100%;height: 100%;overflow-y: auto;background: #F8F9FB">
    <div style="height: 300px;width: 100%;top: 0px;left: 0px;">
      <img src="../../../assets/top_bg.png" style="height: 100%;width: 100%"/>
    </div>
    <div style="margin-top: -275px">
      <div style="font-size: 34px;font-weight: 700;color: white;text-align: center">编辑账号基础信息</div>
      <div style="font-size: 16px;text-align: center;color: rgba(255,255,255,0.89);margin-top: 10px">编辑您的信息</div>
    </div>
    <div style="display: flex">
      <div style="flex: 1"></div>
      <div class="rttm-card" v-if="active == 2" style="background-color: white;width: 800px;margin-top: 25px;margin-bottom: 30px">
        <div style="padding: 30px;padding-top: 10px;padding-bottom: 0px">
          <div style="font-size: 18px;font-weight: 700;padding-left: 13px;
          border-left: 4px #0052d9 solid;height: 22px;margin-bottom: 20px;line-height: 25px">账号基础信息</div>
          <DeveloperInfoEdit :account="accountInfo" ref="developerInfoEdit"></DeveloperInfoEdit>
        </div>
        <div style="text-align: center">
          <el-button type="primary" @click="toCertification" round style="background-color: #0052D9;padding-left: 50px;padding-right: 50px;border: 0px;color: white;margin-bottom: 10px">提交</el-button>
        </div>
      </div>
      <div class="rttm-card" v-if="active == 3" style="background-color: white;width: 800px;margin-top: 25px;margin-bottom: 30px">
        <div style="padding: 30px;padding-top: 0px;padding-bottom: 0px">
          <div style="text-align: center;font-size: 25px;font-weight: 500;margin-top: 30px">
            信息提交成功
          </div>
          <div style="text-align: center;margin-top: 40px">
            <img src="../../../assets/img/my/certification_over.png" height="180" />
          </div>
          <div style="text-align:center;color: #8c939d;text-align: center;font-size: 13px;margin-top: 50px">感谢您的填写，信息已经编辑成功</div>
          <div style="text-align:center;color: #8c939d;text-align: center;font-size: 13px">您的信息已成功编辑，接下来自动前往控制台</div>
        </div>
        <div style="text-align: center;margin-top: 30px;margin-bottom: 40px">
          <el-button type="primary" @click="toControl" round style="background-color: #0052D9;padding-left: 50px;padding-right: 50px;border: 0px;color: white;margin-bottom: 10px">{{countdown}} 前往控制台</el-button>
        </div>
      </div>
      <div style="flex: 1"></div>
    </div>
  </div>
</template>

<script>
import DeveloperInfoEdit from './info/DeveloperInfoEdit'
import AccountApi from '../../../common/api/AccountApi'

export default {
  name: 'MyInfoComplete',
  components: { DeveloperInfoEdit },
  data () {
    return {
      active: 2,
      nodeList: {},
      developerT: 0,
      countdown: 5,
      accountInfo: {}
    }
  },
  methods: {
    toControl () {
      this.$router.replace('/control')
      window.location.reload()
    },
    async toCertification () {
      let developerInfo = {}
      this.$refs.developerInfoEdit.submitData((v) => { developerInfo = v })
      if (!developerInfo) {
        return false
      }
      const _r = await AccountApi.userEdit(developerInfo)

      if (_r.r) {
        this.active = 3
        const self = this
        const countdownInterval = setInterval(function () {
          self.countdown = self.countdown - 1
          if (self.countdown <= 0) {
            self.toControl()
            window.clearInterval(countdownInterval)
          }
        }, 1000)
      }
    }
  },
  created () {
    this.accountInfo = JSON.parse(JSON.stringify(this.$store.state.my.info))
  }
}
</script>

<style scoped lang="less">
  .my-app-card:hover{
    transform: scale(1,1);
    box-shadow: 5px 0px 10px rgba(35, 34, 34, 0.07);
    border: 3px white solid;
  }
  .my-app-card {
    flex: 1;
    height: 240px;
    background-image: linear-gradient(#f4f6fc, #fff);
    border: 1px #e8ebef solid;
    border-radius: 3px;
    text-align: center;
    transform: scale(0.8,0.8);
  .my-app-card-icon{
    margin-top: 12px;

    /*background: #8c939d;*/
    display: inline-block;
  }
  .my-app-card-name{
    margin-top: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #0067db;
  }
  .my-app-card-value{
    margin-top: 10px;
    color: #4faa27;
    display: none;
    font-size: 30px;
    font-weight: bold;
  }
  .my-app-card-unit{
    margin-top: 5px;
    color: #8c939d;
    font-size: 12px;
  }
  }
  .my-app-card-act{
    transform: scale(1,1);
    box-shadow: 5px 0px 10px rgba(35, 34, 34, 0.07);
    border: 3px white solid;
    .my-app-card-value{
      display: block;
    }
  }
</style>
