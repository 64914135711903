<template>
    <div class="rttm-card" style="margin: 18px">
      <div class="rttm-card-title" style="font-size: 16px;margin-bottom: 15px">
        应用版本记录
      </div>
      <SoftwareVersionTable :version-list="versionList" @updateVersionHistory="getHistoryPage" :type="type"></SoftwareVersionTable>
      <div style="margin-left: -5px;margin-top: 20px">
        <el-pagination
          background
          @size-change="(s)=> {pageParam.pageSize = s;pageParam.pageNo = 1;getHistoryPage()}"
          @current-change="(c)=> {pageParam.pageNo = c;getHistoryPage()}"
          :page-size="pageParam.pageSize"
          :page-sizes="[5, 10, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageParam.total">
        </el-pagination>
      </div>
    </div>
</template>

<script>
import SoftwareVersionTable from './SoftwareVersionTable'
import SoftwareApi from '../../../../../common/api/SoftwareApi'
export default {
  name: 'SoftwareVersionTableView',
  props: ['softwareId', 'type'],
  components: { SoftwareVersionTable },
  data () {
    return {
      versionList: [],
      pageParam: {
        total: 0,
        pageSize: 10,
        pageNo: 1
      }
    }
  },
  methods: {
    async getHistoryPage () {
      const _r = await SoftwareApi.getHistoryPage({
        pageSize: this.pageParam.pageSize,
        pageNo: this.pageParam.pageNo,
        softwareId: this.softwareId
      })
      if (_r.r) {
        this.pageParam.total = _r.data.total
        this.versionList = _r.data.records
      }
    }
  },
  watch: {
    softwareId (oldVal, newVal) {
      if (oldVal !== newVal) {
        this.getHistoryPage()
      }
    }
  },
  created () {
    this.getHistoryPage()
  }
}
</script>

<style scoped>

</style>
