<template>
  <div style="width: 100%;height: 100%;display: flex;flex-direction: column;border-right: 1px #eff1f3 solid;background-color: white">
    <div style="padding: 20px">
      <div style="">
        <el-button type="primary" @click="addDialog = true" style="background-color: #0052D9;width: 100%;border: 0px;height: 42px" round>
          <slot name="buttonText"></slot>
        </el-button>
      </div>
    </div>
    <div style="margin-left: 20px;margin-right: 20px;height: 1px;background-color: #eceff1"></div>
    <div class="sel-app" style="padding: 20px;">
      <el-input
        placeholder="请输入应用关键词"
        v-model="keywords"
        :maxlength="20"
        @input="()=> keywords = keywords.replaceAll()"
        clearable>
      </el-input>
    </div>
    <div style="flex: 1;height: 0px;overflow-y: auto; overflow-x: hidden;">
      <EzStateView @reLoadFun="getMySoftwareList" :state="state" padding-t="20px">
        <el-scroll-bar style="height: 100%; width: 100%">
          <slot name="dataContent" :keyword="keywords"></slot>
        </el-scroll-bar>
      </EzStateView>
    </div>
    <el-dialog
      :destroy-on-close="true"
      :visible.sync="addDialog"
      top="60px"
      width="800px">
      <div v-if="addDialog">
        <slot name="dialog"></slot>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'SoftwareLeft',
  props: {
    softwareId: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: 1
    },
    state: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      addDialog: false,
      keywords: '',
      softwareList: []
    }
  },
  methods: {
    getMySoftwareList () {
      this.$emit('refresh')
    },
    hideDialog () {
      this.addDialog = false
    }
  },
  mounted () {
    this.$bus.$on('hideSoftWareDialog', this.hideDialog)
  },
  beforeDestroy () {
    this.$bus.$off('hideSoftWareDialog')
  }
}
</script>
<style lang='less' scoped>
.sel-app{
  /deep/.el-input__inner{
    border-radius: 30px;
    background-color: #f5f6f8;
    border: 0px;
  }
}
/deep/.el-scrollbar__wrap{
  overflow-x: hidden;
}
</style>
