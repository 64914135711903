<template>
  <div style="height: 60px;display: flex;border-bottom: 1px #ececec solid">
    <div style="flex: 1"></div>
    <div style="width: 100%;display: flex;line-height: 60px">
      <div style="width: 260px;text-align: left;text-align: center">
        <img src="../../../assets/app_logo.png" height="30" style="margin-top: 15px"/>
      </div>
      <div style="height: 40px;margin-top: 10px;width: 1px;background-color: #ececec"></div>
      <div style="flex: 1;display: flex;color: #8c939d;line-height: 60px">
        <div class="nav-item" @click="jumpPath(item.path)" :class="cRouteNodePath == item.path ? 'nav-item-act': ''" v-for="(item,key) in topRoutes" :key="key">{{item.name}}</div>
      </div>
      <div style="font-size: 15px;line-height: 60px;display: flex">
        <div class="nav-item" @click="jumpPath('/sdkList')" :class="cRouteNodePath == '/sdkList' ? 'nav-item-act': ''">SDK</div>
        <div class="nav-item" @click="goUrl('./api.html')">开放接口</div>
        <div class="nav-item" @click="goUrl('https://club.rt-thread.org/index.html')">开发者社区</div>
      </div>
      <div>
        <MyTop></MyTop>
      </div>
    </div>
    <div style="flex: 1"></div>
  </div>
</template>

<script>
import MyTop from './my/MyTop'
export default {
  name: 'TopLoginNav',
  components: { MyTop },
  data () {
    return {
      cRouteNodePath: '',
      topRoutes: [
        {
          path: '/control',
          name: '控制台'
        },
        {
          path: '/project',
          name: '手表项目'
        },
        {
          path: '/wearapp',
          name: '穿戴应用'
        },
        {
          path: '/dialapp',
          name: '表盘应用'
        },
        {
          path: '/openapi',
          name: '开放应用'
        }
      ]
    }
  },
  watch: {
    $route () {
      this.cRouteNodePath = this.getRouteNodePath(1)
    }
  },
  methods: {
    goUrl (url) {
      window.open(url)
    },
    jumpPath (path) {
      if (this.cRouteNodePath === path) {
        return
      }
      this.$router.push({ path: path })
      this.cRouteNodePath = path
    },
    getRouteNodePath (node) {
      const routePaths = this.$router.currentRoute.path.split('/')
      return '/' + routePaths[node]
    }
  },
  created () {
    this.cRouteNodePath = this.getRouteNodePath(1)
  }
}
</script>

<style scoped>
  .nav-item{
    width: 70px;
    font-size: 14px;
    text-align: center;
    color: #606468;
    font-weight: 500;
    margin-left: 20px;
    margin-right: 10px;
    border-bottom: 3px transparent solid;
    margin-bottom: -1px;
  }
  .nav-item-act{
    color: #1072e2;
    border-bottom: 3px #1072e2 solid;
  }
  .nav-item:hover{
    cursor:pointer;
    color: #1072e2;
    border-bottom: 3px #1072e2 solid;
  }
</style>
