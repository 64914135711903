<template>
  <div  style="height: 100%;width: 100%;overflow-y: auto;background-color: white" class="home-view">
    <el-carousel ref="carouselX"  @change="hdpChange" height="100%" style="height: 100%" direction="vertical" :autoplay="false">
      <el-carousel-item>
        <div class="img-layout" ref="scoll1" style="height: 100%;width: 100%;overflow-y: auto">
          <img src="../../assets/home_bg_1.png" style="height: calc(100% + 20px);width:100%;object-fit: cover;"/>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="img-layout" ref="scoll2" style="height: 100%;width: 100%;overflow-y: auto">
          <img src="../../assets/home_bg_2.png"  style="height: calc(100% + 20px);width:100%;object-fit: cover;"/>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="img-layout" ref="scoll3" style="height: 100%;width: 100%;overflow-y: auto">
          <img src="../../assets/home_bg_3.png"  style="height: calc(100% + 20px);width:100%;object-fit: cover;"/>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="img-layout" ref="scoll4" style="height: 100%;width: 100%;overflow-y: auto">
          <img src="../../assets/home_bg_4.png"  style="height: calc(100% + 20px);width:100%;object-fit: cover;"/>
        </div>
      </el-carousel-item>
    </el-carousel>
<!--    <el-carousel height="200px" direction="vertical" :autoplay="false">-->
<!--      <el-carousel-item v-for="item in 3" :key="item">-->
<!--        <h3 class="medium">{{ item }}</h3>-->
<!--      </el-carousel-item>-->
<!--    </el-carousel>-->
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data () {
    return {
      hdpIndex: 0,
      changeTime: 0
    }
  },
  methods: {
    hdpChange (index) {
      this.hdpIndex = index
      setTimeout(() => {
        this.$refs.scoll1.scrollTo(0, 10)
        this.$refs.scoll2.scrollTo(5, 10)
        this.$refs.scoll3.scrollTo(5, 10)
        this.$refs.scoll4.scrollTo(20, 10)
      }, 10)
    },
    scrollChange (e) {
      if (e.target.scrollTop === 0) {
        const xtime = (new Date()).valueOf()
        if (this.hdpIndex !== 0 && xtime > (this.changeTime + 300)) {
          this.$refs.carouselX.prev()
          this.changeTime = xtime
        }
      }
      const scrollTop = e.target.scrollTop
      const currentHeight = scrollTop + this.offsetHeight
      // 保证向上回滚后达到临界值可以再次触底记录
      if (currentHeight < this.scrollHeight && this.isReachBottom) {
        this.isReachBottom = false
      }
      // 保证达到临界值后只记录一次触底
      if (this.isReachBottom) {
        return
      }
      if (currentHeight >= this.scrollHeight) {
        this.isReachBottom = true
        const xtime = (new Date()).valueOf()
        if (this.hdpIndex < 3 && xtime > (this.changeTime + 300)) {
          this.$refs.carouselX.next()
          this.changeTime = xtime
        }
      }
    }
  },
  created () {
    setTimeout(() => {
      // 获取元素
      const scrollview = this.$refs.scoll1

      this.$refs.scoll1.addEventListener('scroll', this.scrollChange, true)
      this.$refs.scoll2.addEventListener('scroll', this.scrollChange, true)
      this.$refs.scoll3.addEventListener('scroll', this.scrollChange, true)
      this.$refs.scoll4.addEventListener('scroll', this.scrollChange, true)

      this.$refs.scoll1.scrollTo(0, 10)

      // 定义滚动到距离底部50px时既认为已经触底（因为此时已经展现绝大部分内容）
      this.scrollHeight = scrollview.scrollHeight - 5
      this.offsetHeight = Math.ceil(scrollview.getBoundingClientRect().height)
    }, 100)
  }
}
</script>

<style scoped>
  .el-carousel__item h3 {
    font-size: 14px;
    line-height: 200px;
    margin: 0;
  }

  .home-view .img-layout::-webkit-scrollbar{
    display: none;
  }

  .home-view >>> .el-carousel__indicator--vertical .el-carousel__button{
    width: 7px;
    height: 22px;
    background-color: #8c939d;
  }
  .home-view >>> .el-carousel__indicator--vertical .el-carousel__button{
    width: 8px;
    margin-top: 10px;
    height: 22px;
    background-color: #ced3db;
  }
  .home-view >>> .is-active .el-carousel__button{
    background-color: #0065f3;
  }
  .home-view >>> .el-carousel__indicators--vertical{
    left: 5%;
    width: 20px;
    top: 40%;
  }
</style>
