import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/home/HomePage'
import ControlPage from '../views/module/control/ControlPage'
import store from '../store'
import AccountInfoComplete from '../views/module/account/AccountInfoComplete'
import WearappPage from '../views/module/software/WearappPage.js'
import DialappPage from '../views/module/software/DialappPage.js'
import OpenapiProject from '../views/module/apiapp/OpenapiProject'
import OpenapiProjectList from '../views/module/apiapp/list/OpenapiProjectList'
import OpenapiProjectInfo from '../views/module/apiapp/info/OpenapiProjectInfo'
import EditAccountUserInfo from '../views/module/account/EditAccountUserInfo'
import EditAccountCompanyInfo from '../views/module/account/EditAccountCompanyInfo'
import SdkList from '../views/module/sdk/SdkList'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    needLogin: false,
    component: HomePage
  },
  {
    path: '/control',
    name: 'control',
    meta: {
      needLogin: true
    },
    component: ControlPage
  },
  {
    path: '/project',
    name: 'project',
    meta: {
      needLogin: true
    },
    component: () => import('../views/module/project/index')
  },
  {
    path: '/wearapp',
    name: 'wearapp',
    meta: {
      needLogin: true
    },
    component: WearappPage
  },
  {
    path: '/dialapp',
    name: 'dialapp',
    meta: {
      needLogin: true
    },
    component: DialappPage
  },
  {
    path: '/openapi',
    name: 'openapi',
    redirect: '/openapi/list',
    meta: {
      needLogin: true
    },
    component: OpenapiProject,
    children: [
      {
        path: '/openapi/list',
        name: 'openapi-list',
        meta: {
          needLogin: true
        },
        component: OpenapiProjectList
      },
      {
        path: '/openapi/info',
        name: 'openapi-info',
        meta: {
          needLogin: true
        },
        component: OpenapiProjectInfo
      }
    ]
  },
  {
    path: '/accountInfoComplete',
    name: 'accountInfoComplete',
    meta: {
      needLogin: true
    },
    component: AccountInfoComplete
  },
  {
    path: '/account/editUser',
    name: 'accountEditUser',
    meta: {
      needLogin: true
    },
    component: EditAccountUserInfo
  },
  {
    path: '/account/editCompany',
    name: 'accountEditCompany',
    meta: {
      needLogin: true
    },
    component: EditAccountCompanyInfo
  },
  {
    path: '/sdkList',
    name: 'sdkList',
    meta: {
      needLogin: false
    },
    component: SdkList
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('login/LOGIN_FROM_LOCAL')
  // console.log('store.state.login.isLogin:' + store.state.login.isLogin)
  if (store.state.login.isLogin) {
    if (store.state.my.developerT === 0) {
      if (to.path !== '/accountInfoComplete') {
        next('/accountInfoComplete')
      }
      next()
    } else {
      if (to.path === '/accountInfoComplete') {
        next('/control')
      }
      next()
    }
    // next();
  } else {
    if (to.meta.needLogin) {
      next('/')
    } else {
      next()
    }
  }
})
export default router
