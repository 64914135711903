import { AppApiResult, AppRequest } from './request/AppRequest'
export default {
  async getMyProjectList (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/open/project/myProjectList',
        param: p,
        config: { showLoad: false, loadText: '用户信息获取中' }
      })
    })
  },
  async getDetail (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/open/project/detail',
        param: p,
        config: { showLoad: false, loadText: '用户信息获取中' }
      })
    })
  },
  async createProject (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/open/project/create',
        param: p,
        // paramType: 'JSON',
        config: { showLoad: true, loadText: '项目保存中' }
      }),
      showSuc: true,
      sucText: '保存成功'
    })
  },
  async editProject (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/open/project/edit',
        param: p,
        // paramType: 'JSON',
        config: { showLoad: true, loadText: '项目保存中' }
      }),
      showSuc: true,
      sucText: '保存成功'
    })
  },
  async getDeveloperCount (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/open/project/developerCount',
        param: p,
        config: { showLoad: false, loadText: '应用提交中' }
      })
    })
  },
  async sdkDirectoryEdit (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/open/sdk/directory/edit',
        param: p,
        config: { showLoad: true, loadText: '操作提交中' }
      }),
      showSuc: true,
      sucText: '编辑成功'
    })
  },
  async sdkDirectoryList (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/open/sdk/directory/list',
        param: p,
        config: { showLoad: false, loadText: '操作提交中' }
      }),
      showSuc: false,
      sucText: '操作成功'
    })
  },
  async sdkDirectoryInfo (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/open/sdk/directory/info',
        param: p,
        config: { showLoad: false, loadText: '操作提交中' }
      }),
      showSuc: false,
      sucText: '操作成功'
    })
  },
  async sdkEdit (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/open/sdk/edit',
        param: p,
        config: { showLoad: true, loadText: '操作提交中' }
      }),
      showSuc: true,
      sucText: '编辑成功'
    })
  },
  async sdkList (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/open/sdk/list',
        param: p,
        config: { showLoad: false, loadText: '操作提交中' }
      }),
      showSuc: false,
      sucText: '操作成功'
    })
  },
  async sdkInfo (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/open/sdk/info',
        param: p,
        config: { showLoad: false, loadText: '操作提交中' }
      }),
      showSuc: false,
      sucText: '操作成功'
    })
  },
  async versionSave (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/open/sdk/version/save',
        param: p,
        config: { showLoad: true, loadText: '版本提交中' }
      }),
      showSuc: true,
      sucText: '版本提交成功'
    })
  },
  async versionList (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/rtt/open/sdk/version/list',
        param: p,
        config: { showLoad: false, loadText: '操作提交中' }
      }),
      showSuc: false,
      sucText: '操作成功'
    })
  }
}
