const state = {
  developerT: -1,
  nickName: '',
  uid: null,
  info: {},
  companyInfo: {}
}
const mutations = {
  UP_MYINFO (state, data) {
    state.info = data
    state.companyInfo = state.info.companyInfo
    state.developerT = state.info.developerT
    state.nickName = state.info.nickName
    state.uid = state.info.uid
  }
}
const actions = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
