const state = {
  isLogin: false,
  token: null
}
const mutations = {
  LOGIN_IN (state, data) {
    state.isLogin = true
    state.token = data
    localStorage.setItem('developerLogin', JSON.stringify(state))
  },
  LOGIN_OUT (state) {
    state.isLogin = false
    state.token = null
    localStorage.setItem('developerLogin', JSON.stringify(state))
  },
  LOGIN_FROM_LOCAL (state) {
    const login = localStorage.getItem('developerLogin')
    if (login) {
      state.isLogin = JSON.parse(login).isLogin
      state.token = JSON.parse(login).token
    }
  }
}
const actions = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
