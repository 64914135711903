<template>
    <div>
      <input type="file" ref="upload" style="display: none" @change="afterSel"/>
      <div @click="$refs.upload.click()">
        <slot></slot>
      </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'EzImgSelUpload',
  props: ['toUpload', 'url', 'types'],
  data () {
    return {
      imgUrl: ''
    }
  },
  methods: {
    afterSel (i) {
      var _typeList = this.types || this.$appConfig.upload.defTypes
      var self = this
      var oFReader = new FileReader()
      var file = i.target.files[0]
      if (!file) {
        return
      }
      const _fileType = file.type
      const _index = _fileType.indexOf('/')

      let _type = ''
      if (_index > -1) {
        _type = _fileType.substring(_index + 1, _fileType.length)
      } else {
        const _ix = file.name.lastIndexOf('.')
        if (_ix > -1) {
          _type = file.name.substring(_ix + 1, file.name.length)
        }
      }
      if (this.$ez.util.str.isEmpty(_type)) {
        this.$message.error('未知的文件类型,支持以下格式:' + _typeList.toString())
        return false
      }
      const _r = _typeList.indexOf(_type)
      if (_r < 0) {
        this.$message.error('文件格式不符合,支持以下格式:' + _typeList.toString())
        return false
      }
      oFReader.readAsDataURL(file)
      oFReader.onloadend = function (oFRevent) {
        var src = oFRevent.target.result
        self.$emit('afterSel', src)
      }
      const _imgList = ['png', 'jpg', 'jpeg', 'bmp']
      if (_imgList.indexOf(_type) > -1) {
        if (this.$ez.util.number.getSizeMb(file.size) > 2.1) {
          this.$message.error('上传图片不得大于2M')
          return false
        }
      }
      // eslint-disable-next-line no-undef
      if (this.toUpload !== false) {
        this.update(i)
      }
    },
    async update (e) { // 上传照片
      const file = e.target.files[0]
      /* eslint-disable no-undef */
      const param = new FormData() // 创建form对象
      param.append('files', file) // 通过append向form对象添加数据
      // param.append('files', file)
      // param.append('files', file)
      // console.log(param.get('file')) // FormData私有类对象，访问不到，可以通过get判断值是否传进去
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
      const self = this
      const loadKey = this.$ez.util.load.show('图片上传中')
      // 添加请求头
      // const _url = this.$appConfig.upload.urls.defUrl
      // if (this.url && this.url !== 'undefined') {
      //   _url = this.url
      // }
      // console.log(this.url ?? this.$appConfig.upload.defUrl)
      // let that = this
      axios.post(this.url ?? this.$appConfig.upload.defUrl, param, config).then((_r) => {
        if (_r.data.code === 0) {
          _r.data.data[0].fileName = file.name
          self.$emit('afterUpload', _r.data.data)
        } else {
          self.$message.error('文件上传失败请重试！')
        }
        // console.log('=======1=======')
      }, err => {
        // 关闭加载中
        self.$message.error('文件上传失败请重试！')
        console.error(err)
      }).catch((e) => {
        // console.log('=======2=======')
        // this.$message.error('文件上传失败请重试！')
      }).finally((r) => {
        // console.error(r)
        // console.log('=======3=======')
        self.$ez.util.load.close(loadKey, 200)
      })
      // console.log('==============')
    }
  }
}
</script>

<style scoped>
</style>
