<template>
    <div style="width: 100%;height: 100%;background: #F8F9FB">
      <div style="height: 180px;width: 100%;top: 0px;left: 0px;">
        <img src="../../../assets/top_bg.png" style="height: 100%;width: 100%"/>
      </div>
      <div style="top: 0px;left: 0px;width: 100%;display: flex;margin-top: -185px;height: 100%;overflow-y: auto;">
        <div style="flex: 1"></div>
        <div style="width: 1200px;margin-top: 20px">
          <div style="background-color: rgba(255,255,255,0.3);height: 40px;font-size: 13px;line-height: 40px;padding-left: 20px;color: #fff">
            RTT开发者平台上线啦
          </div>
          <div style="width: 100%;display: flex;margin-top: 15px">
            <div style="flex:1;">
              <ControlMyInfo :myCount="myCount"></ControlMyInfo>
              <div style="margin-top: 15px">
                <ControlProjectTable></ControlProjectTable>
              </div>
              <div style="margin-top: 15px">
                <ControlSoftwareTable :type="1"></ControlSoftwareTable>
              </div>
              <div style="margin-top: 15px">
                <ControlSoftwareTable :type="2"></ControlSoftwareTable>
              </div>
              <div style="height: 100px"></div>
            </div>
            <div style="width: 320px;margin-left: 15px">
              <ControlRight  :myCount="myCount"></ControlRight>
            </div>
          </div>
        </div>
        <div style="flex: 1"></div>
      </div>
    </div>
</template>

<script>
import ControlRight from './right/ControlRight'
import ControlMyInfo from './my/ControlMyInfo'
import SoftwareApi from '../../../common/api/SoftwareApi'
import OpenapiApi from '../../../common/api/OpenApi'
import ControlSoftwareTable from './software/ControlSoftwareTable'
import ControlProjectTable from './software/ControlProjectTable.vue'
export default {
  name: 'ControlPage',
  components: {
    ControlSoftwareTable,
    ControlMyInfo,
    ControlRight,
    ControlProjectTable
  },
  data () {
    return {
      myCount: {
        appCount: null,
        dialCount: null,
        downNum: null,
        openapiProjectCount: null
      }
    }
  },
  methods: {
    async getCount () {
      const _sR = await SoftwareApi.getDeveloperCount({ developerId: this.$store.state.my.uid })
      if (_sR.r) {
        this.myCount.appCount = _sR.data.appCount
        this.myCount.downNum = _sR.data.downNum
        this.myCount.dialCount = _sR.data.dialCount
        this.myCount.projectCount = _sR.data.projectCount
      }
      const _pR = await OpenapiApi.getDeveloperCount({ developerId: this.$store.state.my.uid })
      if (_pR.r) {
        this.myCount.openapiProjectCount = _pR.data.openapiProjectCount
      }
    }
  },
  created () {
    this.getCount()
  }
}
</script>
<style lang="less">
  .control-card{
    padding: 20px;
    background-color: white;
    box-shadow: 5px 0px 10px rgba(80, 78, 78, 0.07);
    .control-card-title{
      font-size: 15px;
      color: #333;
      font-weight: bold;
    }
  }
</style>
<style scoped>

</style>
