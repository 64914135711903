<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ez_view'
}
</script>

<style scoped>
  /*.ez-dialog >>> .el-dialog__header{*/
  /*  display: none;*/
  /*}*/
  .ez-dialog >>> .el-dialog__body{
    padding: 0px;
  }
  .ez-dialog >>> .el-dialog__close{
    font-size: 22px;color: #8c939d;margin-top: 3px;
  }
  .ez-e-steps{}
  .ez-e-steps >>> .el-step__head.is-success{
    /*border: 2px silver solid;*/
    color: #0072ff;
    border-color: #b3c1f5;
  }
  .ez-e-steps >>> .el-step__title{
    color: white;
    font-size: 14px;
    font-weight: 500;
  }
  .ez-e-steps >>> .el-step__head.is-wait{
    color: transparent;
  }
  .ez-e-steps >>> .is-wait .el-step__icon.is-text{
    border: 5px solid #9ec4ff;
    background-color: #fff;
  }
  .ez-e-steps >>> .is-success .el-step__icon.is-text{
    border: 0px solid #b8d2ff;
  }
  .ez-e-steps >>> .is-process .el-step__icon.is-text{
    border: 2px solid #9ec4ff;
  }
  .ez-e-steps >>> .el-step__head.is-process{
    color: transparent;
    border-color: white;
  }
  .ez-e-steps >>> .is-process .el-step__icon{
    color: transparent;
    background-color: #ffffff;
  }
</style>
