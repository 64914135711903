<template>
  <div style="width: 100%;height: 100%;display: flex;flex-direction: column">
    <div style="border-bottom: 1px #eaebef solid;padding-bottom: 0px;">
      <div style="display: flex">
        <div style="font-size: 15px;color:#666;padding-bottom: 0px;display: flex;font-weight: 500;flex: 1;
        border-left: 5px #1B8AFA solid;padding-top: 0px;padding-left: 10px;font-size: 18px;font-weight: bold;color: #333333">
          {{directoryInfo.name}}
        </div>
      </div>
      <div style="height: 15px"></div>
    </div>
    <div style="flex: 1;height: 0px;margin-top: 10px;font-size: 15px;overflow-y: auto">
      <div v-html="directoryInfo.introduce"></div>
    </div>
    <el-dialog
      :visible.sync="showEdit"
      :close-on-click-modal="false"
      top="60px"
      width="800px">
      <div slot="title" class="rttm-card-title" style="margin-top: 3px;margin-left: 8px">
        SDK目录编辑
      </div>
     <SdkDirectoryEdit v-if="showEdit" :directory-info="this.directoryInfo"></SdkDirectoryEdit>
    </el-dialog>
  </div>
</template>

<script>
import OpenApi from '../../../../common/api/OpenApi'
export default {
  name: 'SdkDirectoryMainView',
  data () {
    return {
      showEdit: false,
      directoryInfo: {}
    }
  },
  watch: {
    $route () {
      if (this.$route.query.directoryId !== this.directoryInfo.id) {
        this.getDirInfo()
      }
    }
  },
  methods: {
    async getDirInfo () {
      const _r = await OpenApi.sdkDirectoryInfo({ directoryId: this.$route.query.directoryId })
      if (_r.r) {
        this.directoryInfo = _r.data
      }
    }
  },
  created () {
    this.getDirInfo()
  }
}
</script>

<style scoped>

</style>
