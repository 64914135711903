<template>
  <div class="software-version-info-container">
    <div class="info-item-container">
      <h3 class="info-item-title">
        应用 Logo
      </h3>
      <div class="info-item-main">
        <img :src="softwareInfo.logo" alt="">
      </div>
    </div>
    <div class="info-item-container">
      <h3 class="info-item-title">
        版本号
      </h3>
      <div class="info-item-main">
        <el-input :value="softwareInfo.version" :disabled="true"></el-input>
      </div>
    </div>
    <div class="info-item-container">
      <h3 class="info-item-title">
        预览图
      </h3>
      <div class="info-item-main info-preview-imgs">
        <template v-for="(item, index) in JSON.parse(softwareInfo.previewImgs)">
          <img :src="item" alt="" :key="index">
        </template>
      </div>
    </div>
    <div class="info-item-container">
      <h3 class="info-item-title">
        支持语言
      </h3>
      <div class="info-item-main">
        <span>{{ softwareInfo.language ? softwareInfo.language : "暂无" }}</span>
      </div>
    </div>
    <div class="info-item-container">
      <h3 class="info-item-title">
        支持手表型号
      </h3>
      <div class="info-item-main">
        <el-input :value="softwareInfo.projectName" :disabled="true"></el-input>
      </div>
    </div>

    <div class="info-item-container">
      <h3 class="info-item-title">
        版本描述
      </h3>
      <div class="info-item-main">
        <el-input type="textarea" autosize resize="none" :value="softwareInfo.versionDescription" :disabled="true"></el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SoftwareVersionInfo',
  props: {
    softwareInfo: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style lang="less" scoped>
.software-version-info-container{
  width: 80%;
  max-height: calc(100vh - 200px);
  overflow: auto;
  padding: 10px 40px;
  .info-item-main{
    img{
      width: 80px;
      height: 80px;
      border-radius: 4px;
      border: 1px solid #eeeeee;
    }
  }
  .info-preview-imgs{
    overflow-x: auto;
    display: flex;
    img{
      margin: 0 8px;
    }
  }
}
</style>
