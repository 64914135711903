<template>
  <div style="width: 100%;height: 100%;display: flex;flex-direction: column">
    <div style="border-bottom: 1px #eaebef solid;padding-bottom: 0px">
      <div style="display: flex">
        <div style="font-size: 15px;color:#666;padding-bottom: 0px;display: flex;font-weight: 500;flex: 1;
        border-left: 5px #1B8AFA solid;padding-top: 0px;padding-left: 10px;font-size: 18px;font-weight: bold;color: #333333">
          {{sdkInfo.name}}
        </div>
      </div>
      <div style="margin-top: 10px;display: flex;">
        <div @click="showTab = 1" :class="showTab == 1 ? 'top-menu-act':''" class="top-menu">
          SDK介绍
        </div>
        <div @click="showTab = 2" :class="showTab == 2 ? 'top-menu-act':''" class="top-menu">
          历史版本
        </div>
      </div>
    </div>
    <div style="flex: 1;height: 0px">
      <SdkIntroduceView v-if="showTab == 1" :sdk-info="sdkInfo"></SdkIntroduceView>
      <SdkVersionView v-if="showTab == 2" :sdk-info="sdkInfo"></SdkVersionView>
    </div>
  </div>
</template>

<script>
import SdkIntroduceView from './introduce/SdkIntroduceView'
import OpenApi from '../../../../common/api/OpenApi'
import SdkVersionView from './version/SdkVersionView'
export default {
  name: 'SdkManageMain',
  components: { SdkVersionView, SdkIntroduceView },
  data () {
    return {
      shwoPublishVersion: false,
      showTab: 1,
      showEdit: false,
      sdkInfo: {}
    }
  },
  watch: {
    $route () {
      if (this.$route.query.sdkId !== this.sdkInfo.id) {
        this.getSdkInfo()
      }
    }
  },
  methods: {
    async getSdkInfo () {
      const _r = await OpenApi.sdkInfo({ sdkId: this.$route.query.sdkId })
      if (_r.r) {
        this.sdkInfo = _r.data
      }
    }
  },
  created () {
    this.getSdkInfo()
  }
}
</script>

<style scoped>
.top-menu{
  height: 46px;
  line-height: 46px;
  font-size: 15px;
  font-weight: 500;
  color: #666666;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 3px transparent solid;
  margin-right: 50px;
}
.top-menu-act{
  color: #1B8AFA;
  border-bottom: 3px #1B8AFA solid;
}
  .top-menu:hover{
    color: #1B8AFA;
    border-bottom: 3px #1B8AFA solid;
  }
</style>
