<template>
  <div>
    <div class="rttm-card" style="padding: 40px;padding-top: 20px;padding-bottom:12px;max-height: calc(100vh - 250px);overflow-y: auto;">
      <el-form label-position="top"
               label-width="80px"
               ref="form"
               :rules="rules"
               :model="formData">
        <el-form-item label="应用名称" prop="name" :rules="[{ required: true, message: '应用名称不能为空'}]">
          <el-input size="small" :maxlength="20"  v-model="formData.name" placeholder="请输入应用名称" disabled></el-input>
        </el-form-item>
        <div style="display:flex;margin-top: 10px">
          <div style="flex: 1">
            <el-form-item label="应用logo" prop="logo" :rules="[{ required: true, message: 'logo必须上穿'}]" style="margin-top: -15px">
              <div style="display:flex;">
                <EzFileSel @afterUpload="afterUploadLogo" style="height: 80px">
                  <div style="position: relative">
                    <div style="z-index: 998;">
                      <img :src="formData.logo" style="z-index: 998;height: 80px;width: 80px;;border-radius: 10px;border: 1px #eaebee solid">
                    </div>
                    <div style="margin-top: -46px;z-index: 999;position: absolute">
                      <div style="height: 30px;background-color: rgba(0,0,0,0.4);line-height: 30px;text-align: center;color: white;font-size: 13px;font-weight: 900;
                  width: 81px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px">
                        点击切换
                      </div>
                    </div>
                  </div>
                </EzFileSel>
              </div>
            </el-form-item>
          </div>
          <div style="flex: 1">
            <el-form-item label="安装包文件" prop="packageUrl"
                          :rules="[{ required: true, message: '请上传安装包文件'}]"
                          style="margin-top: -15px">
              <div style="display:flex;">
                <EzFileSel @afterUpload="afterUploadPackage" style="height: 80px"
                           :url="$appConfig.upload.urls.packages"
                           :types="software.type == 1 ? $appConfig.upload.types.rttApp : $appConfig.upload.types.rttDial ">
                  <div v-if="formData.packageUrl != null"  style="position: relative">
                    <div style="z-index: 998;height: 80px;width: 80px;background-color: #f6f6fb;text-align: center;line-height: 80px;border-radius: 10px">
                      <img src="../../../../../assets/img/def_app.png" height="50" width="50" style="margin-top: 13px"/>
                    </div>
                    <div style="margin-top: -30px;z-index: 999;position: absolute;">
                      <div style="height: 30px;background-color: rgba(0,0,0,0.4);
                line-height: 30px;text-align: center;color: white;font-size: 13px;font-weight: 900;
                  width: 81px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px">
                        点击切换
                      </div>
                    </div>
                  </div>
                  <img  v-else src="../../../../../assets/img/sel_file.png" height="80" width="80" />
                </EzFileSel>
              </div>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="应用版本号" prop="version" style="margin-top: -10px">
          <el-input size="small" @input="versionCheck" :maxlength="20"  v-model="formData.version" placeholder="请输入应用版本号">
            <template slot="prepend">V</template>
          </el-input>
        </el-form-item>
        <el-form-item label="应用截图" prop="previewImgs"
                      style="margin-top: -5px">
          <div style="display: flex;margin-top: 5px">
            <div v-for="(item,key) in previewImgList" :key="key" style="height: 80px;width: 80px;position: relative;margin-right: 25px">
              <el-image fit="cover"
                style="width: 80px; height: 80px"
                :src="item"
                :preview-src-list="previewImgList">
              </el-image>
              <div @click="delPreviewImg(item)" style="height: 24px;width: 24px;background-color: rgba(0,0,0,0.6);
                color: white;font-size: 16px;line-height: 26px;text-align: center;
                position: absolute;top: -10px;right: -10px;border-radius: 20px">
                <i class="el-icon-close" style="margin-left: 2px"></i>
              </div>
            </div>
            <EzFileSel v-if="previewImgList.length < 6" @afterUpload="afterPreviewImg" style="">
              <img src="../../../../../assets/img/sel_file.png" height="80" width="80" />
            </EzFileSel>
          </div>
        </el-form-item>
        <el-form-item label="支持语言" prop="language" :rules="[{ required: true, message: '请上传安装包文件'}]" style="margin-top: -5px">
          <div style="margin-top: -10px">
            <el-radio v-model="formData.language" label="中文">中文</el-radio>
            <el-radio v-model="formData.language" label="英文">英文</el-radio>
            <el-radio v-model="formData.language" label="多语言">多语言</el-radio>
          </div>
        </el-form-item>
        <!-- 选择支持的设备型号 -->
        <el-form-item label="支持的产品" prop="projectId" :rules="[{ required: true, message: '请选择支持的产品' }]">
          <el-select placeholder="请选择支持的产品" v-model="formData.projectId">
            <el-option v-for="(item, index) in projects" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="更新描述" prop="versionDescription" :rules="[{ required: true, message: '请输入更新描述'}]"  style="margin-top: -10px">
          <el-input size="small" :maxlength="250"  v-model="formData.versionDescription"  type="textarea"   :rows="4" placeholder="请输入更新描述" style="width: 100%"></el-input>
        </el-form-item>
        <div></div>
      </el-form>
    </div>
    <div style="text-align: center;margin-top: 20px;">
      <el-button type="primary" style="background-color: #0052D9;border: 0px;height: 38px" @click="saveData()">提交版本</el-button>
    </div>
    <div style="height: 20px"></div>
  </div>
</template>

<script>

import SoftwareApi from '@/common/api/SoftwareApi'
import { mapState } from 'vuex'

export default {
  props: ['software'],
  name: 'SoftwareVersionUpload',
  data () {
    var previewImgsCheck = (rule, value, callback) => {
      if (this.previewImgList.length < 3) {
        return callback(new Error('应用截图至少为3张'))
      } else {
        callback()
      }
    }
    var versionCheck = (rule, value, callback) => {
      const x = this.formData.version.split('.')
      if (x.length < 3) {
        return callback(new Error('版本至少为三级'))
      }
      callback()
    }
    return {
      formData: {
        softwareId: this.software.id,
        name: this.software.name,
        logo: this.software.logo,
        packageUrl: null,
        version: null,
        packageSize: null,
        previewImgs: null,
        language: this.software.language ?? '中文',
        projectId: '',
        versionDescription: null
      },
      rules: {
        previewImgs: [
          { required: true, message: '请上传应用截图' },
          { validator: previewImgsCheck, trigger: 'blur' }
        ],
        version: [
          { required: true, message: '请上输入版本号' },
          { validator: versionCheck, trigger: 'blur' }
        ]
      },
      previewImgList: [],
      imgFileNameList: [],
      projects: []
    }
  },
  computed: {
    ...mapState('my', {
      uid: state => state.uid
    })
  },
  methods: {
    versionCheck (val) {
      this.formData.version = this.formData.version.replaceAll(' ', '')
      // console.log(val);
      const codeReg = new RegExp('[z0-9.]+') // 正则 英文+数字；
      const len = val.length
      let str = ''
      for (var i = 0; i < len; i++) {
        if (codeReg.test(val[i])) {
          str += val[i]
        }
      }
      this.formData.version = str
    },
    async saveData () {
      const _value = this.$ez.util.form.checkData({ that: this, ref: 'form', formData: this.formData })
      if (!_value) { return false }
      // todo 版本验证
      if (this.$ez.util.str.isNotEmpTy(this.software.version)) {
        // const oldVList = this.software.version.split('.')
        // const newVList = _value.version.split('.')
        // let _flag = false
        // for (let i = 0; i < newVList.length; i++) {
        //   if (oldVList.length < i + 1) {
        //     _flag = true
        //     break
        //   }
        //   if (newVList[i] > oldVList[i]) {
        //     _flag = true
        //     break
        //   } else if (newVList[i] < oldVList[i]) {
        //     _flag = false
        //     break
        //   }
        // }
        // if (this.software.version === _value.version) {
        //   _flag = false
        // }
        // if (!_flag) {
        //   this.$message.error('上传应用版本必须大于当前版本')
        //   return false
        // }
      }

      this.$messageBox.confirm('版本上传后，对应手表项目的上一个版本将被禁用', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(res => {
        SoftwareApi.addVersion(_value).then(res => {
          if (res.data.code !== 0) {
            this.$message.error(res.data.msg)
          } else {
            this.$message.success(res.data.msg)
            this.$emit('saveOver')
          }
        })
      })
    },
    delPreviewImg (d) {
      const i = this.previewImgList.indexOf(d)

      const indexxx = this.imgFileNameList.findIndex((e) => e.url === d)
      if (indexxx > -1) {
        this.imgFileNameList.splice(indexxx, 1)
      }

      this.previewImgList.splice(i, 1)
      this.formData.previewImgs = JSON.stringify(this.previewImgList)
      if (this.previewImgList.length <= 0) {
        this.formData.previewImgs = null
      }
    },
    afterPreviewImg (d) {
      if (this.imgFileNameList.findIndex((e) => e.fileName === d[0].fileName) > -1) {
        this.$message.error('已上传过该图片,不可重复上传同一图片！')
        return false
      }
      this.imgFileNameList.push(d[0])
      this.previewImgList.push(d[0].url)
      this.formData.previewImgs = JSON.stringify(this.previewImgList)
      if (this.previewImgList.length <= 0) {
        this.formData.previewImgs = null
      }
    },
    afterUploadLogo (d) {
      this.formData.logo = d[0].url
    },
    afterUploadPackage (d) {
      this.formData.packageUrl = d[0].url
      this.formData.packageSize = d[0].size
    },
    getProjectList () {
      SoftwareApi.getProjectsByUid({ uid: this.uid }).then(res => {
        this.projects = res.data.records
      })
    }
  },
  created () {
    this.getProjectList()
    // try {
    //   this.previewImgList = JSON.parse(JSON.stringify(this.software.previewImgList))
    //   if (!this.previewImgList) { this.previewImgList = [] }
    // } catch (e) {
    //   console.error(e)
    // }
    // if (this.previewImgList.length > 0) {
    //   this.formData.previewImgs = JSON.stringify(this.previewImgList)
    // }
  }
}
</script>

<style scoped>

</style>
