<template>
  <div style="width: 100%">
    <div>
      <div class="control-card">
        <div class="control-card-title">我的应用</div>
        <div style="display: flex;margin-top: 15px">
          <div @click="()=>{$router.push({path: '/wearapp'}) }" class="my-app-card">
            <div class="my-app-card-icon">
              <img src="../../../../assets/icon/app/app_ruanjian.png" style="height: 100%;width: 100%"/>
            </div>
            <div class="my-app-card-name">穿戴应用</div>
            <div class="my-app-card-value">{{myCount.appCount}}</div>
            <!--          <div class="my-app-card-unit">/个</div>-->
          </div>
          <div style="width: 10px"></div>
          <div @click="()=>{$router.push({path: '/dialapp'}) }" class="my-app-card">
            <div class="my-app-card-icon">
              <img src="../../../../assets/icon/app/app_biaopan.png" style="height: 100%;width: 100%"/>
            </div>
            <div class="my-app-card-name">表盘</div>
            <div class="my-app-card-value">{{myCount.dialCount}}</div>
          </div>
          <div style="width: 10px"></div>
          <div @click="()=>{$router.push({path: '/openapi'}) }" class="my-app-card">
            <div class="my-app-card-icon">
              <img src="../../../../assets/icon/app/app_jiekou.png" style="height: 100%;width: 100%"/>
            </div>
            <div class="my-app-card-name">接口应用</div>
            <div class="my-app-card-value">{{myCount.openapiProjectCount}}</div>
          </div>
        </div>
      </div>
      <div v-if="!hasPassword" style="margin-top: 15px;padding-bottom: 0px">
        <el-tag type="warning" style="width: 100%;border-radius: 0px;font-size: 13px;color: #f59e06">您还未设置登录密码,安全起见请尽快设置</el-tag>
      </div>
      <div class="control-card" style="margin-top: 15px;cursor: pointer">
        <div class="control-card-title">快速入口</div>
        <div style="display: flex;margin-top: 10px">
          <div @click="$router.push({path: '/account/editUser'})"  class="quick-item">
            <img src="../../../../assets/icon/my/my-info.png" height="32" width="32" style="margin-right: 10px"/>
            <div class="my-menu-text">编辑账号信息</div>
          </div>
          <div @click="$router.push({path: '/account/editCompany'})" class="quick-item">
            <img src="../../../../assets/icon/my/my-company.png" height="32" width="32" style="margin-right: 10px"/>
            {{myInfo.developerT == 1 ? '升级企业账号':'编辑企业信息'}}
          </div>
        </div>
        <div style="display: flex">
          <div @click="showPhoneUpdate = true" class="quick-item">
            <img src="../../../../assets/icon/my/my-setting.png" height="32" width="32" style="margin-right: 10px"/>
            更换手机号
          </div>
          <div @click="showUpdatePassword = true" class="quick-item">
            <img src="../../../../assets/icon/my/my-password.png" height="32" width="32" style="margin-right: 10px"/>
            设置登录密码
          </div>
        </div>
      </div>
      <div style="margin-top: 15px;padding-bottom: 0px">
        <el-tag style="width: 100%;border-radius: 0px;font-size: 13px;color: #0052D9;">费用订单系统暂未完全开放,敬请期待</el-tag>
      </div>
      <div class="control-card" style="margin-top: 15px;cursor: pointer">
        <div class="control-card-title">费用信息</div>
        <img src="../../../../assets/img/def/developer_yu_e.jpg" width="100%;" style="margin-top: 14px"/>
        <div class="control-card-title" style="margin-top: 15px">最新订单</div>
        <img src="../../../../assets/img/def/developer_order.jpg" width="100%" style="margin-top: 14px"/>
      </div>
    </div>
    <el-dialog
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :visible.sync="showPhoneUpdate"
      top="60px"
      width="440px">
      <div slot="title" class="rttm-card-title" style="margin-top: 3px;margin-left: 8px">
        更换绑定手机号
      </div>
      <UpdatePhone v-if="showPhoneUpdate"></UpdatePhone>
    </el-dialog>
    <el-dialog
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :visible.sync="showUpdatePassword"
      top="60px"
      width="440px">
      <div slot="title" class="rttm-card-title" style="margin-top: 3px;margin-left: 8px">
        设置登录密码
      </div>
      <UpdatePassword v-if="showUpdatePassword"></UpdatePassword>
    </el-dialog>
  </div>
</template>

<script>
import UpdatePhone from '../../account/update/phone/UpdatePhone'
import UpdatePassword from '../../account/update/password/UpdatePassword'
import { mapState } from 'vuex'
import AccountApi from '../../../../common/api/AccountApi'
export default {
  name: 'ControlRight',
  components: { UpdatePassword, UpdatePhone },
  props: ['myCount'],
  computed: {
    ...mapState('my', {
      nickName: state => state.nickName,
      myInfo: state => state.info
    })
  },
  data () {
    return {
      showPhoneUpdate: false,
      showUpdatePassword: false,
      hasPassword: false
    }
  },
  methods: {
    async getIsHasPassword () {
      const _r = await AccountApi.isHasPassword()
      if (_r.r) {
        this.hasPassword = _r.data
      }
    }
  },
  created () {
    this.getIsHasPassword()
  }
}
</script>

<style scoped lang="less">
  .quick-item{
    margin-top: 5px;
    flex:1;display: flex;line-height: 36px;font-size: 13px;
  }
  .my-app-card{
    flex: 1;
    height: 140px;
    box-shadow: 5px 0px 10px rgba(35, 34, 34, 0.07);
    background-image: linear-gradient(#f4f6fc, #fff);
    border: 3px white solid;
    border-radius: 3px;
    text-align: center;
    .my-app-card-icon{
      margin-top: 12px;
      height: 40px;
      width: 40px;
      /*background: #8c939d;*/
      display: inline-block;
    }
    .my-app-card-name{
      margin-top: 6px;
      font-size: 12px;
      color: #353a40;
    }
    .my-app-card-value{
      margin-top: 10px;
      color: #0067db;
      font-size: 24px;
      font-weight: bold;
    }
    .my-app-card-unit{
      margin-top: 5px;
      color: #8c939d;
      font-size: 12px;
    }
  }
</style>
