const strUtil = {
  isEmpty (d) {
    return !this.isNotEmpTy(d)
  },
  isNotEmpTy (d) {
    return d !== null && d !== undefined && d !== '' && d.length > 0
  }
}
export default strUtil

// console.log(strUtil.isEmpty('null'))
