<template>
  <div id="app" style="display: flex;flex-direction: column;height: 100%">
    <div>
      <TopLoginNav v-if="isLogin"></TopLoginNav>
      <TopNav v-else></TopNav>
    </div>
    <div v-if="accountLoadSuc" style="height: 0px;flex: 1;background-color: #f5f6f8">
      <router-view/>
    </div>
  </div>
</template>
<script>
import TopNav from './views/home/top/TopNav'
import TopLoginNav from './views/home/top/TopLoginNav'
import { mapMutations, mapState } from 'vuex'
import AccountApi from './common/api/AccountApi'
export default {
  components: {
    TopNav, TopLoginNav
  },
  computed: {
    ...mapState('login', {
      isLogin: state => state.isLogin
    })
  },
  data () {
    return {
      accountLoadSuc: false
    }
  },
  methods: {
    ...mapMutations('login', {
      LOGIN_FROM_LOCAL: 'LOGIN_FROM_LOCAL'
    }),
    ...mapMutations('my', {
      UP_MYINFO: 'UP_MYINFO'
    }),
    handlerRoute () {
      alert('====')
    }
  },
  mounted () {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
      // window.addEventListener('popstate', this.handlerRoute, false)
    }
  },
  async created () {
    this.LOGIN_FROM_LOCAL()
    if (!this.isLogin) {
      this.accountLoadSuc = true
      return
    }
    const _r = await AccountApi.myDetail()
    if (_r.r) {
      this.accountLoadSuc = true
      this.UP_MYINFO(_r.data)
      if (this.$store.state.my.developerT === 0) {
        this.$router.replace('/')
      }
    }
  }
}
</script>
<style lang="less">
  html,body{
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    font-size: 14px;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-width: 800px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
