import { AppApiResult, AppRequest } from './request/AppRequest'

export default {
  async registerVerifyCode (p) {
    return AppApiResult({
      filterCodes: [102001],
      res: await AppRequest({
        url: '/auth/register/developer/verifyCode',
        param: p,
        config: { showLoad: true, loadText: '正在注册中' }
      })
    })
  },
  async loginVerifyCode (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/auth/login/developer/verifyCode',
        param: p,
        config: { showLoad: true, loadText: '正在登录中' }
      })
    })
  },
  async loginPassword (p) {
    return AppApiResult({
      res: await AppRequest({
        url: '/auth/login/developer/password',
        param: p,
        config: { showLoad: true, loadText: '正在登录中' }
      })
    })
  }
}
