<template>
  <div class="detail-container">
    <div class="rttm-card" style="margin: 16px;">
      <!-- 顶部基本信息 -->
      <div>
        <div style="display: flex">
          <div style="height: 80px;width: 80px">
            <img :src="software.logo" style="height: 100%;width: 100%;border: 1px #f3f4f6 solid;border-radius: 15px;object-fit: cover">
          </div>
          <div style="margin-left: 20px;flex: 1;">
            <div style="display: flex">
              <div style="font-size: 22px;font-weight: 900">{{software.name}}</div>
            </div>
            <div style="display: flex;margin-top: 5px">
              <div style="font-size: 13px;color: #b9bfc7;margin-right: 10px;">应用分类</div>
              <div style="height: 21px;line-height: 22px;background-color: rgba(23,110,255,0.07);font-size: 13px;color: #0052D9;padding-left: 10px;padding-right: 10px">
                {{software.categoryName}}
              </div>
            </div>
            <!-- <div style="display: flex;margin-top: 6px">
              <div style="font-size: 13px;color: #b9bfc7;margin-right: 10px">最新版本</div>
              <span v-if="software.isHasVersion" style="font-size: 13px;color: #8c939d">v{{software.version}}</span>
              <span v-else style="font-size: 13px;color: #8c939d">暂无版本</span>
            </div> -->
          </div>
          <div style="">
            <el-button @click="toEdit" type="primary" size="mini" round style="background-color: #0052d9;border: 0px;height: 32px">编辑信息</el-button>
            <el-button @click="toUploadVersion" type="primary" size="mini" round style="background-color: #0052D9;border: 0px;height: 32px">更新{{type === 1 ? '穿戴':'表盘'}}版本</el-button>
          </div>
        </div>
        <div style="display: flex;margin-top: 20px">
          <div style="flex: 1">
            <div style="color: #818487;margin-top: 5px;font-weight: 500">AppId</div>
            <div style="color: #242e3d;margin-top: 5px;font-weight: 500">{{software.appId}}</div>
            <div style="display: flex">
              <div @click="copy(software.appId)" style="color: #0052d9;margin-top: 5px;font-size: 13px;cursor:pointer;">点击复制</div>
            </div>

          </div>
          <div style="flex: 1">
            <div style="color: #818487;margin-top: 5px;font-weight: 500">AppKey</div>
            <div style="color: #242e3d;margin-top: 5px;font-weight: 500;width: 100%;overflow: hidden">{{software.appKey}}</div>
            <div style="color: #bfc5ce;margin-top: 5px;font-size: 12px">密钥信息为敏感信息，请注意保密，不要泄露</div>
          </div>
          <div style="flex: 1">
            <div style="color: #818487;margin-top: 5px;font-weight: 500">应用开发商</div>
            <div style="color: #242e3d;margin-top: 5px;display: flex">
              <!-- <div style="font-size: 13px;color: #989ea5">开发者</div>
              <div style="color: #0052d9;font-size: 13px;margin-left: 15px">{{software.developerName}}</div> -->
            </div>
            <div style="color: #969ba0;margin-top: 5px;font-size: 12px;display: flex">
              <div style="font-size: 13px">提供方</div>
              <div style="color: #0052d9;font-size: 13px;margin-left: 15px">{{software.provider}}</div>
            </div>
          </div>
        </div>
        <div style="height: 1px;background-color: #f1f2f5;margin-top: 18px"></div>
      </div>
      <!-- 基本信息 -->
      <div style="display: flex;padding-top: 16px">
        <div class="info-item">
          <div class="info-item-title">资费类型</div>
          <div class="info-item-v">免费</div>
          <div style="font-size: 13px;color: #8f939a;font-weight: 500;">¥</div>
        </div>
        <div style="height: 30px;width: 1px;background-color: #f4f4f5;margin-top: 20px"></div>
        <div style="height: 30px;width: 1px;background-color: #f4f4f5;margin-top: 20px"></div>
        <div class="info-item">
          <div class="info-item-title">评分</div>
          <div style="display: flex">
            <div style="flex: 1"></div>
            <div v-if="software.score > 0" class="comment-rate" style="margin-top: 2px">
              <template>
                <el-rate
                  :value="+software.score.toFixed(2)"
                  disabled
                  show-score
                  text-color="#ff9900"
                  score-template="{value}">
                </el-rate>
              </template>
            </div>
            <div v-else class="info-item-v">暂无</div>
            <div style="flex: 1"></div>
          </div>
          <div style="font-size: 13px;color: #8f939a;font-weight: 500;">分</div>
        </div>
        <!-- <div style="height: 30px;width: 1px;background-color: #f4f4f5;margin-top: 20px"></div>
        <div class="info-item">
          <div class="info-item-title">语言</div>
          <div class="info-item-v">
            <template v-if="software.isHasVersion">
              <template v-if="software.language === 1">中文</template>
              <template v-if="software.language === 2">英文</template>
              <template v-if="software.language === 3">多语言</template>
            </template>
            <template v-else>暂无</template>
          </div>
          <div style="font-size: 13px;color: #8f939a;font-weight: 500;">Language</div>
        </div> -->
        <div style="height: 30px;width: 1px;background-color: #f4f4f5;margin-top: 20px"></div>
        <div class="info-item">
          <div class="info-item-title">累计下载</div>
          <div class="info-item-v">
            <template v-if="software.downloadNum > 0">
              {{software.downloadNum}}
            </template>
            <template v-else>暂无</template>
          </div>
          <div style="font-size: 13px;color: #8f939a;font-weight: 500;">次</div>
        </div>
      </div>
      <!-- 应用介绍 -->
      <div class="js-title">应用描述</div>
      <div style="padding: 15px;background-color: #F3F6FB;margin-top: 10px;border: 1px #E1E9FB solid;border-radius: 3px;font-size: 13px;color: #767676">
        {{software.description}}
      </div>
      <div class="js-title">版权证明</div>
      <div>
        <div v-if="copyrightList.length > 0" style="display: flex;margin-top: 15px;">
          <div v-for="(item,key) in copyrightList" :key="key" style="height: 100px;width: 100px;margin-right: 13px">
            <!--              <img :src="item" style="height: 100%;width: 100%;object-fit: cover">-->
            <el-image fit="cover"
              style="height: 100%;width: 100%;object-fit: cover"
              :src="item"
              :preview-src-list="copyrightList">
            </el-image>
          </div>
        </div>
        <div style="font-size: 13px;margin-top: 15px">
          <span style="color: #6d7580">版权证明在编辑应用信息中编辑</span>
          <span style="margin-left: 15px;color: #0052d9" @click="toEdit">前往编辑</span>
        </div>
      </div>
      <div class="js-title">关键词索引</div>
      <div style="display: flex;margin-top: 15px">
        <div v-for="(item,key) in keywordList" :key="key"
              style="height: 26px;line-height: 27px;background-color: rgba(23,110,255,0.07);font-size: 14px;color: #0052D9;
              padding-left: 15px;padding-right: 15px;margin-right: 20px">
          {{item}}
        </div>
      </div>
    </div>
    <div class="latest-version-table">
      <h3>最近版本</h3>
      <SoftwareVersionTable @updateVersionHistory="getLatestVersion" :versionList="latestVersionList" :type="type"></SoftwareVersionTable>
    </div>
    <el-dialog
      :destroy-on-close="true"
      :visible.sync="updateDialog"
      top="60px"
      width="800px">
      <div v-if="updateDialog">
        <SoftwareEdit :isAdd="false" :type="type" :software="software" @saveOver="updateDetail" />
      </div>
    </el-dialog>
    <el-dialog
      :destroy-on-close="true"
      :visible.sync="uploadDialog"
      top="60px"
      width="800px">
      <div v-if="uploadDialog">
        <UploadVersion :software="software" @saveOver="updateHistory" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SoftwareApi from '@/common/api/SoftwareApi'
import SoftwareVersionTable from '../../version/table/SoftwareVersionTable.vue'
import SoftwareEdit from '../edit/SoftwareInfoEdit.vue'
import UploadVersion from '../../version/upload/SoftwareVersionUpload.vue'

export default {
  props: ['softwareId', 'type'],
  name: 'SoftwareDetail',
  components: {
    SoftwareVersionTable,
    SoftwareEdit,
    UploadVersion
  },
  data () {
    return {
      software: {},
      copyrightList: [],
      latestVersionList: [],
      updateDialog: false,
      uploadDialog: false
    }
  },
  computed: {
    keywordList () {
      return (this.software.searchKeywords ? this.software.searchKeywords.split(',') : [])
    }
  },
  methods: {
    copy (data) {
      const url = data
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      oInput.remove()
    },
    toUploadVersion () {
      this.uploadDialog = true
    },
    toEdit () {
      this.updateDialog = true
    },
    getSoftwareDetail () {
      SoftwareApi.getSoftwareDetail({ softwareId: this.softwareId }).then(res => {
        if (res.code === 0) {
          this.software = res.data
          try {
            this.copyrightList = JSON.parse(this.software.copyrightFiles)
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    getLatestVersion () {
      SoftwareApi.getHistoryPage({
        pageSize: 5,
        pageNo: 1,
        softwareId: this.softwareId
      }).then(res => {
        this.latestVersionList = res.data.records
      })
    },
    initData () {
      this.getLatestVersion()
      this.getSoftwareDetail()
    },
    updateHistory () {
      this.uploadDialog = false
      this.getLatestVersion()
    },
    updateDetail () {
      this.updateDialog = false
      this.getSoftwareDetail()

      this.$bus.$emit('refreshList')
    }
  },
  watch: {
    softwareId (oldVal, newVal) {
      if (oldVal !== newVal) {
        this.initData()
      }
    }
  },
  created () {
    this.initData()
  }
}
</script>

<style scoped lang="less">
.comment-rate{
  // width: 115px;
  margin-left: -10px;
  transform: scale(0.85,0.85);
}
/deep/ .el-rate__icon{
  margin-right: 1px;margin-top: -2px;
}
.js-title{
  font-size: 15px;margin-top: 30px;font-weight: 500;
}
.info-item{
  flex: 1;text-align: center;
  .info-item-title{
    font-size: 13px;color: #8f939a;font-weight: 500;
  }
  .info-item-v{
    font-size: 15px;color: #60636f;font-weight: 500;margin-top: 3px;margin-bottom: 3px
  }
}
.detail-container{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.latest-version-table{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

</style>
