<template>
  <div class="software-container">
    <div class="left-aside">
      <LeftColumn :state="state">
        <template #buttonText>新建{{softwareType === 1 ? '应用' : '表盘' }}</template>
        <template #dataContent>
          <LeftList v-infinite-scroll="loadMore" infinite-scroll-disabled="disabled" @showDetail="showDetail" :dataList="softwareList"></LeftList>
        </template>
        <template #dialog>
          <div slot="title" class="rttm-card-title" style="margin-top: 3px;margin-left: 8px">
            新建{{softwareType == 1 ? '穿戴':'表盘'}}应用
          </div>
          <SoftwareEdit :is-add="true" :type="softwareType" @saveOver="refreshList"></SoftwareEdit>
        </template>
      </LeftColumn>
    </div>
    <div class="software-main-section">
      <SoftwareInfo @refreshList="refreshList" v-if="selectedSoftwareInfo.name" :softwareInfo="selectedSoftwareInfo" :type="softwareType"></SoftwareInfo>
      <div v-else style="text-align: center;background-color: white;display: flex;flex-direction: column;height: 100%">
        <div style="flex: 1"></div>
        <div>
          <img src="https://modao.cc/mb-dashboard/images/empty-star.svg">
        </div>
        <div style="color: #8c939d;margin-top: 20px">
          暂无选择应用, 您可以点击左侧应用列表进行选择
        </div>
        <div style="flex: 1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftColumn from '@/components/layout/LeftColumn.vue'
import LeftList from '../components/LeftList.vue'
import SoftwareEdit from './info/edit/SoftwareInfoEdit'
import SoftwareApi from '@/common/api/SoftwareApi'
import SoftwareInfo from './info/SoftwareInfo.vue'
export default {
  name: 'SoftwarePage',
  props: {
    softwareType: {
      type: Number
    }
  },
  components: {
    LeftColumn,
    LeftList,
    SoftwareEdit,
    SoftwareInfo
  },
  data () {
    return {
      state: this.$ez.model.viewState(0),
      softwareList: [],
      selectedSoftwareInfo: {},
      totalPages: 1,
      pageSize: 10,
      curPageNo: 1,
      isLoading: false
    }
  },
  computed: {
    disabled () {
      return this.isLoading || !(this.curPageNo < this.totalPages)
    }
  },
  methods: {
    showDetail (index) {
      this.selectedSoftwareInfo = this.softwareList[index]
    },
    requestSoftwareList () {
      return SoftwareApi.getMySoftwareList({
        type: this.softwareType,
        pageSize: this.pageSize,
        pageNo: this.curPageNo
      }).then(res => {
        this.totalPages = res.data.pages
        return res
      }).catch(err => {
        console.error(err)
        this.state.toFail()
      })
    },
    loadMore () {
      if (this.curPageNo < this.totalPages) {
        this.curPageNo++
        this.requestSoftwareList().then(res => {
          this.softwareList.push(...res.data.records)
        })
      }
    },
    initSoftwareList () {
      this.requestSoftwareList().then(res => {
        this.softwareList = res.data.records ?? []
        if (res.data.records.length) {
          this.state.toSuc()
          let index = 0
          if (this.$route.params?.id) {
            index = this.getIndexById(this.$route.params?.id)
          }
          this.selectedSoftwareInfo = this.selectedSoftwareInfo.name ? this.selectedSoftwareInfo : this.softwareList[index >= 0 ? index : 0]
        } else {
          this.state.toNoData()
        }
      })
    },
    getIndexById (id) {
      return this.softwareList.findIndex((item) => item.id === id)
    },
    refreshList () {
      this.curPageNo = 1
      this.initSoftwareList()
    }
  },
  created () {
    this.initSoftwareList()
    this.$bus.$on('refreshList', this.refreshList)
  },
  beforeDestroy () {
    this.$bus.$off('refreshList', this.refreshList)
  }
}
</script>

<style lang="less" scoped>
.software-container{
  width: 100%;
  height: 100%;
  .left-aside{
    width: 260px;
    height: 100%;
    float: left;
  }
  .software-main-section{
    width: 100%;
    height: 100%;
    padding-left: 260px;
    box-sizing: border-box;
  }
}
</style>
